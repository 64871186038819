import React from 'react';
import { DeviceState } from '@energyweb/origin-device-registry-irec-local-api-react-query-client';
import {
  SpecField,
  SpecFieldProps,
  TextWithPendingDot,
} from '@energyweb/origin-ui-core';
import { Button } from '@mui/material';
import { ChevronRight, Edit } from '@mui/icons-material';
import { useMyDeviceCardHeaderEffects } from './MyDeviceCardHeader.effects';
import { useStyles } from './MyDeviceCardHeader.styles';
import { DeviceModalsActionsEnum } from '../../../context';

export interface MyDeviceCardHeaderProps {
  deviceName: string;
  deviceActive: boolean;
  deviceState: DeviceState;
  viewButtonText: string;
  viewButtonLink: string;
  editButtonText: string;
  editButtonLink: string;
  specFieldProps: SpecFieldProps;
}

// export const MyDeviceCardHeader: React.FC<MyDeviceCardHeaderProps> = ({
//   deviceName,
//   deviceActive,
//   deviceState,
//   viewButtonText,
//   viewButtonLink,
//   editButtonText,
//   editButtonLink,
//   specFieldProps,
// }) => {
//   const { viewDetailsClickHandler, editDeviceClickHandler, t } =
//     useMyDeviceCardHeaderEffects(viewButtonLink, editButtonLink);
//   const showEditButton =
//     deviceState == DeviceState.Draft ||
//     deviceState == DeviceState.InternalDraft ||
//     deviceState == DeviceState.Rejected ||
//     deviceActive === false;
//   const classes = useStyles();

//   return (
//     <div className={classes.headerWrapper}>
//       <div className={classes.nameBlockWrapper}>
//         <TextWithPendingDot
//           textContent={deviceName}
//           typographyProps={{
//             variant: 'h5',
//             component: 'span',
//             marginRight: '5px',
//           }}
//           pending={deviceState !== DeviceState.Approved}
//           showSuccessDot={deviceState === DeviceState.Approved}
//           showInProgressDot={
//             deviceState === ('In Progress' as DeviceState) ||
//             deviceState === DeviceState.Submitted
//           }
//           showRejectedDot={deviceState === DeviceState.Rejected}
//           showInactiveDot={deviceActive === false}
//           tooltipText={t('device.my.deviceStatusTooltip', {
//             status: deviceState,
//           })}
//           dotWrapperProps={{ ['data-cy']: 'deviceStatus' }}
//         />
//         <Button
//           color="inherit"
//           data-cy="viewDevice"
//           onClick={viewDetailsClickHandler}
//           className={classes.button}
//           classes={{ endIcon: classes.buttonEndIcon }}
//           endIcon={<ChevronRight />}
//         >
//           {viewButtonText}
//         </Button>
//         {showEditButton && (
//           <Button
//             color="inherit"
//             data-cy="editDevice"
//             onClick={editDeviceClickHandler}
//             className={classes.button}
//             classes={{ iconSizeMedium: classes.smallEndIcon }}
//             endIcon={<Edit />}
//             sx={{ marginLeft: '10px' }}
//           >
//             {editButtonText}
//           </Button>
//         )}
//       </div>
//       <div className={classes.specBlockWrapper}>
//         <SpecField
//           wrapperProps={{ className: classes.specFieldWrapper }}
//           valueProps={{ className: classes.specFieldValue }}
//           {...specFieldProps}
//         />
//       </div>
//     </div>
//   );
// };

export const MyDeviceCardHeader: React.FC<MyDeviceCardHeaderProps> = ({
  deviceName,
  deviceActive,
  deviceState,
  viewButtonText,
  viewButtonLink,
  editButtonText,
  editButtonLink,
  specFieldProps,
}) => {
  const { viewDetailsClickHandler, editDeviceClickHandler, t } =
    useMyDeviceCardHeaderEffects(viewButtonLink, editButtonLink);
  
  const classes = useStyles();

  const showEditButton =
    deviceState === DeviceState.Draft ||
    deviceState === DeviceState.InternalDraft ||
    deviceState === DeviceState.Rejected ||
    deviceActive === false;

  // Determine which dot to show, prioritizing inactive status
  const showInactiveDot = deviceActive === false;
  const showSuccessDot = !showInactiveDot && deviceState === DeviceState.Approved;
  const showInProgressDot = !showInactiveDot && (
    deviceState === ('In Progress' as DeviceState) ||
    deviceState === DeviceState.Submitted
  );
  const showRejectedDot = !showInactiveDot && deviceState === DeviceState.Rejected;

  // Determine the tooltip text
  const tooltipText = showInactiveDot
    ? t('device.my.deviceStatusTooltip', { status: 'Inactive' })
    : t('device.my.deviceStatusTooltip', { status: deviceState });

  return (
    <div className={classes.headerWrapper}>
      <div className={classes.nameBlockWrapper}>
        <TextWithPendingDot
          textContent={deviceName}
          typographyProps={{
            variant: 'h5',
            component: 'span',
            marginRight: '5px',
          }}
          pending={!showInactiveDot && deviceState !== DeviceState.Approved}
          showSuccessDot={showSuccessDot}
          showInProgressDot={showInProgressDot}
          showRejectedDot={showRejectedDot}
          showInactiveDot={showInactiveDot}
          tooltipText={tooltipText}
          dotWrapperProps={{ ['data-cy']: 'deviceStatus' }}
        />
        <Button
          color="inherit"
          data-cy="viewDevice"
          onClick={viewDetailsClickHandler}
          className={classes.button}
          classes={{ endIcon: classes.buttonEndIcon }}
          endIcon={<ChevronRight />}
        >
          {viewButtonText}
        </Button>
        {showEditButton && (
          <Button
            color="inherit"
            data-cy="editDevice"
            onClick={editDeviceClickHandler}
            className={classes.button}
            classes={{ iconSizeMedium: classes.smallEndIcon }}
            endIcon={<Edit />}
            sx={{ marginLeft: '10px' }}
          >
            {editButtonText}
          </Button>
        )}
      </div>
      <div className={classes.specBlockWrapper}>
        <SpecField
          wrapperProps={{ className: classes.specFieldWrapper }}
          valueProps={{ className: classes.specFieldValue }}
          {...specFieldProps}
        />
      </div>
    </div>
  );
};

