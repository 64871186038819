import React, { FC } from 'react';
import {
  Box,
  Tooltip,
  Typography,
  TypographyProps,
  BoxProps,
} from '@mui/material';
import { Dot } from './Dot';
import { useStyles } from './TextWithPendingDot.styles';
import { useTheme } from '@mui/styles';

export interface TextWithPendingDotProps {
  textContent: string;
  pending?: boolean;
  tooltipText?: string;
  typographyProps?: TypographyProps & { component?: 'span' | 'p' };
  showSuccessDot?: boolean;
  showInProgressDot?: boolean;
  showRejectedDot?: boolean;
  showInactiveDot?: boolean;
  dotWrapperProps?: BoxProps & { ['data-cy']?: string };
}

export const TextWithPendingDot: FC<TextWithPendingDotProps> = ({
  textContent,
  pending = false,
  tooltipText = '',
  typographyProps,
  showSuccessDot = false,
  showInProgressDot = false,
  showRejectedDot = false,
  showInactiveDot = false,
  dotWrapperProps,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  let dotBgColor;

  if (showSuccessDot) {
    dotBgColor = theme.palette.primary.main; // Green
  } else if (showInProgressDot) {
    dotBgColor = theme.palette.info.main; // Blue
  } else if (showRejectedDot) {
    dotBgColor = theme.palette.error.main; // Red
  } else if (showInactiveDot) {
    dotBgColor = theme.palette.grey[500]; // Grey
  } else {
    undefined; // Yellow
  }

  console.log(dotBgColor);
  console.log('showInProgressDot', showSuccessDot);
  console.log(showSuccessDot);

  return (
    <div className={classes.blockWrapper}>
      <div>
        <Typography {...typographyProps}>{textContent}</Typography>
      </div>
      {(pending ||
        showSuccessDot ||
        showInProgressDot ||
        showRejectedDot ||
        showInactiveDot) && (
        <Tooltip title={tooltipText}>
          <Box className={classes.dotWrapper} {...dotWrapperProps}>
            <Dot backgroundColor={dotBgColor} />
          </Box>
        </Tooltip>
      )}
    </div>
  );
};
