import { FormSelectOption } from '@energyweb/origin-ui-core';

export const gridOperatorOptions: FormSelectOption[] = [
  {
    value: 'DEWA',
    label: 'DEWA',
  },
  {
    value: 'EWEC',
    label: 'EWEC',
  },
  {
    value: 'SEC',
    label: 'SEC',
  }
];
