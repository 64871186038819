// import { useConfigurationControllerGet } from '@energyweb/origin-backend-react-query-client';

// export const useApiRegionsConfiguration = () => {
//   const { data, isLoading } = useConfigurationControllerGet();

//   const allRegions = data?.regions;
//   const country = data?.countryName;

//   return { allRegions, country, isLoading };
// };
// src/hooks/useApiRegionsConfiguration.ts

// import { useConfigurationControllerGet } from '@energyweb/origin-backend-react-query-client';

// // Define the type for regions within a country
// interface CountryDTORegions {
//   [regionName: string]: string[];
// }

// // Define the type for a country DTO
// interface CountryDTO {
//   countryName: string;
//   gridOperators: string[];
//   regions: CountryDTORegions;
// }

// // Define a type to represent the aggregated regions structure
// export type ConfigurationDTORegions = { [countryName: string]: CountryDTORegions };

// export const useApiRegionsConfiguration = () => {
//   const { data, isLoading } = useConfigurationControllerGet();

//   console.log('device useApi', data);

//   // Aggregate regions across all countries
//   const allRegions = data?.countries?.reduce<ConfigurationDTORegions>((acc, country: CountryDTO) => {
//     if (!acc[country.countryName]) {
//       acc[country.countryName] = {};
//     }

//     // Copy the regions as-is since they are already in the expected format
//     acc[country.countryName] = country.regions;
//     return acc;
//   }, {} as ConfigurationDTORegions);

//   // Flatten the country names into an array
//   const countryNames = data?.countries?.map(country => country.countryName);

//   return { allRegions, countryNames, isLoading };
// };

// import { useConfigurationControllerGet } from '@energyweb/origin-backend-react-query-client';

// // Define the type for regions within a country
// interface CountryDTORegions {
//   [regionName: string]: string[];
// }

// // Define the type for a country DTO
// interface CountryDTO {
//   countryName: string;
//   gridOperators: string[];
//   regions: CountryDTORegions;
// }

// // Define a type to represent the aggregated regions structure
// export type ConfigurationDTORegions = { [countryName: string]: CountryDTORegions };

// export const useApiRegionsConfiguration = () => {
//   const { data, isLoading, error } = useConfigurationControllerGet();

//   console.log('API Response Data:', data); // Debugging API response
//   console.log('API Loading State:', isLoading);
//   console.log('API Error:', error); // Check if there's any error

//   // Check if data is available and log detailed information
//   if (data) {
//     console.log('Countries data:', data.countries);
//     data.countries?.forEach((country, index) => {
//       console.log(`Country ${index + 1}:`, country);
//     });
//   } else {
//     console.warn('API data is undefined or empty');
//   }

//   // Fallback to handle undefined data
//   if (!data || !data.countries) {
//     return { allRegions: {}, countryNames: [], isLoading };
//   }

//   // Aggregate regions across all countries
//   const allRegions = data.countries.reduce<ConfigurationDTORegions>((acc, country: CountryDTO) => {
//     if (!acc[country.countryName]) {
//       acc[country.countryName] = {};
//     }

//     // Copy the regions as-is since they are already in the expected format
//     acc[country.countryName] = country.regions;
//     return acc;
//   }, {} as ConfigurationDTORegions);

//   // Flatten the country names into an array
//   const countryNames = data.countries.map(country => country.countryName) || [];

//   return { allRegions, countryNames, isLoading };
// };

import { useConfigurationControllerGet } from '@energyweb/origin-backend-react-query-client';

// Define the type for regions within a country
interface CountryDTORegions {
  [regionName: string]: string[];
}

// Define the type for a country DTO
interface CountryDTO {
  countryName: string;
  gridOperators: string[];
  regions: CountryDTORegions;
}

// Define a type to represent the aggregated regions and grid operators structure
export type ConfigurationDTORegions = {
  [countryName: string]: {
    regions: CountryDTORegions;
    gridOperators: string[];
  };
};

export const useApiRegionsConfiguration = () => {
  const { data, isLoading, error } = useConfigurationControllerGet();

  console.log('API Response Data:', data); // Debugging API response
  console.log('API Loading State:', isLoading);
  console.log('API Error:', error); // Check if there's any error

  // Check if data is available and log detailed information
  if (data) {
    console.log('Countries data:', data.countries);
    data.countries?.forEach((country, index) => {
      console.log(`Country ${index + 1}:`, country);
    });
  } else {
    console.warn('API data is undefined or empty');
  }

  // Fallback to handle undefined data
  if (!data || !data.countries) {
    return { allRegions: {}, countryNames: [], isLoading };
  }

  // Aggregate regions and grid operators across all countries
  const allRegions = data.countries.reduce<ConfigurationDTORegions>((acc, country: CountryDTO) => {
    if (!acc[country.countryName]) {
      acc[country.countryName] = { regions: {}, gridOperators: [] };
    }

    // Copy the regions and grid operators
    acc[country.countryName] = {
      regions: country.regions,
      gridOperators: country.gridOperators,
    };
    return acc;
  }, {} as ConfigurationDTORegions);

  // Flatten the country names into an array
  const countryNames = data.countries.map(country => country.countryName) || [];

  return { allRegions, countryNames, isLoading };
};






