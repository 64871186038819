import { useState, useEffect } from 'react';
import { UploadedFile } from '@energyweb/origin-ui-core';
import { publicFileUploadHandler } from '@energyweb/origin-ui-device-data';
import { DeviceImagesFormValues } from '@energyweb/origin-ui-device-logic';
import { useTranslation } from 'react-i18next';

export const useDeviceImagesUploadEffects = () => {
  const [imageIds, setImageIds] = useState<UploadedFile[]>([]);
  const { t } = useTranslation();

  const values: DeviceImagesFormValues = {
    imageIds: imageIds
      .filter((doc) => !doc.removed)
      .map((doc) => doc.uploadedName),
  };

  const uploadText = t('file.upload.dropOrClick');
  const deviceImagesHeading = t('device.register.deviceImagesFormTitle');
  const uploadFunction = publicFileUploadHandler;

  const onDeviceImageChange = (newValues: UploadedFile[]) => {
    if (JSON.stringify(newValues) !== JSON.stringify(imageIds)) {
      setImageIds(newValues);
    }
  };

  const buttonText = t('general.buttons.submit');

  return {
    values,
    uploadText,
    uploadFunction,
    onDeviceImageChange,
    deviceImagesHeading,
    buttonText,
  };
};

