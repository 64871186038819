// import { gridOperatorOptions } from '../utils';

// export const getGridOperatorOptions = (gridOperators: string[]) => {
//   return gridOperatorOptions.filter((option) =>
//     gridOperators.includes(option.value as string)
//   );
// };
import { ConfigurationDTORegions } from '@energyweb/origin-ui-exchange-data';
import { FormSelectOption } from '@energyweb/origin-ui-core';
import { prepareGridOperatorOptions } from '../utils';

export const getGridOperatorOptions = (
  selectedGridOperators: string[],
  selectedRegionsOptions: FormSelectOption[],
  allRegions: ConfigurationDTORegions
): FormSelectOption[] => {
  // Prepare all possible grid operator options based on the selected regions
  const allAvailableOptions = prepareGridOperatorOptions(allRegions, selectedRegionsOptions);

  // Filter the options based on the selected grid operators
  const selectedOptions = allAvailableOptions.filter((option) =>
    selectedGridOperators.includes(option.value as string)
  );

  return selectedOptions;
};
