import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  switchBase: {
    color: theme.palette.primary.main, // Base color when the switch is off
    '&.Mui-checked': {
      color: theme.palette.secondary.main, // Color when the switch is on
    },
    '&.Mui-checked + .MuiSwitch-track': {
      backgroundColor: theme.palette.secondary.main, // Track color when the switch is on
    },
  },
  track: {
    backgroundColor: theme.palette.grey[400], // Default track color
    borderRadius: 20, // Makes the track fully rounded
  },
  thumb: {
    width: 24,
    height: 24,
  },
  label: {
    marginLeft: theme.spacing(1),
    color: theme.palette.text.primary,
  },
}));
