// import { getDeviceRegistryControllerGetMyDevicesQueryKey } from '@energyweb/origin-device-registry-api-react-query-client';
// import {
//   DeviceState,
//   getDeviceControllerGetMyDevicesQueryKey,
//   UpdateDeviceDTO,
//   useDeviceControllerUpdateDevice,
// } from '@energyweb/origin-device-registry-irec-local-api-react-query-client';
// import {
//   FormSelectOption,
//   NotificationTypeEnum,
//   showNotification,
// } from '@energyweb/origin-ui-core';
// import { PowerFormatter } from '@energyweb/origin-ui-utils';
// import { useTranslation } from 'react-i18next';
// import { useQueryClient } from 'react-query';
// import { useNavigate } from 'react-router';
// import { ComposedPublicDevice } from '../types';

// import { BigNumber } from '@ethersproject/bignumber';

// export type EditDeviceFormValues = {
//   name: string;
//   fuelType: FormSelectOption[];
//   deviceType: FormSelectOption[];
//   capacity: string;
//   commissioningDate: string;
//   registrationDate: string;
//   latitude: string;
//   longitude: string;
//   notes: string;
//   imageIds: string[];
//   fileIds: string[];
// };

// export const useEditDeviceHandler = (
//   device: ComposedPublicDevice,
//   closeModal: () => void
// ) => {
//   const { t } = useTranslation();
//   const navigate = useNavigate();

//   const queryClient = useQueryClient();
//   const myIRecDevicesQueryKey = getDeviceControllerGetMyDevicesQueryKey();
//   const myOriginDevicesQueryKey =
//     getDeviceRegistryControllerGetMyDevicesQueryKey();

//   const { mutate, isLoading } = useDeviceControllerUpdateDevice();

//   const submitHandler = (values: EditDeviceFormValues) => {
//     // Extract imageIds and fileIds and only include images and files
//     const { imageIds, fileIds, ...restValues } = values;

//     const formattedValues: UpdateDeviceDTO = {
//       ...restValues,
//       fuelType: values.fuelType[0].value as string,
//       deviceType: values.deviceType[0].value as string,
//       capacity: PowerFormatter.getBaseValueFromValueInDisplayUnit(values.capacity),
//       commissioningDate: values.commissioningDate,
//       registrationDate: values.registrationDate,
//       countryCode: device.countryCode,
//       address: device.address,
//       active: true,
//       images: values.imageIds, // Send imageIds as images
//       files: values.fileIds, // Send fileIds as files
//     };

//     console.log('This is the formatted values', formattedValues);

//     mutate(
//       { id: device.externalRegistryId, data: formattedValues },
//       {
//         onSuccess: () => {
//           closeModal();
//           showNotification(
//             t('device.edit.notifications.editSuccess'),
//             NotificationTypeEnum.Success
//           );
//           queryClient.invalidateQueries([
//             ...myIRecDevicesQueryKey,
//             ...myOriginDevicesQueryKey,
//           ]);
//           navigate('/device/my');
//         },
//         onError: (error: any) => {
//           closeModal();
//           showNotification(
//             `${t('device.edit.notifications.editError')}:
//             ${error?.response?.data?.message || ''}`,
//             NotificationTypeEnum.Error
//           );
//         },
//       }
//     );
//   };

//   return { submitHandler, isLoading };
// };
// import { getDeviceRegistryControllerGetMyDevicesQueryKey } from '@energyweb/origin-device-registry-api-react-query-client';
// import {
//   DeviceState,
//   getDeviceControllerGetMyDevicesQueryKey,
//   UpdateDeviceDTO,
//   useDeviceControllerUpdateDevice,
// } from '@energyweb/origin-device-registry-irec-local-api-react-query-client';
// import {
//   FormSelectOption,
//   NotificationTypeEnum,
//   showNotification,
// } from '@energyweb/origin-ui-core';
// import { PowerFormatter } from '@energyweb/origin-ui-utils';
// import { useTranslation } from 'react-i18next';
// import { useQueryClient } from 'react-query';
// import { useNavigate } from 'react-router';
// import { ComposedPublicDevice } from '../types';
// import { useFileContext } from '@energyweb/origin-ui-device-view';

// import { BigNumber } from '@ethersproject/bignumber';

// export type EditDeviceFormValues = {
//   name: string;
//   fuelType: FormSelectOption[];
//   deviceType: FormSelectOption[];
//   capacity: string;
//   commissioningDate: string;
//   registrationDate: string;
//   latitude: string;
//   longitude: string;
//   notes: string;
//   imageIds: string[];
//   fileIds: string[];
// };

// export const useEditDeviceHandler = (
//   device: ComposedPublicDevice,
//   closeModal: () => void,
//   removedInitialFiles: string[] = [] // Make removedInitialFiles optional
// ) => {
//   const { t } = useTranslation();
//   const navigate = useNavigate();

//   const queryClient = useQueryClient();
//   const myIRecDevicesQueryKey = getDeviceControllerGetMyDevicesQueryKey();
//   const myOriginDevicesQueryKey =
//     getDeviceRegistryControllerGetMyDevicesQueryKey();

//   const { mutate, isLoading } = useDeviceControllerUpdateDevice();

//   const { uploadedFiles } = useFileContext(); // Access the uploaded files from context

//   console.log('removedInitialFiles:', removedInitialFiles);

//   const submitHandler = (values: EditDeviceFormValues) => {
//     // Destructure values into imageIds, fileIds, and the rest of the values
//     const { imageIds, fileIds, ...restValues } = values;

//     // Remove initial files that have been removed
//     const filteredImageIds = imageIds.filter(
//       (id) => !removedInitialFiles.includes(id)
//     );
//     const filteredFileIds = fileIds.filter(
//       (id) => !removedInitialFiles.includes(id)
//     );

//     console.log('Filtered Image IDs:', filteredImageIds);
//     console.log('Filtered File IDs:', filteredFileIds);

//     // Merge uploaded files with filtered IDs
//     // const mergedImageIds = [...filteredImageIds, ...uploadedFiles.filter((id) => !removedInitialFiles.includes(id))];
//     // const mergedFileIds = [...filteredFileIds, ...uploadedFiles.filter((id) => !removedInitialFiles.includes(id))];

//     const mergedImageIds = filteredImageIds;
//     const mergedFileIds = filteredFileIds;

//     console.log('Merged Image IDs:', mergedImageIds);
//     console.log('Merged File IDs:', mergedFileIds);

//     // Construct the final object with restValues and the updated image and file IDs
//     const formattedValues: UpdateDeviceDTO = {
//       ...restValues,  // Spread the rest of the values
//       fuelType: values.fuelType[0].value as string,
//       deviceType: values.deviceType[0].value as string,
//       capacity: PowerFormatter.getBaseValueFromValueInDisplayUnit(values.capacity),
//       commissioningDate: values.commissioningDate,
//       registrationDate: values.registrationDate,
//       countryCode: device.countryCode,
//       address: device.address,
//       active: true,
//       images: mergedImageIds,
//       files: mergedFileIds,
//     };

//     console.log('This is the formatted values', formattedValues);

//     mutate(
//       { id: device.externalRegistryId, data: formattedValues },
//       {
//         onSuccess: () => {
//           closeModal();
//           showNotification(
//             t('device.edit.notifications.editSuccess'),
//             NotificationTypeEnum.Success
//           );
//           queryClient.invalidateQueries([
//             ...myIRecDevicesQueryKey,
//             ...myOriginDevicesQueryKey,
//           ]);
//           navigate('/device/my');
//         },
//         onError: (error: any) => {
//           closeModal();
//           showNotification(
//             `${t('device.edit.notifications.editError')}:
//             ${error?.response?.data?.message || ''}`,
//             NotificationTypeEnum.Error
//           );
//         },
//       }
//     );
//   };

//   return { submitHandler, isLoading };
// };
// import { getDeviceRegistryControllerGetMyDevicesQueryKey } from '@energyweb/origin-device-registry-api-react-query-client';
// import {
//   DeviceState,
//   getDeviceControllerGetMyDevicesQueryKey,
//   UpdateDeviceDTO,
//   useDeviceControllerUpdateDevice,
// } from '@energyweb/origin-device-registry-irec-local-api-react-query-client';
// import {
//   FormSelectOption,
//   NotificationTypeEnum,
//   showNotification,
// } from '@energyweb/origin-ui-core';
// import { PowerFormatter } from '@energyweb/origin-ui-utils';
// import { useTranslation } from 'react-i18next';
// import { useQueryClient } from 'react-query';
// import { useNavigate } from 'react-router';
// import { ComposedPublicDevice } from '../types';
// import { useFileContext } from '@energyweb/origin-ui-device-view';

// import { BigNumber } from '@ethersproject/bignumber';

// export type EditDeviceFormValues = {
//   name: string;
//   fuelType: FormSelectOption[];
//   deviceType: FormSelectOption[];
//   capacity: string;
//   commissioningDate: string;
//   registrationDate: string;
//   latitude: string;
//   longitude: string;
//   notes: string;
//   imageIds: string[];
//   fileIds: string[];
// };

// export const useEditDeviceHandler = (
//   device: ComposedPublicDevice,
//   closeModal: () => void,
//   removedInitialFiles: string[] = [] // Make removedInitialFiles optional
// ) => {
//   const { t } = useTranslation();
//   const navigate = useNavigate();

//   const queryClient = useQueryClient();
//   const myIRecDevicesQueryKey = getDeviceControllerGetMyDevicesQueryKey();
//   const myOriginDevicesQueryKey =
//     getDeviceRegistryControllerGetMyDevicesQueryKey();

//   const { mutate, isLoading } = useDeviceControllerUpdateDevice();

//   const { uploadedFiles } = useFileContext(); // Access the uploaded files from context

//   console.log('removedInitialFiles:', removedInitialFiles);

//   const submitHandler = (values: EditDeviceFormValues) => {
//     // Destructure values into imageIds, fileIds, and the rest of the values
//     const { imageIds, fileIds, ...restValues } = values;

//     // Remove initial files that have been removed
//     const filteredImageIds = imageIds.filter(
//       (id) => !removedInitialFiles.includes(id)
//     );
//     const filteredFileIds = fileIds.filter(
//       (id) => !removedInitialFiles.includes(id)
//     );

//     console.log('Filtered Image IDs:', filteredImageIds);
//     console.log('Filtered File IDs:', filteredFileIds);

//     // Filter out removedInitialFiles from uploadedFiles
//     const validUploadedFiles = uploadedFiles.filter(
//       (file) => !removedInitialFiles.includes(file.filename)
//     );

//     // Categorize and merge uploaded files based on their contentType
//     validUploadedFiles.forEach((file) => {
//       if (file.contentType.startsWith('image/')) {
//         filteredImageIds.push(file.filename);
//       } else {
//         filteredFileIds.push(file.filename);
//       }
//     });

//     console.log('Merged Image IDs:', filteredImageIds);
//     console.log('Merged File IDs:', filteredFileIds);

//     // Construct the final object with restValues and the updated image and file IDs
//     const formattedValues: UpdateDeviceDTO = {
//       ...restValues, // Spread the rest of the values
//       fuelType: values.fuelType[0].value as string,
//       deviceType: values.deviceType[0].value as string,
//       capacity: PowerFormatter.getBaseValueFromValueInDisplayUnit(values.capacity),
//       commissioningDate: values.commissioningDate,
//       registrationDate: values.registrationDate,
//       countryCode: device.countryCode,
//       address: device.address,
//       active: true,
//       images: filteredImageIds,
//       files: filteredFileIds,
//     };

//     console.log('This is the formatted values', formattedValues);

//     mutate(
//       { id: device.externalRegistryId, data: formattedValues },
//       {
//         onSuccess: () => {
//           closeModal();
//           showNotification(
//             t('device.edit.notifications.editSuccess'),
//             NotificationTypeEnum.Success
//           );
//           queryClient.invalidateQueries([
//             ...myIRecDevicesQueryKey,
//             ...myOriginDevicesQueryKey,
//           ]);
//           navigate('/device/my');
//         },
//         onError: (error: any) => {
//           closeModal();
//           showNotification(
//             `${t('device.edit.notifications.editError')}:
//             ${error?.response?.data?.message || ''}`,
//             NotificationTypeEnum.Error
//           );
//         },
//       }
//     );
//   };

//   return { submitHandler, isLoading };
// };
import { getDeviceRegistryControllerGetMyDevicesQueryKey } from '@energyweb/origin-device-registry-api-react-query-client';
import {
  DeviceState,
  getDeviceControllerGetMyDevicesQueryKey,
  UpdateDeviceDTO,
  useDeviceControllerUpdateDevice,
} from '@energyweb/origin-device-registry-irec-local-api-react-query-client';
import {
  useDeviceRegistryControllerUpdateDevice as useOriginUpdateDevice,
} from '@energyweb/origin-device-registry-api-react-query-client';
import {
  FormSelectOption,
  NotificationTypeEnum,
  showNotification,
} from '@energyweb/origin-ui-core';
import { PowerFormatter } from '@energyweb/origin-ui-utils';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { ComposedPublicDevice } from '../types';
import { useFileContext } from '@energyweb/origin-ui-device-view';

import { BigNumber } from '@ethersproject/bignumber';

export type EditDeviceFormValues = {
  name: string;
  fuelType: FormSelectOption[];
  deviceType: FormSelectOption[];
  capacity: string;
  commissioningDate: string;
  registrationDate: string;
  latitude: string;
  longitude: string;
  notes: string;
  imageIds: string[];
  fileIds: string[];
};

export const useEditDeviceHandler = (
  device: ComposedPublicDevice,
  closeModal: () => void,
  removedInitialFiles: string[] = [] // Make removedInitialFiles optional
) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const myIRecDevicesQueryKey = getDeviceControllerGetMyDevicesQueryKey();
  const myOriginDevicesQueryKey =
    getDeviceRegistryControllerGetMyDevicesQueryKey();

  const { mutate: mutateIRec, isLoading: isIRecLoading } = useDeviceControllerUpdateDevice();
  const { mutate: mutateOrigin, isLoading: isOriginLoading } = useOriginUpdateDevice();

  const { uploadedFiles } = useFileContext(); // Access the uploaded files from context

  console.log('removedInitialFiles:', removedInitialFiles);

  const submitHandler = (values: EditDeviceFormValues) => {
    // Destructure values into imageIds, fileIds, and the rest of the values
    const { imageIds, fileIds, ...restValues } = values;

    // Remove initial files that have been removed
    const filteredImageIds = imageIds.filter(
      (id) => !removedInitialFiles.includes(id)
    );
    const filteredFileIds = fileIds.filter(
      (id) => !removedInitialFiles.includes(id)
    );

    console.log('Filtered Image IDs:', filteredImageIds);
    console.log('Filtered File IDs:', filteredFileIds);

    // Filter out removedInitialFiles from uploadedFiles
    const validUploadedFiles = uploadedFiles.filter(
      (file) => !removedInitialFiles.includes(file.filename)
    );

    // Categorize and merge uploaded files based on their contentType
    validUploadedFiles.forEach((file) => {
      if (file.contentType.startsWith('image/')) {
        filteredImageIds.push(file.filename);
      } else {
        filteredFileIds.push(file.filename);
      }
    });

    console.log('Merged Image IDs:', filteredImageIds);
    console.log('Merged File IDs:', filteredFileIds);

    // Construct the final object for I-REC
    const iRecFormattedValues: UpdateDeviceDTO = {
      ...restValues, // Spread the rest of the values
      fuelType: values.fuelType[0].value as string,
      deviceType: values.deviceType[0].value as string,
      capacity: PowerFormatter.getBaseValueFromValueInDisplayUnit(values.capacity),
      commissioningDate: values.commissioningDate,
      registrationDate: values.registrationDate,
      countryCode: device.countryCode,
      address: device.address,
      active: true,
      images: filteredImageIds,
      files: filteredFileIds,
    };

    console.log('This is the I-REC formatted values', iRecFormattedValues);

    // Update I-REC first
    mutateIRec(
      { id: device.externalRegistryId, data: iRecFormattedValues },
      {
        onSuccess: (updatedIRecDevice) => {
          // After successfully updating I-REC, update Origin with only imageIds and fileIds
          const originFormattedValues = {
            id: device.id,
            externalRegistryId: device.externalRegistryId,
            smartMeterId: device.smartMeterId,
            description: device.description,
            owner: device.ownerId,
            imageIds: filteredImageIds,
            fileIds: filteredFileIds,
          };

          console.log('This is the Origin formatted values', originFormattedValues);

          mutateOrigin(
            { id: device.id, data: originFormattedValues },
            {
              onSuccess: () => {
                closeModal();
                showNotification(
                  t('device.edit.notifications.editSuccess'),
                  NotificationTypeEnum.Success
                );
                queryClient.invalidateQueries([
                  ...myIRecDevicesQueryKey,
                  ...myOriginDevicesQueryKey,
                ]);
                navigate('/device/my');
              },
              onError: (error: any) => {
                closeModal();
                showNotification(
                  `${t('device.edit.notifications.editError')}:
                  ${error?.response?.data?.message || ''}`,
                  NotificationTypeEnum.Error
                );
              },
            }
          );
        },
        onError: (error: any) => {
          closeModal();
          showNotification(
            `${t('device.edit.notifications.editError')}:
            ${error?.response?.data?.message || ''}`,
            NotificationTypeEnum.Error
          );
        },
      }
    );
  };

  return { submitHandler, isLoading: isIRecLoading || isOriginLoading };
};
