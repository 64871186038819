import {
  EnergyTypeEnum,
  formatDate,
  PowerFormatter,
  EnergyFormatter,
} from '@energyweb/origin-ui-utils';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { TUseDeviceDetailsLogic } from './types';
import { getEnergyTypeImage, getMainFuelType } from '../utils';

import { Countries } from '@energyweb/utils-general';

export const useDeviceDetailsLogic: TUseDeviceDetailsLogic = ({
  device,
  owner,
  allTypes,
  certifiedAmount,
}) => {
  const { t } = useTranslation();

  const countryName = Countries.find(
    (country) => country.code === device.countryCode
  )?.name;

  const locationProps = {
    // @should be changed to actual owner name
    owner: `Device owner organization id ${owner}`,
    location: `${countryName}, ${device.subregion}`,
    coordinates: `${device.latitude}, ${device.longitude}`,
  };

  const { mainType, restType } = getMainFuelType(device.fuelType, allTypes);
  const deviceIcon = getEnergyTypeImage(
    mainType.toLowerCase() as EnergyTypeEnum
  );

  const cardProps = {
    headingIconProps: {
      icon: deviceIcon,
      title: mainType,
      subtitle: restType,
    },
    //TODO change to EnergyFormatter and adjust teh formula for decimal numbers
    specFields: [
      {
        label: t('device.card.certifiedMw'),
        value: PowerFormatter.format(certifiedAmount),
      },
      {
        label: t('device.card.nameplateCapacity'),
        value: PowerFormatter.format(device.capacity),
      },
      {
        label: t('device.card.certifiedByRegistry'),
        value: 'I-REC',
      },
      {
        label: t('device.card.toBeCertified'),
        value: '-',
      },
      {
        label: t('device.card.otherGreenAttr'),
        value: '-',
      },
      {
        label: t('device.card.publicSupport'),
        value: '-',
      },
      {
        label: t('device.card.vintageCOD'),
        value: formatDate(dayjs(device.registrationDate).unix() * 1000),
      },
    ],
  };

  return {
    locationProps,
    cardProps,
  };
};
