import React, { FC } from 'react';
import { Switch as MuiSwitch, SwitchProps as MuiSwitchProps } from '@mui/material';
import { useStyles } from './Switch.styles';

export interface SwitchProps extends MuiSwitchProps {
  label?: string;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
}

export const Switch: FC<SwitchProps> = ({ label, labelPlacement = 'end', ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {label && labelPlacement === 'start' && <span className={classes.label}>{label}</span>}
      <MuiSwitch {...props} />
      {label && labelPlacement === 'end' && <span className={classes.label}>{label}</span>}
    </div>
  );
};
