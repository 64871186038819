// import { ConfigurationDTORegions } from '@energyweb/origin-backend-react-query-client';
import { ConfigurationDTORegions } from '@energyweb/origin-ui-exchange-data';
import {
  FormSelectOption,
  SelectAutocompleteProps,
} from '@energyweb/origin-ui-core';
import { useTranslation } from 'react-i18next';
import { prepareSubRegionsOptions } from '../utils';

type TUseSubRegionsFilterLogic = (
  value: FormSelectOption[],
  onChange: (...event: any[]) => void,
  // countryNames: string[],
  allRegions: ConfigurationDTORegions,
  selectedRegions: FormSelectOption[]
) => SelectAutocompleteProps;

export const useSubRegionsFilterLogic: TUseSubRegionsFilterLogic = (
  value,
  onChange,
  // countryNames,
  allRegions,
  selectedRegions
) => {
  const { t } = useTranslation();

  return {
    value,
    onChange,
    field: {
      name: 'subregions',
      label: t('exchange.viewMarket.subregions'),
      multiple: true,
      // options: prepareSubRegionsOptions(allRegions, countryNames, selectedRegions),
      options: prepareSubRegionsOptions(allRegions, selectedRegions),
    },
    errorExists: false,
    errorText: '',
    variant: 'filled',
  };
};
// import { FormSelectOption, SelectAutocompleteProps } from '@energyweb/origin-ui-core';
// import { useTranslation } from 'react-i18next';
// import { prepareSubRegionsOptions, flattenRegions } from '../utils';
// import { ConfigurationDTORegions } from '@energyweb/origin-ui-exchange-data';

// type TUseSubRegionsFilterLogic = (
//   value: FormSelectOption[],
//   onChange: (...event: any[]) => void,
//   country: string,
//   allRegions: ConfigurationDTORegions,
//   selectedRegions: FormSelectOption[]
// ) => SelectAutocompleteProps;

// export const useSubRegionsFilterLogic: TUseSubRegionsFilterLogic = (
//   value,
//   onChange,
//   country,
//   allRegions,
//   selectedRegions
// ) => {
//   const { t } = useTranslation();

//   // Flatten the nested regions structure to a flat structure
//   const flattenedRegions = flattenRegions(allRegions);

//   return {
//     value,
//     onChange,
//     field: {
//       name: 'subregions',
//       label: t('exchange.viewMarket.subregions'),
//       multiple: true,
//       options: prepareSubRegionsOptions(flattenedRegions, country, selectedRegions),
//     },
//     errorExists: false,
//     errorText: '',
//     variant: 'filled',
//   };
// };
