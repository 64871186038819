// import { FileUpload } from '@energyweb/origin-ui-core';
// import { Box } from '@mui/material';
// import React, { FC } from 'react';

// interface DeviceFilesUploadProps {
//   uploadText: string;
//   uploadFunction: any;
//   onDeviceChange: (newValues: any) => void;
//   heading: string;
// }

// export const DeviceFilesUpload: FC<DeviceFilesUploadProps> = ({
//   uploadText,
//   uploadFunction,
//   onDeviceChange,
//   heading,
// }) => (
//   <Box>
//     <FileUpload
//       dropzoneText={uploadText}
//       heading={heading}
//       apiUploadFunction={uploadFunction}
//       onChange={onDeviceChange}
//       wrapperProps={{ ['data-cy']: 'deviceFiles' }}
//     />
//   </Box>
// );

// export default DeviceFilesUpload;
// import { FileUpload } from '@energyweb/origin-ui-core';
// import { Box } from '@mui/material';
// import React, { FC } from 'react';

// interface DeviceFilesUploadProps {
//   uploadText: string;
//   uploadFunction: any;
//   onDeviceChange: (newValues: any) => void;
//   heading: string;
//   initialFiles: string[];
// }

// export const DeviceFilesUpload: FC<DeviceFilesUploadProps> = ({
//   uploadText,
//   uploadFunction,
//   onDeviceChange,
//   heading,
//   initialFiles,
// }) => (
//   <Box>
//     <FileUpload
//       dropzoneText={uploadText}
//       heading={heading}
//       apiUploadFunction={uploadFunction}
//       onChange={onDeviceChange}
//       initialFiles={initialFiles}
//       wrapperProps={{ ['data-cy']: 'deviceFiles' }}
//     />
//   </Box>
// );

// export default DeviceFilesUpload;
// import React, { FC, useEffect  } from 'react';
// import { FileUpload } from '@energyweb/origin-ui-core';
// import { Box } from '@mui/material';
// import { CustomFile } from '@energyweb/origin-ui-core';

// interface DeviceFilesUploadProps {
//   uploadText: string;
//   uploadFunction: any;
//   onDeviceChange: (newValues: any) => void;
//   heading: string;
//   initialFiles?: CustomFile[]; // Use CustomFile type for initial files
// }

// export const DeviceFilesUpload: FC<DeviceFilesUploadProps> = ({
//   uploadText,
//   uploadFunction,
//   onDeviceChange,
//   heading,
//   initialFiles = [],
// }) => {
//   useEffect(() => {
//     console.log('DeviceFilesUpload - Initial Files:', initialFiles);
//   }, [initialFiles]);

//   return (
//     <Box>
//       <FileUpload
//         dropzoneText={uploadText}
//         heading={heading}
//         apiUploadFunction={uploadFunction}
//         onChange={onDeviceChange}
//         initialFiles={initialFiles}
//         wrapperProps={{ ['data-cy']: 'deviceFiles' }}
//       />
//     </Box>
//   );
// };

// export default DeviceFilesUpload;
import React, { FC, useEffect } from 'react';
import { FileUploadDevice, CustomFile } from '@energyweb/origin-ui-core';
import { Box } from '@mui/material';

interface EditDeviceFilesUploadProps {
  uploadText: string;
  uploadFunction: any;
  onDeviceChange: (newValues: any) => void; // Update type definition
  heading: string;
  initialFiles?: CustomFile[]; // Use file information
}

export const EditDeviceFilesUpload: FC<EditDeviceFilesUploadProps> = ({
  uploadText,
  uploadFunction,
  onDeviceChange,
  heading,
  initialFiles = [],
}) => {

  useEffect(() => {
    console.log('DeviceFilesUpload - Initial Files:', initialFiles);
  }, [initialFiles]);

  return (
    <Box>
      <FileUploadDevice
        dropzoneText={uploadText}
        heading={heading}
        apiUploadFunction={uploadFunction}
        onChange={onDeviceChange}
        initialFiles={initialFiles}
        wrapperProps={{ ['data-cy']: 'deviceFiles' }}
      />
    </Box>
  );
};






