import { IExternalDeviceId } from '@energyweb/origin-backend-core';
import { DeviceState } from '@energyweb/origin-device-registry-irec-local-api-react-query-client';
import { FormSelectOption } from '@energyweb/origin-ui-core';

export type ComposedDevice = {
  id: string;
  ownerId: string;
  owner: string;
  externalRegistryId: string;
  code: string;
  name: string;
  defaultAccount: string;
  deviceType: string;
  fuelType: string;
  countryCode: string;
  registrantOrganization: string;
  issuer: string;
  capacity: string;
  commissioningDate: string;
  registrationDate: string;
  address: string;
  latitude: string;
  longitude: string;
  notes: string;
  status: DeviceState;
  active: boolean;
  timezone: string;
  gridOperator: string;
  smartMeterId: string;
  description: string;
  postalCode: string;
  region: string;
  subregion: string;
  externalDeviceIds?: IExternalDeviceId[];
  imageIds?: string[];
  fileIds?: string[];
};

export type ComposedPublicDevice = Omit<ComposedDevice, 'defaultAccount'>;

export type TRegisterDeviceFormValues = {
  facilityName: string;
  fuelType: FormSelectOption[];
  deviceType: FormSelectOption[];
  commissioningDate: string;
  registrationDate: string;
  capacity: string;
  gridOperator: string;
  irecTradeAccountCode?: string;
  description: string;
  smartMeterId: string;
  timeZone?: FormSelectOption[];
  region: FormSelectOption[];
  subregion: FormSelectOption[];
  postalCode: string;
  address: string;
  latitude: string;
  longitude: string;
  imageIds: string[];
  fileIds: string[];
};

export type TCreateDeviceData = {
  name: string;
  code: string;
  defaultAccount: string;
  deviceType: string;
  fuelType: string;
  countryCode: string;
  capacity: string;
  commissioningDate: string;
  registrationDate: string;
  address: string;
  latitude: string;
  longitude: string;
  timezone: string;
  gridOperator: string;
  notes: string;
  smartMeterId: string;
  description: string;
  externalDeviceIds?: IExternalDeviceId[];
  imageIds: string[];
  fileIds: string[];
  country: string;
  postalCode: string;
  region: string;
  subregion: string;
};

export type GridOperator = { value: string };
