// import { FormSelectOption } from '@energyweb/origin-ui-core';

// export const prepareGridOperatorOptions = (
//   allRegions: Record<string, { regions: Record<string, string[]>; gridOperators: string[] }>
// ): ((selected: FormSelectOption[]) => FormSelectOption[]) => {
//   return (selectedRegion: FormSelectOption[]) => {
//     if (!selectedRegion || selectedRegion.length === 0) {
//       return [];
//     }

//     const selectedValue = selectedRegion[0]?.value;
//     if (typeof selectedValue !== 'string') {
//       console.error('Selected value is not a string:', selectedValue);
//       return [];
//     }

//     const [country, region] = selectedValue.split(' - ');

//     console.log('Selected Country for Grid Operators:', country);
//     console.log('Selected Region for Grid Operators:', region);

//     let gridOperator = '';

//     if (region === 'Dubai') {
//       gridOperator = 'DEWA';
//     } else if (region === 'Abu Dhabi') {
//       gridOperator = 'EWEC';
//     } else {
//       gridOperator = allRegions[country]?.gridOperators[0] || '';
//     }

//     console.log('Selected Grid Operator:', gridOperator);

//     // Return as an array containing a single option
//     return gridOperator ? [{ value: gridOperator, label: gridOperator }] : [];
//   };
// };
// import { FormSelectOption } from '@energyweb/origin-ui-core';

// export const prepareGridOperatorOptions = (
//   allRegions: Record<string, { regions: Record<string, string[]>; gridOperators: string[] }>
// ): ((selected: FormSelectOption[]) => FormSelectOption[]) => {
//   return (selectedRegion: FormSelectOption[]) => {
//     if (!selectedRegion || selectedRegion.length === 0) {
//       return [];
//     }

//     const selectedValue = selectedRegion[0]?.value;
//     if (typeof selectedValue !== 'string') {
//       console.error('Selected value is not a string:', selectedValue);
//       return [];
//     }

//     const [country, region] = selectedValue.split(' - ');

//     console.log('Selected Country for Grid Operators:', country);
//     console.log('Selected Region for Grid Operators:', region);

//     // Prepare an array to hold grid operator options
//     const gridOperatorOptions: FormSelectOption[] = [];

//     // Specific cases for regions with unique grid operators
//     if (region === 'Dubai') {
//       gridOperatorOptions.push({ value: 'DEWA', label: 'DEWA' });
//     } else if (region === 'Abu Dhabi') {
//       gridOperatorOptions.push({ value: 'EWEC', label: 'EWEC' });
//     } else {
//       // General case for other regions, use the first grid operator for the country
//       const defaultGridOperator = allRegions[country]?.gridOperators[0];
//       if (defaultGridOperator) {
//         gridOperatorOptions.push({ value: defaultGridOperator, label: defaultGridOperator });
//       }
//     }

//     console.log('Grid Operator Options:', gridOperatorOptions);

//     return gridOperatorOptions; // This returns a FormSelectOption[] as expected
//   };
// };

// import { FormSelectOption } from '@energyweb/origin-ui-core';

// export const prepareGridOperatorOptions = (
//   allRegions: Record<string, { regions: Record<string, string[]>; gridOperators: string[] }>
// ): ((selected: FormSelectOption[]) => FormSelectOption | undefined) => {
//   return (selectedRegion: FormSelectOption[]) => {
//     if (!selectedRegion || selectedRegion.length === 0) {
//       return undefined;
//     }

//     const selectedValue = selectedRegion[0]?.value;
//     if (typeof selectedValue !== 'string') {
//       console.error('Selected value is not a string:', selectedValue);
//       return undefined;
//     }

//     const [country, region] = selectedValue.split(' - ');

//     console.log('Selected Country for Grid Operators:', country);
//     console.log('Selected Region for Grid Operators:', region);

//     let gridOperator = '';

//     if (region === 'Dubai') {
//       gridOperator = 'DEWA';
//     } else if (region === 'Abu Dhabi') {
//       gridOperator = 'EWEC';
//     } else {
//       gridOperator = allRegions[country]?.gridOperators[0] || '';
//     }

//     console.log('Selected Grid Operator:', gridOperator);

//     // Return a single FormSelectOption object
//     return gridOperator ? { value: gridOperator, label: gridOperator } : undefined;
//   };
// };
// import { FormSelectOption } from '@energyweb/origin-ui-core';

// export const prepareGridOperatorOptions = (
//   allRegions: Record<string, { regions: Record<string, string[]>; gridOperators: string[] }>
// ): ((selected: FormSelectOption[]) => FormSelectOption[]) => {
//   return (selectedRegion: FormSelectOption[]) => {
//     if (!selectedRegion || selectedRegion.length === 0) {
//       return [];
//     }

//     const selectedValue = selectedRegion[0]?.value;
//     if (typeof selectedValue !== 'string') {
//       console.error('Selected value is not a string:', selectedValue);
//       return [];
//     }

//     const [country, region] = selectedValue.split(' - ');

//     console.log('Selected Country for Grid Operators:', country);
//     console.log('Selected Region for Grid Operators:', region);

//     const gridOperatorOptions: FormSelectOption[] = [];

//     if (region === 'Dubai') {
//       gridOperatorOptions.push({ value: 'DEWA', label: 'DEWA' });
//     } else if (region === 'Abu Dhabi') {
//       gridOperatorOptions.push({ value: 'EWEC', label: 'EWEC' });
//     } else {
//       const defaultGridOperator = allRegions[country]?.gridOperators[0];
//       if (defaultGridOperator) {
//         gridOperatorOptions.push({ value: defaultGridOperator, label: defaultGridOperator });
//       }
//     }

//     console.log('Grid Operator Options:', gridOperatorOptions);

//     return gridOperatorOptions;
//   };
// };
import { FormSelectOption } from '@energyweb/origin-ui-core';

export const prepareGridOperatorOptions = (
  allRegions: Record<string, { regions: Record<string, string[]>; gridOperators: string[] }>
): ((selected: FormSelectOption[]) => FormSelectOption[]) => {
  return (selectedRegion: FormSelectOption[]) => {
    if (!selectedRegion || selectedRegion.length === 0) {
      return [];
    }

    const selectedValue = selectedRegion[0]?.value;
    if (typeof selectedValue !== 'string') {
      console.error('Selected value is not a string:', selectedValue);
      return [];
    }

    const [country, region] = selectedValue.split(' - ');

    console.log('Selected Country for Grid Operators:', country);
    console.log('Selected Region for Grid Operators:', region);

    const gridOperatorOptions: FormSelectOption[] = [];

    const countryInfo = allRegions[country];
    if (!countryInfo) {
      console.error('Country information not found:', country);
      return [];
    }

    const { regions, gridOperators } = countryInfo;

    if (gridOperators.length === 1) {
      // If there's only one grid operator, use it for all regions
      gridOperatorOptions.push({ value: gridOperators[0], label: gridOperators[0] });
    } else {
      // If there are multiple grid operators, map them to regions
      const regionKeys = Object.keys(regions);

      // Find the index of the current region in the list of regions
      const regionIndex = regionKeys.indexOf(region);
      if (regionIndex !== -1 && regionIndex < gridOperators.length) {
        // Use the grid operator at the corresponding index
        gridOperatorOptions.push({
          value: gridOperators[regionIndex],
          label: gridOperators[regionIndex]
        });
      } else if (regionIndex >= gridOperators.length) {
        // If there are more regions than grid operators, use the last available grid operator
        gridOperatorOptions.push({
          value: gridOperators[gridOperators.length - 1],
          label: gridOperators[gridOperators.length - 1]
        });
      } else {
        console.error('Region not found or no grid operator available for the region:', region);
      }
    }

    console.log('Grid Operator Options:', gridOperatorOptions);

    return gridOperatorOptions;
  };
};
