import React from 'react';
import {
  CodeNameDTO,
  DeviceState,
} from '@energyweb/origin-device-registry-irec-local-api-react-query-client';
import { getFlagComponentByCountryCode } from '@energyweb/origin-ui-utils';
import {
  IconHoverTextProps,
  IconTextProps,
  SpecFieldProps,
} from '@energyweb/origin-ui-core';
import { ComposedDevice } from '@energyweb/origin-ui-device-data';
import { EnergyTypeEnum, PowerFormatter } from '@energyweb/origin-ui-utils';
import { useTranslation } from 'react-i18next';
import { getMainFuelType, getEnergyTypeImage } from '../utils';
import { Countries } from '@energyweb/utils-general';

// Define default values and fallback components
const DefaultCountryCode = 'UNKNOWN';
// Define a default SVG component
const DefaultFlagComponent: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  React.createElement('svg', { ...props, viewBox: "0 0 24 24" }, 
    React.createElement('text', { x: "12", y: "20", textAnchor: "middle", fontSize: "20" }, '🏳️')
  )
);

type TUseSpecsForMyDeviceCardArgs = {
  device: ComposedDevice;
  allTypes: CodeNameDTO[];
  imageUrl: string;
};

export type TUseSpecsForMyDeviceCardReturnType = {
  imageUrl: string;
  fallbackIcon: IconHoverTextProps['icon'];
  cardHeaderProps: {
    deviceName: string;
    deviceState: DeviceState;
    deviceActive: boolean;
    viewButtonText: string;
    viewButtonLink: string;
    editButtonText: string;
    editButtonLink: string;
    specFieldProps: SpecFieldProps;
  };
  cardContentProps: {
    iconsProps: IconTextProps[];
  };
};

export type TUseSpecsForMyDeviceCard = (
  args: TUseSpecsForMyDeviceCardArgs
) => TUseSpecsForMyDeviceCardReturnType;

export const useSpecsForMyDeviceCard: TUseSpecsForMyDeviceCard = ({
  device,
  allTypes,
  imageUrl,
}) => {
  const { t } = useTranslation();

  const { mainType, restType } = getMainFuelType(device.fuelType, allTypes);
  const deviceIconRegular = getEnergyTypeImage(
    mainType.toLowerCase() as EnergyTypeEnum
  );

  const cardHeaderProps: TUseSpecsForMyDeviceCardReturnType['cardHeaderProps'] =
    {
      deviceName: device.name,
      deviceState: device.status,
      deviceActive: device.active,
      viewButtonText: t('device.card.viewDetailsButton'),
      viewButtonLink: `/device/detail-view/${device.id}`,
      editButtonText: t('device.card.editButton'),
      editButtonLink: `/device/edit/${device.id}`,
      specFieldProps: {
        label: t('device.card.capacity'),
        value: PowerFormatter.format(device.capacity),
      },
    };

  // Safely handle countryCode
  const countryCode = device.countryCode || DefaultCountryCode;

  // Get country name based on countryCode, default to 'Unknown'
  const countryName = Countries.find(
    (country) => country.code === countryCode
  )?.name || 'Unknown';

  console.log('countryName', countryName);
  console.log('device.countryCode', countryCode);

  // Dynamically get the flag component and assert its type to React.FC
  const FlagComponent = getFlagComponentByCountryCode(countryCode) as React.FC<React.SVGProps<SVGSVGElement>> || DefaultFlagComponent;

  const cardContentProps: TUseSpecsForMyDeviceCardReturnType['cardContentProps'] =
    {
      iconsProps: [
        {
          icon: deviceIconRegular,
          title: mainType,
          subtitle: restType,
        },
        {
          icon: FlagComponent, // Pass the component reference
          title: `${countryName}, ${device.subregion || 'Unknown'}`,
        },
      ],
    };

  return {
    imageUrl,
    fallbackIcon: deviceIconRegular,
    cardHeaderProps,
    cardContentProps,
  };
};
