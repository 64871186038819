import React from 'react';
import {
  AEFlag,
  SAFlag,
  QAFlag,
  KWFlag,
  BHFlag,
  OMFlag,
  YEFlag,
  JOFlag,
  IQFlag,
  SYFlag,
  LBFlag,
  PSFlag,
  ILFlag,
  IRFlag,
  PKFlag,
  AFFlag,
  DZFlag,
  EGFlag,
  LYFlag,
  MAFlag,
  SDFlag,
  TNFlag,
} from '@energyweb/origin-ui-assets';
import withFlagStyles from './withFlagStyles';

// Mapping of enhanced components
const flagMap: Record<
  string,
  React.ComponentType<React.SVGProps<SVGSVGElement>>
> = {
  AE: withFlagStyles(AEFlag), // United Arab Emirates
  SA: withFlagStyles(SAFlag), // Saudi Arabia
  QA: withFlagStyles(QAFlag), // Qatar
  KW: withFlagStyles(KWFlag), // Kuwait
  BH: withFlagStyles(BHFlag), // Bahrain
  OM: withFlagStyles(OMFlag), // Oman
  YE: withFlagStyles(YEFlag), // Yemen
  JO: withFlagStyles(JOFlag), // Jordan
  IQ: withFlagStyles(IQFlag), // Iraq
  SY: withFlagStyles(SYFlag), // Syria
  LB: withFlagStyles(LBFlag), // Lebanon
  PS: withFlagStyles(PSFlag), // Palestine
  IL: withFlagStyles(ILFlag), // Israel
  IR: withFlagStyles(IRFlag), // Iran
  PK: withFlagStyles(PKFlag), // Pakistan
  AF: withFlagStyles(AFFlag), // Afghanistan
  DZ: withFlagStyles(DZFlag), // Algeria
  EG: withFlagStyles(EGFlag), // Egypt
  LY: withFlagStyles(LYFlag), // Libya
  MA: withFlagStyles(MAFlag), // Morocco
  SD: withFlagStyles(SDFlag), // Sudan
  TN: withFlagStyles(TNFlag), // Tunisia
};

export const getFlagComponentByCountryCode = (
  countryCode: string
): React.ComponentType<React.SVGProps<SVGSVGElement>> | null => {
  if (countryCode) {
    const normalizedCountryCode = countryCode.toUpperCase();
    return flagMap[normalizedCountryCode] || null;
  } else {
    console.warn('Country code is undefined or invalid.');
    return null;
  }
};
// import React from 'react';
// import {
//   AEFlag,
//   SAFlag,
//   QAFlag,
//   KWFlag,
//   BHFlag,
//   OMFlag,
//   YEFlag,
//   JOFlag,
//   IQFlag,
//   SYFlag,
//   LBFlag,
//   PSFlag,
//   ILFlag,
//   IRFlag,
//   PKFlag,
//   AFFlag,
//   DZFlag,
//   EGFlag,
//   LYFlag,
//   MAFlag,
//   SDFlag,
//   TNFlag,
// } from '@energyweb/origin-ui-assets';

// // Mapping of original components without additional styling
// const flagMap: Record<string, React.ComponentType<React.SVGProps<SVGSVGElement>>> = {
//   AE: AEFlag, // United Arab Emirates
//   SA: SAFlag, // Saudi Arabia
//   QA: QAFlag, // Qatar
//   KW: KWFlag, // Kuwait
//   BH: BHFlag, // Bahrain
//   OM: OMFlag, // Oman
//   YE: YEFlag, // Yemen
//   JO: JOFlag, // Jordan
//   IQ: IQFlag, // Iraq
//   SY: SYFlag, // Syria
//   LB: LBFlag, // Lebanon
//   PS: PSFlag, // Palestine
//   IL: ILFlag, // Israel
//   IR: IRFlag, // Iran
//   PK: PKFlag, // Pakistan
//   AF: AFFlag, // Afghanistan
//   DZ: DZFlag, // Algeria
//   EG: EGFlag, // Egypt
//   LY: LYFlag, // Libya
//   MA: MAFlag, // Morocco
//   SD: SDFlag, // Sudan
//   TN: TNFlag, // Tunisia
// };

// export const getFlagComponentByCountryCode = (
//   countryCode: string
// ): React.ComponentType<React.SVGProps<SVGSVGElement>> | null => {
//   if (countryCode) {
//     const normalizedCountryCode = countryCode.toUpperCase();
//     return flagMap[normalizedCountryCode] || null;
//   } else {
//     console.warn('Country code is undefined or invalid.');
//     return null;
//   }
// };
