// import { Countries } from '@energyweb/utils-general';
// import {
//   defaultRequirementList,
//   Requirement,
//   useRegisterDeviceFormLogic,
// } from '@energyweb/origin-ui-device-logic';
// import {
//   useAllDeviceTypes,
//   useAllDeviceFuelTypes,
//   useApiRegisterDevice,
//   useApiRegionsConfiguration,
//   useApiUserAndAccount,
//   useApiMyAccounts,
//   useCachedIRecOrg,
//   useCachedIRecConnection,
// } from '@energyweb/origin-ui-device-data';
// import { usePermissionsLogic } from '@energyweb/origin-ui-device-logic';
// import { DeviceImagesUpload } from '../../containers';
// import { useDeviceAppEnv } from '../../context';

// const permissionsConfig = [
//   ...defaultRequirementList,
//   // ...(process.env.NODE_ENV === 'development'
//   //   ? []
//   //   : [Requirement.HasIRecOrg, Requirement.HasIRecApiConnection]),
// ];

// export const useRegisterPageEffects = () => {
//   const { smartMeterId, singleAccountMode } = useDeviceAppEnv();
//   const iRecOrg = useCachedIRecOrg();
//   const iRecConnection = useCachedIRecConnection();

//   const {
//     user,
//     exchangeDepositAddress,
//     isLoading: userAndAccountLoading,
//   } = useApiUserAndAccount();
//   const { canAccessPage, requirementsProps } = usePermissionsLogic({
//     user,
//     exchangeDepositAddress,
//     iRecOrg,
//     iRecConnection,
//     config: permissionsConfig,
//   });

//   const { allTypes: allFuelTypes, isLoading: areFuelTypesLoading } =
//     useAllDeviceFuelTypes();
//   const { allTypes: allDeviceTypes, isLoading: areDeviceTypesLoading } =
//     useAllDeviceTypes();
//   const { myAccounts, isLoading: areMyAccountsLoading } = useApiMyAccounts({
//     enabled: singleAccountMode,
//   });
//   const {
//     allRegions,
//     country,
//     isLoading: areRegionsLoading,
//   } = useApiRegionsConfiguration();
//   const platformCountryCode = Countries.find(
//     (cntr) => cntr.name === country
//   )?.code;

//   const formsLogic = useRegisterDeviceFormLogic({
//     allFuelTypes,
//     allDeviceTypes,
//     allRegions,
//     myAccounts,
//     externalDeviceId: smartMeterId,
//     platformCountryCode,
//     singleAccountMode,
//   });

//   const { submitHandler, isMutating } =
//     useApiRegisterDevice(platformCountryCode);

//   const formsWithImagesUpload = formsLogic.forms.map((form) =>
//     form.customStep
//       ? {
//           ...form,
//           component: DeviceImagesUpload,
//         }
//       : form
//   );

//   const formProps = {
//     ...formsLogic,
//     forms: formsWithImagesUpload,
//     submitHandler,
//   };
//   const isLoading =
//     areFuelTypesLoading ||
//     areDeviceTypesLoading ||
//     areRegionsLoading ||
//     userAndAccountLoading ||
//     areMyAccountsLoading;

//   return { isLoading, isMutating, formProps, canAccessPage, requirementsProps };
// };
import { Countries } from '@energyweb/utils-general';
import {
  defaultRequirementList,
  Requirement,
  useRegisterDeviceFormLogic,
} from '@energyweb/origin-ui-device-logic';
import {
  useAllDeviceTypes,
  useAllDeviceFuelTypes,
  useApiRegisterDevice,
  useApiRegionsConfiguration,
  useApiUserAndAccount,
  useApiMyAccounts,
  useCachedIRecOrg,
  useCachedIRecConnection,
} from '@energyweb/origin-ui-device-data';
import { usePermissionsLogic } from '@energyweb/origin-ui-device-logic';
import { DeviceUploadsWrapper } from '../../containers';
// import { DeviceFilesUpload } from '../../containers';
import { useDeviceAppEnv } from '../../context';
import { ConnectingAirportsOutlined } from '@mui/icons-material';

const permissionsConfig = [
  ...defaultRequirementList,
  // ...(process.env.NODE_ENV === 'development'
  //   ? []
  //   : [Requirement.HasIRecOrg, Requirement.HasIRecApiConnection]),
];

export const useRegisterPageEffects = () => {
  const { smartMeterId, singleAccountMode } = useDeviceAppEnv();
  const iRecOrg = useCachedIRecOrg();
  const iRecConnection = useCachedIRecConnection();

  const {
    user,
    exchangeDepositAddress,
    isLoading: userAndAccountLoading,
  } = useApiUserAndAccount();
  const { canAccessPage, requirementsProps } = usePermissionsLogic({
    user,
    exchangeDepositAddress,
    iRecOrg,
    iRecConnection,
    config: permissionsConfig,
  });

  const { allTypes: allFuelTypes, isLoading: areFuelTypesLoading } = useAllDeviceFuelTypes();
  const { allTypes: allDeviceTypes, isLoading: areDeviceTypesLoading } = useAllDeviceTypes();
  const { myAccounts, isLoading: areMyAccountsLoading } = useApiMyAccounts({
    enabled: singleAccountMode,
  });

  // Use the updated useApiRegionsConfiguration hook
  const { allRegions, countryNames, isLoading: areRegionsLoading } = useApiRegionsConfiguration();

  // The first country in config.json is UAE
  const platformCountryCode = countryNames?.length > 0
    ? Countries.find(cntr => cntr.name === countryNames[0])?.code
    : undefined;

  const formsLogic = useRegisterDeviceFormLogic({
    allFuelTypes,
    allDeviceTypes,
    allRegions,
    myAccounts,
    externalDeviceId: smartMeterId,
    platformCountryCode,
    singleAccountMode,
  });

  const { submitHandler, isMutating } = useApiRegisterDevice(platformCountryCode);

  const formsWithFilesUpload = formsLogic.forms.map(form =>
    form.customStep ? { ...form, component: DeviceUploadsWrapper } : form
  );

console.log('formsWithFilesUpload', formsWithFilesUpload); 
console.log('DeviceUploadsWrapper', DeviceUploadsWrapper);

  const formProps = {
    ...formsLogic,
    forms: formsWithFilesUpload as any,
    submitHandler,
  };

  // const formProps = {
  //   ...formsLogic,
  //   forms: formsWithFilesUpload,
  //   submitHandler: (values:any) => {
  //     // Console logs to check file IDs and image IDs
  //     console.log('Image IDs:', values.imageIds);
  //     console.log('File IDs:', values.fileIds);

  //     submitHandler(values);
  //   },
  // };

  const isLoading =
    areFuelTypesLoading ||
    areDeviceTypesLoading ||
    areRegionsLoading ||
    userAndAccountLoading ||
    areMyAccountsLoading;

  return { isLoading, isMutating, formProps, canAccessPage, requirementsProps };
};
