// import { FullOrganizationInfoDTO } from '@energyweb/origin-backend-react-query-client';
// import {
//   OriginDeviceDTO,
//   NewDeviceDTO as OriginCreateDeviceDTO,
// } from '@energyweb/origin-device-registry-api-react-query-client';
// import {
//   PublicDeviceDTO,
//   DeviceDTO as IRecMyDeviceDTO,
//   CreateDeviceDTO as IRecCreateDeviceDTO,
// } from '@energyweb/origin-device-registry-irec-local-api-react-query-client';
// import { PowerFormatter } from '@energyweb/origin-ui-utils';
// import { TimeZone, TimeZones } from '@energyweb/utils-general';
// import { uniqBy } from 'lodash';
// import {
//   ComposedDevice,
//   ComposedPublicDevice,
//   TRegisterDeviceFormValues,
// } from './types';
// import { BigNumber } from '@ethersproject/bignumber';

// export function composePublicDevices(
//   originDevices: OriginDeviceDTO[],
//   iRecDevices: PublicDeviceDTO[]
// ): ComposedPublicDevice[] {
//   if (!originDevices || !iRecDevices) return [];
//   const composedResult: ComposedPublicDevice[] = [];

//   for (const originDevice of originDevices) {
//     const matchingIRecDevice = iRecDevices.find(
//       (device) => device.id === originDevice.externalRegistryId
//     );
//     composedResult.push({
//       ...originDevice,
//       ...matchingIRecDevice,
//       id: originDevice.id,
//     });
//   }
//   return composedResult;
// }

// export function composeMyDevices(
//   originDevices: OriginDeviceDTO[],
//   iRecDevices: IRecMyDeviceDTO[]
// ): ComposedDevice[] {
//   const composedResult: ComposedDevice[] = [];

//   for (const originDevice of originDevices) {
//     const matchingIRecDevice = iRecDevices.find(
//       (device) => device.id === originDevice.externalRegistryId
//     );
//     composedResult.push({
//       ...originDevice,
//       ...matchingIRecDevice,
//       id: originDevice.id,
//     });
//   }
//   return composedResult;
// }

// export type TDecomposeForIRecArgs = {
//   newDevice: TRegisterDeviceFormValues;
//   organization: FullOrganizationInfoDTO;
//   platformCountryCode: string;
//   moreThanOneTimeZone: boolean;
//   timeZones: TimeZone[];
// };

// export function decomposeForIRec({
//   newDevice,
//   organization,
//   platformCountryCode,
//   moreThanOneTimeZone,
//   timeZones,
// }: TDecomposeForIRecArgs): IRecCreateDeviceDTO {
//   // const capacityInMW = parseFloat(newDevice.capacity);

//   // if (isNaN(capacityInMW)) {
//   //   throw new Error('Invalid capacity value');
//   // }

//   // console.log('This is the capacity in MW', capacityInMW);

//   // // Convert capacity to BigNumber in watts
//   // const capacityInWatts = BigNumber.from(Math.floor(capacityInMW * 1_000_000));
//   // console.log(
//   //   'This is the capacity in watts (BigNumber)',
//   //   capacityInWatts.toString()
//   // );
//   // console.log('timeZones', timeZones);
//   // const capacityInWatts = PowerFormatter.getBaseValueFromValueInDisplayUnit(newDevice.capacity);
//   // console.log(
//   //   'This is the capacity in watts (BigNumber)',
//   //   capacityInWatts
//   // );

//   // Safely extract the first value of the gridOperator array
//   const gridOperator = newDevice.gridOperator && newDevice.gridOperator.length > 0
//     ? newDevice.gridOperator[0].toString() // Assuming gridOperator is array of FormSelectOption with value as string
//     : '';

//   const iRecCreateDevice: IRecCreateDeviceDTO = {
//     name: newDevice.facilityName,
//     deviceType: newDevice.deviceType[0].value.toString(),
//     fuelType: newDevice.fuelType[0].value.toString(),
//     countryCode: platformCountryCode,
//     //  capacity: capacityInWatts.toString(),
//     capacity: PowerFormatter.getBaseValueFromValueInDisplayUnit(
//       newDevice.capacity
//     ),
//     commissioningDate: newDevice.commissioningDate,
//     registrationDate: newDevice.registrationDate,
//     address: newDevice.address,
//     latitude: newDevice.latitude,
//     longitude: newDevice.longitude,
//     notes: newDevice.description,
//     timezone: moreThanOneTimeZone
//       ? newDevice.timeZone[0].value.toString()
//       : timeZones[0].timeZone,
//     // gridOperator: newDevice.gridOperator,
//     gridOperator: gridOperator,
//     irecTradeAccountCode: newDevice.irecTradeAccountCode || undefined,
//     postalCode: organization.zipCode,
//     region: newDevice.region[0].value.toString(),
//     subregion: newDevice.subregion[0].value.toString(),
//     files: newDevice.imageIds,
//   };

//   return iRecCreateDevice;
// }

// export function decomposeForOrigin(
//   newDevice: TRegisterDeviceFormValues
// ): OriginCreateDeviceDTO {
//   return {
//     externalRegistryId: null,
//     smartMeterId: newDevice.smartMeterId,
//     description: newDevice.description,
//     externalDeviceIds: [],
//     imageIds: newDevice.imageIds,
//   };
// }

// export const getCountriesTimeZones = (platformCountryCode: string) => {
//   console.log('This is the platformCountryCode', platformCountryCode);
//   const countryTimezones = uniqBy(
//     TimeZones.filter(
//       (timezone) => timezone.countryCode === platformCountryCode
//     ),
//     'utcOffset'
//   );

//   console.log('This is the countryTimezones', countryTimezones);
//   console.log('platformCountryCode', platformCountryCode);
//   const moreThanOneTimeZone = countryTimezones.length > 1;

//   return { countryTimezones, moreThanOneTimeZone };
// };
import { FullOrganizationInfoDTO } from '@energyweb/origin-backend-react-query-client';
import {
  OriginDeviceDTO,
  NewDeviceDTO as OriginCreateDeviceDTO,
} from '@energyweb/origin-device-registry-api-react-query-client';
import {
  PublicDeviceDTO,
  DeviceDTO as IRecMyDeviceDTO,
  CreateDeviceDTO as IRecCreateDeviceDTO,
} from '@energyweb/origin-device-registry-irec-local-api-react-query-client';
import { PowerFormatter } from '@energyweb/origin-ui-utils';
import { TimeZone, TimeZones, Countries } from '@energyweb/utils-general';
import { uniqBy } from 'lodash';
import {
  ComposedDevice,
  ComposedPublicDevice,
  TRegisterDeviceFormValues,
  GridOperator,
} from './types';
import { BigNumber } from '@ethersproject/bignumber';

export function composePublicDevices(
  originDevices: OriginDeviceDTO[],
  iRecDevices: PublicDeviceDTO[]
): ComposedPublicDevice[] {
  if (!originDevices || !iRecDevices) return [];
  const composedResult: ComposedPublicDevice[] = [];

  for (const originDevice of originDevices) {
    const matchingIRecDevice = iRecDevices.find(
      (device) => device.id === originDevice.externalRegistryId
    );
    composedResult.push({
      ...originDevice,
      ...matchingIRecDevice,
      id: originDevice.id,
    });
  }
  return composedResult;
}

export function composeMyDevices(
  originDevices: OriginDeviceDTO[],
  iRecDevices: IRecMyDeviceDTO[]
): ComposedDevice[] {
  const composedResult: ComposedDevice[] = [];

  for (const originDevice of originDevices) {
    const matchingIRecDevice = iRecDevices.find(
      (device) => device.id === originDevice.externalRegistryId
    );
    composedResult.push({
      ...originDevice,
      ...matchingIRecDevice,
      id: originDevice.id,
    });
  }
  return composedResult;
}

export type TDecomposeForIRecArgs = {
  newDevice: TRegisterDeviceFormValues;
  organization: FullOrganizationInfoDTO;
  platformCountryCode: string;
  moreThanOneTimeZone: boolean;
  timeZones: TimeZone[];
};

// Extract the country name from the location string
const getCountryNameFromLocation = (location: string): string => {
  const [countryName] = location.split('-').map((part) => part.trim());
  return countryName;
};

// Function to get the country code based on the country name
const getCountryCodeByName = (countryName: string): string | undefined => {
  const country = Countries.find(
    (country) => country.name.toLowerCase() === countryName.toLowerCase()
  );
  return country ? country.code : undefined;
};

export function decomposeForIRec({
  newDevice,
  organization,
  platformCountryCode,
  moreThanOneTimeZone,
  timeZones,
}: TDecomposeForIRecArgs): IRecCreateDeviceDTO {
  // Extract the country name from the location
  const countryName = getCountryNameFromLocation(newDevice.region[0].label);
  // Get the country code from the country name
  const deviceCountryCode = getCountryCodeByName(countryName);

  // Log the full gridOperator object to inspect its structure
  console.log('Full gridOperator object:', newDevice.gridOperator);

  // Safely extract the first value of the gridOperator array
  let gridOperator = '';
  if (newDevice.gridOperator && newDevice.gridOperator.length > 0) {
    const firstElement = newDevice.gridOperator[0];
    console.log('First gridOperator element:', firstElement);

    if (typeof firstElement === 'string') {
      // If it's a string, use it directly
      console.log('First gridOperator element is a string');
      gridOperator = firstElement;
    } else if (typeof firstElement === 'object' && firstElement !== null) {
      // If it's an object, assume it has a 'value' property
      gridOperator = (firstElement as GridOperator).value.toString();
      console.log(
        'First gridOperator element is an object with a value property'
      );
    } else {
      // Handle other types if necessary
      gridOperator = String(firstElement);
    }
  }

  // Log the extracted gridOperator value
  console.log('Extracted gridOperator value:', gridOperator);

  // Log imageIds and fileIds
  console.log('Image IDs:', newDevice.imageIds);
  console.log('File IDs:', newDevice.fileIds);

  const iRecCreateDevice: IRecCreateDeviceDTO = {
    name: newDevice.facilityName,
    deviceType: newDevice.deviceType[0].value.toString(),
    fuelType: newDevice.fuelType[0].value.toString(),
    countryCode: deviceCountryCode,
    capacity: PowerFormatter.getBaseValueFromValueInDisplayUnit(
      newDevice.capacity
    ),
    commissioningDate: newDevice.commissioningDate,
    registrationDate: newDevice.registrationDate,
    address: newDevice.address,
    latitude: newDevice.latitude,
    longitude: newDevice.longitude,
    notes: newDevice.description,
    timezone: moreThanOneTimeZone
      ? newDevice.timeZone[0].value.toString()
      : timeZones[0].timeZone,
    gridOperator: gridOperator,
    irecTradeAccountCode: newDevice.irecTradeAccountCode || undefined,
    postalCode: organization.zipCode,
    region: newDevice.region[0].value.toString(),
    subregion: newDevice.subregion[0].value.toString(),
    images: newDevice.imageIds,
    files: newDevice.fileIds,
  };

  return iRecCreateDevice;
}

export function decomposeForOrigin(
  newDevice: TRegisterDeviceFormValues
): OriginCreateDeviceDTO {
  // Log imageIds and fileIds
  console.log('Image IDs:', newDevice.imageIds);
  console.log('File IDs:', newDevice.fileIds);

  return {
    externalRegistryId: null,
    smartMeterId: newDevice.smartMeterId,
    description: newDevice.description,
    externalDeviceIds: [],
    imageIds: newDevice.imageIds,
    fileIds: newDevice.fileIds,
  };
}

export const getCountriesTimeZones = (platformCountryCode: string) => {
  console.log('This is the platformCountryCode', platformCountryCode);
  const countryTimezones = uniqBy(
    TimeZones.filter(
      (timezone) => timezone.countryCode === platformCountryCode
    ),
    'utcOffset'
  );

  console.log('This is the countryTimezones', countryTimezones);
  console.log('platformCountryCode', platformCountryCode);
  const moreThanOneTimeZone = countryTimezones.length > 1;

  return { countryTimezones, moreThanOneTimeZone };
};
