// import { Unit } from '@energyweb/utils-general';
// import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
// import { commify } from '@ethersproject/units';

// export class EnergyFormatter {
//   public static readonly displayUnit = 'MWh';
//   public static readonly decimalPlaces = 3;

//   static getValueInDisplayUnit(baseValue: BigNumberish): number {
//     const valueBN = BigNumber.from(baseValue);

//     const whole = valueBN.div(Unit[EnergyFormatter.displayUnit]);
//     const mod = valueBN.mod(Unit[EnergyFormatter.displayUnit]);
//     return parseFloat(
//       `${whole}.${mod.toString().slice(0, EnergyFormatter.decimalPlaces)}`
//     );
//   }

//   static getBaseValueFromValueInDisplayUnit(
//     valueInDisplayUnit: number
//   ): BigNumber {
//     return BigNumber.from(valueInDisplayUnit).mul(
//       Unit[EnergyFormatter.displayUnit]
//     );
//   }

//   static format(baseValue: BigNumberish, includeDisplayUnit?: boolean): string {
//     const commifiedValue = commify(
//       String(EnergyFormatter.getValueInDisplayUnit(BigNumber.from(baseValue)))
//     );
//     return String(commifiedValue).concat(
//       includeDisplayUnit ? ` ${EnergyFormatter.displayUnit}` : ''
//     );
//   }
// }
import { Unit } from '@energyweb/utils-general';
import { BigNumber, BigNumberish } from '@ethersproject/bignumber';
import { commify } from '@ethersproject/units';

export class EnergyFormatter {
  public static readonly displayUnit = 'MWh';
  public static readonly decimalPlaces = 3;

  private static formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: EnergyFormatter.decimalPlaces,
  });

  /**
   * Converts a base value (in smallest unit) to the display unit (e.g., from Wh to MWh).
   * @param baseValue The value in the base unit.
   * @returns The value converted to the display unit.
   */
  static getValueInDisplayUnit(baseValue: BigNumberish): number {
    const valueBN = BigNumber.from(baseValue);
    // Convert the value to the display unit by dividing by the unit conversion factor.
    return parseFloat(
      valueBN.div(Unit[EnergyFormatter.displayUnit]).toString()
    );
  }

  /**
   * Converts a value in the display unit to the base unit.
   * @param valueInDisplayUnit The value in the display unit (e.g., MWh).
   * @returns The value converted to the base unit (e.g., Wh).
   */
  static getBaseValueFromValueInDisplayUnit(
    valueInDisplayUnit: number | string
  ): BigNumber {
    const valueAsNumber =
      typeof valueInDisplayUnit === 'number'
        ? valueInDisplayUnit
        : parseFloat(valueInDisplayUnit);

    // Convert the display unit value to the base unit by multiplying by the unit conversion factor.
    const baseValue = BigNumber.from(
      Math.round(
        valueAsNumber * Unit[EnergyFormatter.displayUnit as keyof typeof Unit]
      )
    );

    return baseValue;
  }
  /**
   * Formats the base energy value (in smallest unit, e.g., Wh) into the display unit (e.g., MWh).
   * @param baseValue The base value of energy.
   * @param includeDisplayUnit If true, appends the display unit to the formatted string.
   * @returns The formatted energy value as a string.
   */
  static format(
    baseValue: number | string,
    includeDisplayUnit?: boolean
): string {
    if (baseValue === null || baseValue === undefined) {
        const result = includeDisplayUnit
            ? `0 ${EnergyFormatter.displayUnit}`
            : '0';
        return result;
    }

    const baseValueNumber =
        typeof baseValue === 'number' ? baseValue : parseFloat(baseValue);

    console.log('Base Energy Value:', baseValueNumber);

    const factor = Math.pow(10, EnergyFormatter.decimalPlaces);
    console.log('Decimal Places Factor:', factor);

    const multipliedValue = baseValueNumber * factor;
    console.log('Multiplied Value:', multipliedValue);

    const energyValue = BigNumber.from(Math.round(multipliedValue))
        .div(Unit[EnergyFormatter.displayUnit as keyof typeof Unit]);

    console.log('Energy Value:', energyValue.toString());

    const finalValue = parseFloat(energyValue.toString()) / factor;
    console.log('Final Value:', finalValue);

    return `${EnergyFormatter.formatter.format(finalValue)}${includeDisplayUnit ? ' ' + EnergyFormatter.displayUnit : ''}`;
}


  /**
   * Converts the given energy value in base unit (e.g., Wh) to the display unit (e.g., MWh).
   * @param baseValue The base value of energy.
   * @returns The converted energy value as a number in the display unit.
   */
  static formatToNumber(baseValue: number | string): number {
    if (!baseValue) {
      return 0;
    }

    const baseValueNumber =
      typeof baseValue === 'number' ? baseValue : parseFloat(baseValue);

    // Convert the base value to the display unit
    return (
      BigNumber.from(
        Math.round(
          baseValueNumber * Math.pow(10, EnergyFormatter.decimalPlaces)
        )
      )
        .div(Unit[EnergyFormatter.displayUnit as keyof typeof Unit])
        .toNumber() / Math.pow(10, EnergyFormatter.decimalPlaces)
    );
  }

  /**
   * Formats the energy capacity from base unit to display unit.
   * @param capacity The capacity value in base unit.
   * @returns The formatted capacity as a string in the display unit.
   */
  static formatCapacity(capacity: number | string): string {
    const capacityInMWh = BigNumber.from(capacity).div(Unit['MWh']).toNumber();
    return capacityInMWh.toFixed(EnergyFormatter.decimalPlaces);
  }
}
