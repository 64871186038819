// import { ConfigurationDTORegions } from '@energyweb/origin-backend-react-query-client';
// import { ConfigurationDTORegions } from '@energyweb/origin-ui-exchange-data';
// import { FormSelectOption } from '@energyweb/origin-ui-core';

// export const prepareRegionsOptions = (
//   allRegions: ConfigurationDTORegions
// ): FormSelectOption[] => {
//   const allRegionsOptions = allRegions
//     ? Object.keys(allRegions).map((region) => ({
//         value: region,
//         label: region,
//       }))
//     : [];

//   return allRegionsOptions;
// };
import { FormSelectOption } from '@energyweb/origin-ui-core';
import { ConfigurationDTORegions } from '@energyweb/origin-ui-device-data';

export const prepareRegionsOptions = (
  allRegions: ConfigurationDTORegions
): FormSelectOption[] => {
  const regionsOptions: FormSelectOption[] = [];

  if (allRegions) {
    // Collect all regions with their corresponding country
    const regionsList: { country: string; region: string }[] = [];

    Object.keys(allRegions).forEach((country) => {
      Object.keys(allRegions[country].regions).forEach((region) => {
        regionsList.push({ country, region });
      });
    });

    // Sort regions alphabetically by combined country and region
    regionsList.sort((a, b) => {
      const combinedA = `${a.country} - ${a.region}`;
      const combinedB = `${b.country} - ${b.region}`;
      return combinedA.localeCompare(combinedB);
    });

    // Convert the sorted list into FormSelectOption format
    regionsList.forEach(({ country, region }) => {
      regionsOptions.push({
        value: `${country} - ${region}`,
        label: `${country} - ${region}`,
      });
    });
  }

  return regionsOptions;
};