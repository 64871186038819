// import { FileUploadAction, FileUploadState } from './FileUpload.types';

// export const initialState: FileUploadState = {};

// export const reducer = (
//   state: FileUploadState,
//   action: FileUploadAction
// ): FileUploadState => {
//   switch (action.type) {
//     case 'setFile':
//       return { ...state, [action.payload.id]: action.payload.file };
//     case 'deleteFile':
//       return {
//         ...state,
//         [action.payload.id]: { ...state[action.payload.id], removed: true },
//       };
//     case 'cancelFileUpload':
//       return {
//         ...state,
//         [action.payload.id]: {
//           ...state[action.payload.id],
//           cancelled: true,
//           uploadProgress: 0,
//           uploadedName: null,
//           cancelToken: null,
//         },
//       };
//     case 'setFileProgress':
//       return {
//         ...state,
//         [action.payload.id]: {
//           ...state[action.payload.id],
//           uploadProgress: action.payload.uploadProgress,
//         },
//       };
//     case 'setFileUploadCancelToken':
//       return {
//         ...state,
//         [action.payload.id]: {
//           ...state[action.payload.id],
//           cancelToken: action.payload.cancelToken,
//           cancelled: false,
//         },
//       };
//     case 'removeInitialFile':
//       const newState = { ...state };
//       delete newState[action.payload.id];
//       return newState;
//     default:
//       return state;
//   }
// };

import { FileUploadAction, FileUploadState } from './FileUpload.types';

export const initialState: FileUploadState = {};

export const reducer = (
  state: FileUploadState,
  action: FileUploadAction
): FileUploadState => {
  console.log('Reducer received action:', action); // Log each action

  switch (action.type) {
    case 'setFile':
      console.log('Setting file:', action.payload.file);
      return { ...state, [action.payload.id]: action.payload.file };
    case 'deleteFile':
      console.log('Deleting file with id:', action.payload.id);
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], removed: true },
      };
    case 'removeInitialFile':
      console.log('Removing initial file with id:', action.payload.id);
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], removed: true },
      };
    case 'cancelFileUpload':
      console.log('Cancelling file upload with id:', action.payload.id);
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          cancelled: true,
          uploadProgress: 0,
          uploadedName: null,
          cancelToken: null,
        },
      };
    case 'setFileProgress':
      console.log(
        'Setting file progress for id:',
        action.payload.id,
        'Progress:',
        action.payload.uploadProgress
      );
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          uploadProgress: action.payload.uploadProgress,
        },
      };
    case 'setFileUploadCancelToken':
      console.log('Setting file upload cancel token for id:', action.payload);
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          cancelToken: action.payload.cancelToken,
          cancelled: false,
        },
      };
    default:
      console.log('Unknown action type:');
      return state;
  }
};
