// import {
//   CardWithImageProps,
//   IconTextProps,
//   SpecFieldProps,
// } from '@energyweb/origin-ui-core';
// import { getFlagComponentByCountryCode } from '@energyweb/origin-ui-utils';
// import { EnergyTypeEnum, PowerFormatter } from '@energyweb/origin-ui-utils';
// import { useTranslation } from 'react-i18next';
// import { TUseSpecsForAllDeviceCard } from './types';
// import { getMainFuelType, getEnergyTypeImage } from '../utils';
// import { getDeviceAgeInYears } from '../utils';
// import { Countries } from '@energyweb/utils-general';
// // TODO implement a flag finder
// export const useSpecsForAllDeviceCard: TUseSpecsForAllDeviceCard = ({
//   device,
//   allTypes,
//   clickHandler,
//   imageUrl,
// }) => {
//   const { t } = useTranslation();

//   const countryName = Countries.find(
//     (country) => country.code === device.countryCode
//   )?.name;

//   const specsData: SpecFieldProps[] = [
//     {
//       label: t('device.card.capacity'),
//       value: PowerFormatter.format(device.capacity),
//     },
//     {
//       label: t('device.card.age'),
//       value: getDeviceAgeInYears(device.commissioningDate),
//     },
//   ];
//   const { mainType, restType } = getMainFuelType(device.fuelType, allTypes);

//   const FlagComponent = getFlagComponentByCountryCode(
//     device.countryCode
//   ) as React.FC<React.SVGProps<SVGSVGElement>>;

//   const deviceIcon = getEnergyTypeImage(
//     mainType.toLowerCase() as EnergyTypeEnum
//   );
//   const iconsData: IconTextProps[] = [
//     {
//       icon: deviceIcon,
//       title: mainType,
//       subtitle: restType,
//     },
//     {
//       icon: FlagComponent || null,
//       title: `${countryName}, ${device.subregion}`,
//     },
//   ];

//   const detailViewLink = `/device/detail-view/${device.id}`;

//   const cardProps: Omit<CardWithImageProps, 'content'> = {
//     heading: device.name,
//     hoverText: t('device.card.hoverText').toUpperCase(),
//     imageUrl,
//     fallbackIcon: deviceIcon,
//     onActionClick: () => clickHandler(detailViewLink),
//   };

//   return { specsData, iconsData, cardProps };
// };

import {
  CardWithImageProps,
  IconTextProps,
  SpecFieldProps,
} from '@energyweb/origin-ui-core';
import { getFlagComponentByCountryCode } from '@energyweb/origin-ui-utils';
import { EnergyTypeEnum, PowerFormatter } from '@energyweb/origin-ui-utils';
import { useTranslation } from 'react-i18next';
import { TUseSpecsForAllDeviceCard } from './types';
import { getMainFuelType, getEnergyTypeImage } from '../utils';
import { getDeviceAgeInYears } from '../utils';
import { Countries } from '@energyweb/utils-general';

export const useSpecsForAllDeviceCard: TUseSpecsForAllDeviceCard = ({
  device,
  allTypes,
  clickHandler,
  nameClickHandler, // Accept the second click handler
  imageUrl,
}) => {
  const { t } = useTranslation();

  const countryName = Countries.find(
    (country) => country.code === device.countryCode
  )?.name;

  const specsData: SpecFieldProps[] = [
    {
      label: t('device.card.capacity'),
      value: PowerFormatter.format(device.capacity),
    },
    {
      label: t('device.card.age'),
      value: getDeviceAgeInYears(device.commissioningDate),
    },
  ];
  const { mainType, restType } = getMainFuelType(device.fuelType, allTypes);

  const FlagComponent = getFlagComponentByCountryCode(
    device.countryCode
  ) as React.FC<React.SVGProps<SVGSVGElement>>;

  const deviceIcon = getEnergyTypeImage(
    mainType.toLowerCase() as EnergyTypeEnum
  );
  const iconsData: IconTextProps[] = [
    {
      icon: deviceIcon,
      title: mainType,
      subtitle: restType,
    },
    {
      icon: FlagComponent || null,
      title: `${countryName}, ${device.subregion}`,
    },
  ];

  const detailViewLink = `/device/detail-view/${device.id}`;

  const cardProps: Omit<CardWithImageProps, 'content'> = {
    heading: device.name,
    hoverText: t('device.card.hoverText').toUpperCase(),
    imageUrl,
    fallbackIcon: deviceIcon,
    onActionClick: () => clickHandler(detailViewLink),
    onHeadingClick: nameClickHandler, // Handle name clicks with this new prop
  };

  return { specsData, iconsData, cardProps };
};
