// import { useState, useEffect } from 'react';
// import { UploadedFile } from '@energyweb/origin-ui-core';
// import { publicFileUploadHandler } from '@energyweb/origin-ui-device-data';
// import { DeviceImagesFormValues } from '@energyweb/origin-ui-device-logic';
// import { useTranslation } from 'react-i18next';

// export const useEditDeviceImagesUploadEffects = () => {
//   const [imageIds, setImageIds] = useState<UploadedFile[]>([]);
//   const { t } = useTranslation();

//   const values: DeviceImagesFormValues = {
//     imageIds: imageIds
//       .filter((doc) => !doc.removed)
//       .map((doc) => doc.uploadedName),
//   };

//   const uploadText = t('file.upload.dropOrClick');
//   const deviceImagesHeading = t('device.register.deviceImagesFormTitle');
//   const uploadFunction = publicFileUploadHandler;

//   const onDeviceImageChange = (newValues: UploadedFile[]) => {
//     if (JSON.stringify(newValues) !== JSON.stringify(imageIds)) {
//       setImageIds(newValues);
//     }
//   };

//   const buttonText = t('general.buttons.submit');

//   return {
//     values,
//     uploadText,
//     uploadFunction,
//     onDeviceImageChange,
//     deviceImagesHeading,
//     buttonText,
//   };
// };
import { useState, useEffect } from 'react';
import { UploadedFile } from '@energyweb/origin-ui-core';
import { publicFileUploadHandler } from '@energyweb/origin-ui-device-data';
import { DeviceImagesFormValues } from '@energyweb/origin-ui-device-logic';
import { useTranslation } from 'react-i18next';
// import { useFileContext } from '@energyweb/origin-ui-device-view'; // Import the context
// import { set } from 'lodash';

export const useEditDeviceImagesUploadEffects = () => {
  const [imageIds, setImageIds] = useState<UploadedFile[]>([]);
  const { t } = useTranslation();

  // Access the uploadedFiles context
  // const { uploadedFiles, setUploadedFiles } = useFileContext();

  const values: DeviceImagesFormValues = {
    imageIds: imageIds
      .filter((doc) => !doc.removed)
      .map((doc) => doc.uploadedName),
  };

  const uploadText = t('file.upload.dropOrClick');
  const deviceImagesHeading = t('device.register.deviceImagesFormTitle');
  const uploadFunction = publicFileUploadHandler;

  const onDeviceImageChange = (newValues: UploadedFile[]) => {
    if (JSON.stringify(newValues) !== JSON.stringify(imageIds)) {
      setImageIds(newValues);

      // Update the uploadedFiles context using values.imageIds
      // setUploadedFiles((prevUploadedFiles) => {

      //   console.log('effect: Previous Files:', prevUploadedFiles);
      //   console.log('effect: New Files:', values.imageIds);
      //   // Merge new image IDs with the current uploaded files in the context
      //   const updatedFiles = [
      //     ...prevUploadedFiles.filter((id) => values.imageIds.includes(id)),
      //     ...values.imageIds.filter((id) => !prevUploadedFiles.includes(id)),
      //   ];

      //   console.log('effect: Updated Files:', updatedFiles);
      //   return updatedFiles;
      // });
    }
  };

  const buttonText = t('general.buttons.submit');

  return {
    values,
    uploadText,
    uploadFunction,
    onDeviceImageChange,
    deviceImagesHeading,
    buttonText,
  };
};

