import React, { createContext, useState, useContext, ReactNode } from 'react';
import { CustomFile } from '@energyweb/origin-ui-core';

interface FileContextProps {
    removedInitialFiles: CustomFile[];
    setRemovedInitialFiles: React.Dispatch<React.SetStateAction<CustomFile[]>>;
    uploadedFiles: CustomFile[];
    setUploadedFiles: React.Dispatch<React.SetStateAction<CustomFile[]>>;
  }
  
  const FileContext = createContext<FileContextProps | undefined>(undefined);
  
  export const FileProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [removedInitialFiles, setRemovedInitialFiles] = useState<CustomFile[]>([]);
    const [uploadedFiles, setUploadedFiles] = useState<CustomFile[]>([]);
  
    return (
      <FileContext.Provider value={{ removedInitialFiles, setRemovedInitialFiles, uploadedFiles, setUploadedFiles }}>
        {children}
      </FileContext.Provider>
    );
  };
  
  export const useFileContext = () => {
    const context = useContext(FileContext);
    if (!context) {
      throw new Error('useFileContext must be used within a FileProvider');
    }
    return context;
  };
