import { TCreateDeviceUploadFilesForm } from './types';

export const createDeviceUploadFilesForm: TCreateDeviceUploadFilesForm = (t) => ({
  formTitle: t('device.register.deviceFilesFormTitle'),
  initialValues: {
    imageIds: [],
    fileIds: [],
  },
  fields: null,
  validationSchema: null,
  customStep: true,
  buttonText: t('general.buttons.submit'),
});
