// import { useTranslation } from 'react-i18next';
// import { EnergyFormatter, formatDate } from '@energyweb/origin-ui-utils';
// import {
//   TUseLogicClaimsReport,
//   TFormatClaimsReportData,
//   TFormatClaimsReportReturnData,
// } from './types';
// import { useNavigate } from 'react-router-dom';
// import { IconButton } from '@mui/material';
// import DownloadIcon from '@mui/icons-material/Download';

// const formatClaimsReportData: TFormatClaimsReportData = ({
//   devices,
//   blockchainCertificates,
//   claimedCertificates,
//   allFuelTypes,
// }) => {
//   return claimedCertificates?.length > 0 && devices.length > 0
//     ? claimedCertificates?.map((certificate) => {
//         const compliance = 'I-REC';
//         const fullCertificateData = blockchainCertificates?.find(
//           (bc) => bc.id === certificate.id
//         );

//         const device = devices.find(
//           (device) =>
//             fullCertificateData?.deviceId === device.externalRegistryId
//         );

//         return {
//           id: `${certificate.id};${certificate.claimData.periodStartDate}`,
//           fuelType:
//             allFuelTypes?.find((type) => type.code === device?.fuelType)
//               ?.name || '',
//           vintageCOD: formatDate(device?.commissioningDate),
//           location: `${device?.region}, ${device?.subregion}`,
//           gridOperator: device?.gridOperator,
//           compliance,
//           claimDate: formatDate(certificate.claimData.periodStartDate),
//           certifiedEnergy: EnergyFormatter.getValueInDisplayUnit(
//             certificate.value
//           ).toString(),
//           creationTime: fullCertificateData?.creationTime ?? 0,
//           redemptionStatement: (
//             <IconButton
//               component="a"
//               href={certificate.claimData.irecVerificationUrl}
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               <DownloadIcon />
//             </IconButton>
//           ), // Add JSX element for the download link
//         };
//       })
//     : ([] as TFormatClaimsReportReturnData);
// };

// export const useLogicClaimsReport: TUseLogicClaimsReport = ({
//   devices,
//   blockchainCertificates,
//   claimedCertificates,
//   allFuelTypes,
//   loading,
// }) => {
//   const { t } = useTranslation();
//   const navigate = useNavigate();
//   return {
//     header: {
//       fuelType: t('certificate.redemptionsReport.fuelType'),
//       vintageCOD: t('certificate.redemptionsReport.vintageCOD'),
//       location: t('certificate.redemptionsReport.location'),
//       gridOperator: t('certificate.redemptionsReport.gridOperator'),
//       compliance: t('certificate.redemptionsReport.compliance'),
//       claimDate: t('certificate.redemptionsReport.claimDate'),
//       certifiedEnergy: `${t('certificate.redemptionsReport.certifiedEnergy')} (${
//         EnergyFormatter.displayUnit
//       })`,
//       redemptionStatement: t('certificate.redemptionsReport.redemptionStatement'),
//     },
//     pageSize: 10,
//     loading,
//     data: formatClaimsReportData({
//       devices,
//       blockchainCertificates,
//       claimedCertificates,
//       allFuelTypes,
//     }),
//     onRowClick: (id) => {
//       const certificateId = id.split(';')[0];
//       navigate(`/certificate/detail-view/${certificateId}`);
//     },
//   };
// };
import React from 'react';
import { useTranslation } from 'react-i18next';
import { EnergyFormatter, formatDate } from '@energyweb/origin-ui-utils';
import { TUseLogicClaimsReport, TFormatClaimsReportData, TFormatClaimsReportReturnData } from './types';
import { useNavigate } from 'react-router-dom';
import { IconButton, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

const formatClaimsReportData: TFormatClaimsReportData = ({ devices, blockchainCertificates, claimedCertificates, allFuelTypes }) => {
  return claimedCertificates?.length > 0 && devices.length > 0
    ? claimedCertificates?.map((certificate) => {
        const compliance = 'I-REC';
        const fullCertificateData = blockchainCertificates?.find((bc) => bc.id === certificate.id);

        const device = devices.find((device) => fullCertificateData?.deviceId === device.externalRegistryId);

        return {
          id: `${certificate.id};${certificate.claimData.periodStartDate}`,
          fuelType: allFuelTypes?.find((type) => type.code === device?.fuelType)?.name || '',
          vintageCOD: formatDate(device?.commissioningDate),
          location: `${device?.region}, ${device?.subregion}`,
          gridOperator: device?.gridOperator,
          compliance,
          claimDate: formatDate(certificate.claimData.periodStartDate),
          certifiedEnergy: EnergyFormatter.getValueInDisplayUnit(certificate.value).toString(),
          creationTime: fullCertificateData?.creationTime ?? 0,
          redemptionStatement: (
            <Tooltip title={`Verification Key: ${certificate.claimData.verificationKey}`}>
              <IconButton
                component="a"
                href={certificate.claimData.irecVerificationUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                <DownloadIcon />
              </IconButton>
            </Tooltip>
          ), // Add JSX element for the download link
        };
      })
    : ([] as TFormatClaimsReportReturnData);
};

export const useLogicClaimsReport: TUseLogicClaimsReport = ({ devices, blockchainCertificates, claimedCertificates, allFuelTypes, loading }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return {
    header: {
      fuelType: t('certificate.redemptionsReport.fuelType'),
      vintageCOD: t('certificate.redemptionsReport.vintageCOD'),
      location: t('certificate.redemptionsReport.location'),
      gridOperator: t('certificate.redemptionsReport.gridOperator'),
      compliance: t('certificate.redemptionsReport.compliance'),
      claimDate: t('certificate.redemptionsReport.claimDate'),
      certifiedEnergy: `${t('certificate.redemptionsReport.certifiedEnergy')} (${EnergyFormatter.displayUnit})`,
      redemptionStatement: t('certificate.redemptionsReport.redemptionStatement'),
    },
    pageSize: 10,
    loading,
    data: formatClaimsReportData({ devices, blockchainCertificates, claimedCertificates, allFuelTypes }),
    onRowClick: (id) => {
      const certificateId = id.split(';')[0];
      navigate(`/certificate/detail-view/${certificateId}`);
    },
  };
};
