// import {
//   FileUpload,
//   GenericFormSecondaryButton,
// } from '@energyweb/origin-ui-core';
// import { Box, Button, CircularProgress } from '@mui/material';
// import { DeviceImagesFormValues } from '@energyweb/origin-ui-device-logic';
// import React, { FC } from 'react';
// import { useDeviceImagesUploadEffects } from './DeviceImagesUpload.effects';
// import { UnpackNestedValue } from 'react-hook-form';

// export interface DeviceImagesUploadProps {
//   submitHandler: (
//     values: UnpackNestedValue<DeviceImagesFormValues>
//   ) => Promise<void>;
//   secondaryButtons?: GenericFormSecondaryButton[];
//   loading?: boolean;
//   buttonText?: string; 
// }

// export const DeviceImagesUpload: FC<DeviceImagesUploadProps> = ({
//   submitHandler,
//   secondaryButtons,
//   loading,
// }) => {
//   const {
//     values,
//     uploadText,
//     uploadFunction,
//     onDeviceImageChange,
//     deviceImagesHeading,
//     buttonText,
//   } = useDeviceImagesUploadEffects();

//   //TODO hardcoded Create Draft as it is not passed as prop
//   return (
//     <Box>
//       <FileUpload
//         dropzoneText={uploadText}
//         heading={deviceImagesHeading}
//         apiUploadFunction={uploadFunction}
//         onChange={onDeviceImageChange}
//         wrapperProps={{ ['data-cy']: 'deviceImages' }}
//       />
//       <Box mt={1} display="flex" justifyContent="flex-end">
//         {secondaryButtons &&
//           secondaryButtons.map((button) => (
//             <Button key={`secondary-button-${button.label}`} {...button}>
//               {button.label}
//             </Button>
//           ))}
//         <Button
//           color="primary"
//           name="submit"
//           size="large"
//           variant="contained"
//           disabled={loading}
//           onClick={() => submitHandler(values)}
//         >
//           {/* {buttonText} */} 
//           {'Create Draft'}
//           {loading && (
//             <Box ml={2}>
//               <CircularProgress size={20} />
//             </Box>
//           )}
//         </Button>
//       </Box>
//     </Box>
//   );
// };
import { FileUpload } from '@energyweb/origin-ui-core';
import { Box } from '@mui/material';
import React, { FC } from 'react';

interface DeviceImagesUploadProps {
  uploadText: string;
  uploadFunction: any;
  onDeviceChange: (newValues: any) => void;
  heading: string;
}

export const DeviceImagesUpload: FC<DeviceImagesUploadProps> = ({
  uploadText,
  uploadFunction,
  onDeviceChange,
  heading,
}) => (
  <Box>
    <FileUpload
      dropzoneText={uploadText}
      heading={heading}
      apiUploadFunction={uploadFunction}
      onChange={onDeviceChange}
      wrapperProps={{ ['data-cy']: 'deviceImages' }}
    />
  </Box>
);

export default DeviceImagesUpload;
// import React, { FC } from 'react';
// import { FileUpload } from '@energyweb/origin-ui-core';
// import { Box } from '@mui/material';
// import { CustomFile } from '@energyweb/origin-ui-core';

// interface DeviceImagesUploadProps {
//   uploadText: string;
//   uploadFunction: any;
//   onDeviceChange: (newValues: any) => void;
//   heading: string;
//   initialFiles?: CustomFile[]; // Use CustomFile type for initial files
// }

// export const DeviceImagesUpload: FC<DeviceImagesUploadProps> = ({
//   uploadText,
//   uploadFunction,
//   onDeviceChange,
//   heading,
//   initialFiles = [],
// }) => (
//   <Box>
//     <FileUpload
//       dropzoneText={uploadText}
//       heading={heading}
//       apiUploadFunction={uploadFunction}
//       onChange={onDeviceChange}
//       initialFiles={initialFiles} // Pass the initial files directly
//       wrapperProps={{ ['data-cy']: 'deviceImages' }}
//     />
//   </Box>
// );

// export default DeviceImagesUpload;





