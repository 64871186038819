// import { SyntheticEvent, useEffect, useRef, useState } from 'react';
// import { GenericFormField } from '../../../containers';
// import { FormSelectOption } from '../FormSelect';

// export const useSelectAutocompleteEffects = <FormValuesType>(
//   onChange: (newValue: FormSelectOption[]) => void,
//   dependentValue: FormSelectOption[],
//   field: GenericFormField<FormValuesType>
// ) => {
//   const isMountedRef = useRef(false);
//   const [textValue, setTextValue] = useState<string>('');

//   const changeHandler = (
//     event: SyntheticEvent,
//     value: (string | FormSelectOption)[]
//   ) => {
//     const maxValues = field.multiple ? field.maxValues : 1;
//     const slicedValues = value
//       ? (value as FormSelectOption[]).slice(0, maxValues ?? value.length)
//       : (value as FormSelectOption[]);

//     onChange(slicedValues);

//     setTextValue('');
//   };

//   useEffect(() => {
//     if (dependentValue?.length === 0 && isMountedRef.current === true) {
//       setTextValue('');
//       onChange([]);
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [dependentValue]);

//   useEffect(() => {
//     isMountedRef.current = true;
//     return () => {
//       isMountedRef.current = false;
//     };
//   }, []);

//   const options =
//     !!field.dependentOn && field.dependentOptionsCallback
//       ? field.dependentOptionsCallback(dependentValue) || []
//       : field.options;

//   return {
//     options,
//     textValue,
//     setTextValue,
//     changeHandler,
//   };
// };
import { SyntheticEvent, useEffect, useRef, useState } from 'react';
import { GenericFormField } from '../../../containers';
import { FormSelectOption } from '../FormSelect';

export const useSelectAutocompleteEffects = <FormValuesType>(
  onChange: (newValue: FormSelectOption[]) => void,
  dependentValue: FormSelectOption[],
  field: GenericFormField<FormValuesType>
) => {
  const isMountedRef = useRef(false);
  const [textValue, setTextValue] = useState<string>('');

  console.log('useSelectAutocompleteEffects called');
  console.log('Initial Options:', field.options);
  console.log('Initial Dependent Value:', dependentValue);

  const changeHandler = (
    event: SyntheticEvent,
    value: (string | FormSelectOption)[]
  ) => {
    console.log('changeHandler triggered');
    console.log('Event:', event);
    console.log('New Value:', value);

    const maxValues = field.multiple ? field.maxValues : 1;
    const slicedValues = value
      ? (value as FormSelectOption[]).slice(0, maxValues ?? value.length)
      : (value as FormSelectOption[]);

    console.log('Sliced Values:', slicedValues);

    onChange(slicedValues);
    setTextValue('');
  };

  useEffect(() => {
    if (dependentValue?.length === 0 && isMountedRef.current === true) {
      console.log('Dependent Value changed to empty. Resetting textValue.');
      setTextValue('');
      onChange([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dependentValue]);

  useEffect(() => {
    isMountedRef.current = true;
    console.log('Component Mounted');

    return () => {
      isMountedRef.current = false;
      console.log('Component Unmounted');
    };
  }, []);

  const options =
    !!field.dependentOn && field.dependentOptionsCallback
      ? field.dependentOptionsCallback(dependentValue) || []
      : field.options;

      if (!Array.isArray(options)) {
        console.error("Options is not an array:", options);
      } else {
        console.log("Options are arrays:", options);
      }

  return {
    options,
    textValue,
    setTextValue,
    changeHandler,
  };
};
