// import { GenericFormProps } from '@energyweb/origin-ui-core';
// import {
//   EditDeviceFormValues,
//   useAllDeviceFuelTypes,
//   useAllDeviceTypes,
//   useDeviceDetailData,
// } from '@energyweb/origin-ui-device-data';
// import { useEditDeviceFormLogic } from '@energyweb/origin-ui-device-logic';
// import { useParams } from 'react-router';
// import {
//   DeviceModalsActionsEnum,
//   useDeviceModalsDispatch,
// } from '../../context';
// import { useState } from 'react';

// export const useEditDevicePageEffects = () => {
//   const { id } = useParams();
//   const dispatchModals = useDeviceModalsDispatch();

//   const [removedInitialFiles, setRemovedInitialFiles] = useState<string[]>([]);

//   const {
//     device,
//     isLoading: isDeviceLoading,
//     isRefetching: isDeviceRefetching,
//   } = useDeviceDetailData(id);

//   console.log('Fetched device data:', device);
//   console.log('Is device loading:', isDeviceLoading);
//   console.log('Is device refetching:', isDeviceRefetching);

//   const { allTypes: allFuelTypes, isLoading: areFuelTypesLoading } =
//     useAllDeviceFuelTypes();
//   const { allTypes: allDeviceTypes, isLoading: areDeviceTypesLoading } =
//     useAllDeviceTypes();

//   console.log('Fetched fuel types:', allFuelTypes);
//   console.log('Is fuel types loading:', areFuelTypesLoading);
//   console.log('Fetched device types:', allDeviceTypes);
//   console.log('Is device types loading:', areDeviceTypesLoading);

//   const openConfirmModal = (values: EditDeviceFormValues) => {
//     console.log('Form values on submit:', values);
//     dispatchModals({
//       type: DeviceModalsActionsEnum.SHOW_CONFIRM_EDIT,
//       payload: {
//         open: true,
//         editData: values,
//         device,
//       },
//     });
//   };

//   const formLogic = useEditDeviceFormLogic(
//     device,
//     allFuelTypes,
//     allDeviceTypes
//   );
//   console.log('Form logic:', formLogic);
//   const formConfig: GenericFormProps<EditDeviceFormValues> = {
//     ...formLogic,
//     submitHandler: openConfirmModal,
//     buttonText: 'Submit', // Change button text to 'Submit'
//     alwaysEnableSubmit: true,
//   };

//   const isLoading =
//     isDeviceLoading ||
//     areFuelTypesLoading ||
//     areDeviceTypesLoading ||
//     isDeviceRefetching;

//   return { isLoading, formConfig, setRemovedInitialFiles };
// };
// import { GenericFormProps } from '@energyweb/origin-ui-core';
// import {
//   EditDeviceFormValues,
//   useAllDeviceFuelTypes,
//   useAllDeviceTypes,
//   useDeviceDetailData,
// } from '@energyweb/origin-ui-device-data';
// import { useEditDeviceFormLogic } from '@energyweb/origin-ui-device-logic';
// import { useParams } from 'react-router';
// import { useState, useEffect, useCallback, useMemo } from 'react';
// import {
//   DeviceModalsActionsEnum,
//   useDeviceModalsDispatch,
// } from '../../context';

// export const useEditDevicePageEffects = () => {
//   const { id } = useParams();
//   const dispatchModals = useDeviceModalsDispatch();

//   const [removedInitialFiles, setRemovedInitialFiles] = useState<string[]>([]);

//   const {
//     device,
//     isLoading: isDeviceLoading,
//     isRefetching: isDeviceRefetching,
//   } = useDeviceDetailData(id);

//   useEffect(() => {
//     console.log('Fetched device data:', device);
//   }, [device]);

//   const { allTypes: allFuelTypes, isLoading: areFuelTypesLoading } =
//     useAllDeviceFuelTypes();
//   const { allTypes: allDeviceTypes, isLoading: areDeviceTypesLoading } =
//     useAllDeviceTypes();

//   useEffect(() => {
//     console.log('Fetched fuel types:', allFuelTypes);
//   }, [allFuelTypes]);

//   useEffect(() => {
//     console.log('Fetched device types:', allDeviceTypes);
//   }, [allDeviceTypes]);

//   const openConfirmModal = useCallback((values: EditDeviceFormValues) => {
//     console.log('Form values on submit:', values);
//     dispatchModals({
//       type: DeviceModalsActionsEnum.SHOW_CONFIRM_EDIT,
//       payload: {
//         open: true,
//         editData: values,
//         device,
//         removedInitialFiles,
//       },
//     });
//   }, [dispatchModals, device, removedInitialFiles]);

//   const formLogic = useEditDeviceFormLogic(
//     device,
//     allFuelTypes,
//     allDeviceTypes,
//     isDeviceLoading,
//     setRemovedInitialFiles
//   );

//   useEffect(() => {
//     console.log('Form logic:', formLogic);
//     console.log('Removed initial files:', removedInitialFiles);
//   }, [formLogic, removedInitialFiles]);

//   const formConfig: GenericFormProps<EditDeviceFormValues> = useMemo(() => ({
//     ...formLogic,
//     submitHandler: openConfirmModal,
//     buttonText: 'Submit',
//     alwaysEnableSubmit: true,
//   }), [formLogic, openConfirmModal]);

//   const isLoading =
//     isDeviceLoading ||
//     areFuelTypesLoading ||
//     areDeviceTypesLoading ||
//     isDeviceRefetching;

//   return { isLoading, formConfig };
// };
import { GenericFormProps } from '@energyweb/origin-ui-core';
import {
  EditDeviceFormValues,
  useAllDeviceFuelTypes,
  useAllDeviceTypes,
  useDeviceDetailData,
} from '@energyweb/origin-ui-device-data';
import { useEditDeviceFormLogic } from '@energyweb/origin-ui-device-logic';
import { useParams } from 'react-router';
import { useEffect, useCallback, useMemo } from 'react';
import {
  DeviceModalsActionsEnum,
  useDeviceModalsDispatch,
} from '../../context';
import { useFileContext } from '@energyweb/origin-ui-device-view';

export const useEditDevicePageEffects = () => {
  const { id } = useParams();
  const dispatchModals = useDeviceModalsDispatch();
  const { removedInitialFiles } = useFileContext();

  const {
    device,
    isLoading: isDeviceLoading,
    isRefetching: isDeviceRefetching,
  } = useDeviceDetailData(id);

  useEffect(() => {
    console.log('Fetched device data:', device);
  }, [device]);

  const { allTypes: allFuelTypes, isLoading: areFuelTypesLoading } =
    useAllDeviceFuelTypes();
  const { allTypes: allDeviceTypes, isLoading: areDeviceTypesLoading } =
    useAllDeviceTypes();

  useEffect(() => {
    console.log('Fetched fuel types:', allFuelTypes);
  }, [allFuelTypes]);

  useEffect(() => {
    console.log('Fetched device types:', allDeviceTypes);
  }, [allDeviceTypes]);

  const openConfirmModal = useCallback((values: EditDeviceFormValues) => {
    console.log('Form values on submit:', values);

    // Convert removedInitialFiles from CustomFile[] to string[] (just filenames)
    const removedFileNames = removedInitialFiles.map(file => file.filename);

    dispatchModals({
      type: DeviceModalsActionsEnum.SHOW_CONFIRM_EDIT,
      payload: {
        open: true,
        editData: values,
        device,
        removedInitialFiles: removedFileNames,  // Pass the filenames
      },
    });
  }, [dispatchModals, device, removedInitialFiles]);

  const formLogic = useEditDeviceFormLogic(
    device,
    allFuelTypes,
    allDeviceTypes,
    isDeviceLoading
  );

  useEffect(() => {
    console.log('Form logic:', formLogic);
    console.log('Removed initial files:', removedInitialFiles);
  }, [formLogic, removedInitialFiles]);

  const formConfig: GenericFormProps<EditDeviceFormValues> = useMemo(() => ({
    ...formLogic,
    submitHandler: openConfirmModal,
    buttonText: 'Submit',
    alwaysEnableSubmit: true,
  }), [formLogic, openConfirmModal]);

  const isLoading =
    isDeviceLoading ||
    areFuelTypesLoading ||
    areDeviceTypesLoading ||
    isDeviceRefetching;

  return { isLoading, formConfig };
};
