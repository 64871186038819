// import { FormSelectOption } from '@energyweb/origin-ui-core';

// export const prepareSubregionOptions = (
//   allRegions: Record<string, string[]>
// ): ((selected: FormSelectOption[]) => FormSelectOption[]) => {
//   return (selectedRegion: FormSelectOption[]) => {
//     if (!selectedRegion) {
//       return [];
//     }
//     const selectedValue = selectedRegion[0]?.value;
//     const availableSubregions: string[] = allRegions[selectedValue];
//     const options: FormSelectOption[] = availableSubregions?.map(
//       (subregion) => ({
//         value: subregion,
//         label: subregion,
//       })
//     );
//     return options;
//   };
// };
// import { FormSelectOption } from '@energyweb/origin-ui-core';

// export const prepareSubregionOptions = (
//   allRegions: Record<string, Record<string, string[]>>
// ): ((selected: FormSelectOption[]) => FormSelectOption[]) => {
//   return (selectedRegion: FormSelectOption[]) => {
//     if (!selectedRegion || selectedRegion.length === 0) {
//       return [];
//     }

//     const selectedValue = selectedRegion[0]?.value;
//     if (typeof selectedValue !== 'string') {
//       console.error('Selected value is not a string:', selectedValue);
//       return [];
//     }

//     // Ensure selectedValue is treated as a string and split it
//     const [country, region] = (selectedValue as string).split(' - ');

//     console.log('Country:', country);
//     console.log('Region:', region);

//     // Get the subregions for the selected country and region
//     const availableSubregions = allRegions[country]?.[region] || [];

//     // Map the subregions to the expected format for options
//     const options: FormSelectOption[] = availableSubregions.map((subregion) => ({
//       value: subregion,
//       label: subregion,
//     }));
    
//     console.log('Subregions Options:', options);
//     return options;
//   };
// };

// import { FormSelectOption } from '@energyweb/origin-ui-core';

// // Adjusted structure to include regions and gridOperators
// export const prepareSubregionOptions = (
//   allRegions: Record<string, { regions: Record<string, string[]>; gridOperators: string[] }>
// ): ((selected: FormSelectOption[]) => FormSelectOption[]) => {
//   return (selectedRegion: FormSelectOption[]) => {
//     if (!selectedRegion || selectedRegion.length === 0) {
//       return [];
//     }

//     const selectedValue = selectedRegion[0]?.value;
//     if (typeof selectedValue !== 'string') {
//       console.error('Selected value is not a string:', selectedValue);
//       return [];
//     }

//     // Split the selected value to extract country and region
//     const [country, region] = selectedValue.split(' - ');

//     console.log('Country:', country);
//     console.log('Region:', region);

//     // Access the subregions for the selected country and region
//     const availableSubregions = allRegions[country]?.regions?.[region] || [];

//     // Convert subregions to FormSelectOption format
//     const options: FormSelectOption[] = availableSubregions.map((subregion) => ({
//       value: subregion,
//       label: subregion,
//     }));

//     console.log('Subregions Options:', options);
//     return options;
//   };
// };
import { FormSelectOption } from '@energyweb/origin-ui-core';

// Adjusted structure to include regions and gridOperators
export const prepareSubregionOptions = (
  allRegions: Record<string, { regions: Record<string, string[]>; gridOperators: string[] }>
): ((selected: FormSelectOption[]) => FormSelectOption[]) => {
  return (selectedRegion: FormSelectOption[]) => {
    if (!selectedRegion || selectedRegion.length === 0) {
      return [];
    }

    const selectedValue = selectedRegion[0]?.value;
    if (typeof selectedValue !== 'string') {
      console.error('Selected value is not a string:', selectedValue);
      return [];
    }

    // Split the selected value to extract country and region
    const [country, region] = selectedValue.split(' - ');

    console.log('Country:', country);
    console.log('Region:', region);

    // Access the subregions for the selected country and region
    const availableSubregions = allRegions[country]?.regions?.[region] || [];

    // Sort the subregions alphabetically
    availableSubregions.sort((a, b) => a.localeCompare(b));

    // Convert subregions to FormSelectOption format
    const options: FormSelectOption[] = availableSubregions.map((subregion) => ({
      value: subregion,
      label: subregion,
    }));

    console.log('Subregions Options:', options);
    return options;
  };
};
