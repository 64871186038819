import {
  Box,
  Card,
  CardActionArea,
  CardActionAreaProps,
  CardContent,
  CardProps,
  Typography,
  TypographyProps,
} from '@mui/material';
import React, {
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  ImgHTMLAttributes,
  ReactNode,
} from 'react';
import { ImageWithHoverText } from '../../images';
import { IconHoverText } from '../../icons';
import { useStyles } from './CardWithImage.styles';

export interface CardWithImageProps {
  heading: string;
  content: ReactNode;
  imageUrl: string;
  onActionClick?: () => void;
  onHeadingClick?: () => void;
  fallbackIcon?: FC<React.SVGProps<SVGSVGElement>>;
  fallbackIconWrapperProps?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  fallbackIconProps?: React.SVGProps<SVGSVGElement>;
  hoverText?: string;
  cardProps?: CardProps;
  cardActionAreaProps?: CardActionAreaProps;
  imageWrapperProps?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  imageProps?: ImgHTMLAttributes<HTMLImageElement>;
  overlayProps?: DetailedHTMLProps<
    HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  >;
  overlayTextProps?: TypographyProps;
}

// export const CardWithImage: FC<CardWithImageProps> = ({
//   heading,
//   content,
//   fallbackIcon,
//   fallbackIconWrapperProps,
//   fallbackIconProps,
//   imageUrl,
//   hoverText,
//   onActionClick,
//   cardProps,
//   cardActionAreaProps,
//   imageWrapperProps,
//   imageProps,
//   overlayProps,
//   overlayTextProps,
// }) => {
//   const classes = useStyles();
//   return (
//     <Card className={classes.card} {...cardProps}>
//       <CardActionArea onClick={onActionClick} {...cardActionAreaProps}>
//         {imageUrl ? (
//           <ImageWithHoverText
//             src={imageUrl}
//             text={hoverText}
//             imageWrapperProps={
//               imageWrapperProps || { className: classes.imageWrapper }
//             }
//             imageProps={imageProps || { className: classes.image }}
//             overlayProps={overlayProps}
//             overlayTextProps={overlayTextProps}
//           />
//         ) : (
//           <IconHoverText
//             icon={fallbackIcon}
//             hoverText={hoverText}
//             iconProps={fallbackIconProps || { className: classes.image }}
//             wrapperProps={fallbackIconWrapperProps}
//             overlayProps={overlayProps}
//             overlayTextProps={overlayTextProps}
//           />
//         )}
//       </CardActionArea>
//       <Box py={1} px={2} className={classes.heading}>
//         <Typography variant="h6">{heading}</Typography>
//       </Box>
//       <CardContent>{content}</CardContent>
//     </Card>
//   );
// };
export const CardWithImage: FC<CardWithImageProps> = ({
  heading,
  content,
  fallbackIcon,
  fallbackIconWrapperProps,
  fallbackIconProps,
  imageUrl,
  hoverText,
  onActionClick,
  onHeadingClick,  // Accept the new prop
  cardProps,
  cardActionAreaProps,
  imageWrapperProps,
  imageProps,
  overlayProps,
  overlayTextProps,
}) => {
  const classes = useStyles();
  return (
    <Card className={classes.card} {...cardProps}>
      <CardActionArea onClick={onActionClick} {...cardActionAreaProps}>
        {imageUrl ? (
          <ImageWithHoverText
            src={imageUrl}
            text={hoverText}
            imageWrapperProps={
              imageWrapperProps || { className: classes.imageWrapper }
            }
            imageProps={imageProps || { className: classes.image }}
            overlayProps={overlayProps}
            overlayTextProps={overlayTextProps}
          />
        ) : (
          <IconHoverText
            icon={fallbackIcon}
            hoverText={hoverText}
            iconProps={fallbackIconProps || { className: classes.image }}
            wrapperProps={fallbackIconWrapperProps}
            overlayProps={overlayProps}
            overlayTextProps={overlayTextProps}
          />
        )}
      </CardActionArea>
      <Box
        py={1}
        px={2}
        className={classes.heading}
        onClick={onHeadingClick}  // Attach the heading click handler
        style={{ cursor: onHeadingClick ? 'pointer' : 'default' }} // Change cursor if the handler exists
      >
        <Typography
          variant="h6"
          style={{
            textDecoration: 'none', // No underline by default
            color: 'black', // Keep the text color black
          }}
          onMouseEnter={(e) => {
            if (onHeadingClick) {
              (e.target as HTMLElement).style.textDecoration = 'underline';
            }
          }}
          onMouseLeave={(e) => {
            (e.target as HTMLElement).style.textDecoration = 'none';
          }}
        >
          {heading}
        </Typography>
      </Box>
      <CardContent>{content}</CardContent>
    </Card>
  );
};
