// import { UploadedFile } from '@energyweb/origin-ui-core';
// import { publicFileUploadHandler } from '@energyweb/origin-ui-device-data';
// import { DeviceFilesFormValues } from '@energyweb/origin-ui-device-logic';
// import { useState } from 'react';
// import { useTranslation } from 'react-i18next';

// export const useDeviceFilesUploadEffects = () => {
//   const [fileIds, setFileIds] = useState<UploadedFile[]>([]);

//   const values: DeviceFilesFormValues = {
//     fileIds: fileIds
//       .filter((doc) => !doc.removed)
//       .map((doc) => doc.uploadedName),
//   };
//   const { t } = useTranslation();

//   const uploadText = t('file.upload.dropOrClick');
//   const deviceFilesHeading = t('device.register.deviceSupportingFilesFormTitle');

//   const uploadFunction = publicFileUploadHandler;
//   const onDeviceFileChange = (newValues: UploadedFile[]) =>
//     setFileIds(newValues);

//   const buttonText = t('general.buttons.submit');

//   return {
//     values,
//     uploadText,
//     uploadFunction,
//     onDeviceFileChange,
//     deviceFilesHeading,
//     buttonText,

//   };
// };
import { useState, useEffect } from 'react';
import { UploadedFile } from '@energyweb/origin-ui-core';
import { publicFileUploadHandler } from '@energyweb/origin-ui-device-data';
import { DeviceFilesFormValues } from '@energyweb/origin-ui-device-logic';
import { useTranslation } from 'react-i18next';

export const useEditDeviceFilesUploadEffects = () => {
  const [fileIds, setFileIds] = useState<UploadedFile[]>([]);
  const { t } = useTranslation();

  const values: DeviceFilesFormValues = {
    fileIds: fileIds
      .filter((doc) => !doc.removed)
      .map((doc) => doc.uploadedName),
  };

  const uploadText = t('file.upload.dropOrClick');
  const deviceFilesHeading = t('device.register.deviceSupportingFilesFormTitle');
  const uploadFunction = publicFileUploadHandler;

  const onDeviceFileChange = (newValues: UploadedFile[]) => {
    if (JSON.stringify(newValues) !== JSON.stringify(fileIds)) {
      setFileIds(newValues);
    }
  };

  const buttonText = t('general.buttons.submit');

  return {
    values,
    uploadText,
    uploadFunction,
    onDeviceFileChange,
    deviceFilesHeading,
    buttonText,
    fileIds,
  };
};

