// import { FormSelectOption } from '@energyweb/origin-ui-core';
// import { Countries } from '@energyweb/utils-general';

// export const prepareSubRegionsOptions = (
//   allRegions: Record<string, { regions: Record<string, string[]>; gridOperators: string[] }>,
//   country: string,
//   selectedRegionsOptions: FormSelectOption[]
// ) => {
//   const selectedRegionsValues = selectedRegionsOptions?.map(
//     (option) => option.value
//   );

//   const countryCode = Countries.find((cntr) => cntr.name === country)?.code;

//   const subregionsOption = selectedRegionsValues?.flatMap((region) => {
//     const matchingSubregions: string[] = allRegions[region];
//     const options: FormSelectOption[] = matchingSubregions?.map(
//       (subregion) => ({
//         label: `${region} - ${subregion}`,
//         value: `${countryCode};${region};${subregion}`,
//       })
//     );
//     return options;
//   });

//   return subregionsOption;
// };
// import { FormSelectOption } from '@energyweb/origin-ui-core';

// // Adjusted structure to include regions and gridOperators
// export const prepareSubregionOptions = (
//   allRegions: Record<string, { regions: Record<string, string[]>; gridOperators: string[] }>
// ): ((selected: FormSelectOption[]) => FormSelectOption[]) => {
//   return (selectedRegion: FormSelectOption[]) => {
//     if (!selectedRegion || selectedRegion.length === 0) {
//       return [];
//     }

//     const selectedValue = selectedRegion[0]?.value;
//     if (typeof selectedValue !== 'string') {
//       console.error('Selected value is not a string:', selectedValue);
//       return [];
//     }

//     // Split the selected value to extract country and region
//     const [country, region] = selectedValue.split(' - ');

//     console.log('Country:', country);
//     console.log('Region:', region);

//     // Access the subregions for the selected country and region
//     const availableSubregions = allRegions[country]?.regions?.[region] || [];

//     // Sort the subregions alphabetically
//     availableSubregions.sort((a, b) => a.localeCompare(b));

//     // Convert subregions to FormSelectOption format
//     const options: FormSelectOption[] = availableSubregions.map((subregion) => ({
//       value: subregion,
//       label: subregion,
//     }));

//     console.log('Subregions Options:', options);
//     return options;
//   };
// };

import { FormSelectOption } from '@energyweb/origin-ui-core';

// Adjusted structure to include regions and gridOperators
export const prepareSubRegionsOptions = (
  allRegions: Record<string, { regions: Record<string, string[]>; gridOperators: string[] }>,
  selectedRegionsOptions: FormSelectOption[]
): FormSelectOption[] => {
  // Extract the regions from the selected options
  const selectedRegionsValues = selectedRegionsOptions?.map(
    (option) => option.value
  );

  // This will hold the formatted subregion options
  let subregionsOptions: FormSelectOption[] = [];

  // Iterate over the selected regions to fetch their subregions
  selectedRegionsValues?.forEach((selectedValue) => {
    // Ensure the selectedValue is a string
    if (typeof selectedValue === 'string') {
      // Split the selected value to get the country and region
      const [country, region] = selectedValue.split(' - ');

      // Access the subregions for the selected country and region
      const availableSubregions = allRegions[country]?.regions?.[region] || [];

      // Sort subregions alphabetically
      availableSubregions.sort((a, b) => a.localeCompare(b));

      // Convert subregions to FormSelectOption format
      const subregionOptions = availableSubregions.map((subregion) => ({
        value: `${country} - ${region} - ${subregion}`, // Include country, region, and subregion in the value
        label: `${region} - ${subregion}`, // Include region and subregion in the label
      }));

      // Add the subregion options to the main list
      subregionsOptions = [...subregionsOptions, ...subregionOptions];
    } else {
      console.error('Expected a string but got:', selectedValue);
    }
  });

  // Return the formatted and sorted subregion options
  return subregionsOptions;
};
