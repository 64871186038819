import { Grid, Typography } from '@mui/material';
import { CoordinatesLogo } from '@energyweb/origin-ui-assets'; // Keep the logo import as it is
import React, { FC } from 'react';
import { IconText, IconTextTight } from '@energyweb/origin-ui-core';
import { getFlagComponentByCountryCode } from '@energyweb/origin-ui-utils'; // Import the flag finder
import { useStyles } from './DeviceLocationData.styles';
import { Countries } from '@energyweb/utils-general'; // Assuming this is where your Countries array is

export interface DeviceLocationDataProps {
  owner: string;
  location: string; // The location in the format "Country, City"
  coordinates: string;
}

export const DeviceLocationData: FC<DeviceLocationDataProps> = ({
  owner,
  location,
  coordinates,
}) => {
  const classes = useStyles();

  // Extract the country name from the location string
  const getCountryNameFromLocation = (location: string): string => {
    const [countryName] = location.split(',').map(part => part.trim());
    return countryName;
  };

  // Function to get the country code based on the country name
  const getCountryCodeByName = (countryName: string): string | undefined => {
    const country = Countries.find(
      (country) => country.name.toLowerCase() === countryName.toLowerCase()
    );
    return country ? country.code : undefined;
  };

  // Extract the country name from the location
  const countryName = getCountryNameFromLocation(location);

  // Get the country code from the country name
  const countryCode = getCountryCodeByName(countryName);

  // Get the flag component dynamically based on the country code
  const FlagComponent = countryCode
    ? (getFlagComponentByCountryCode(countryCode) as React.FC<React.SVGProps<SVGSVGElement>>)
    : undefined;

  return (
    <div className={classes.wrapper}>
      <Grid container alignItems="center" my={1}>
        <Typography className={classes.owner}>{owner}</Typography>
      </Grid>
      {/* Render the flag dynamically */}
      <IconTextTight icon={FlagComponent || undefined} title={location} />
      <IconText icon={CoordinatesLogo} title={coordinates} />
    </div>
  );
};
