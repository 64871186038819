import { Unit } from '@energyweb/utils-general';
import { BigNumber } from '@ethersproject/bignumber';

export class PowerFormatter {
  static readonly displayUnit: string = 'MW';

  static readonly decimalPlaces: number = 3;

  private static formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: PowerFormatter.decimalPlaces,
  });

  static getBaseValueFromValueInDisplayUnit(
    valueInDisplayUnit: number | string
  ): string {
    const valueAsNumber =
      typeof valueInDisplayUnit === 'number'
        ? valueInDisplayUnit
        : parseFloat(valueInDisplayUnit);

    const valueInWatt = BigNumber.from(
      Math.round(valueAsNumber * 1_000_000)
    ).toString();
    // .mul(Unit[PowerFormatter.displayUnit as keyof typeof Unit])
    // .toString();

    console.log('Value in Watt:', valueInWatt);

    return valueInWatt;
  }

  // static getBaseValueFromValueInDisplayUnit(
  //   valueInDisplayUnit: number
  // ): string {
  //   return BigNumber.from(valueInDisplayUnit)
  //     .mul(Unit[PowerFormatter.displayUnit as keyof typeof Unit])
  //     .toString();
  // }

  // static getBaseValueFromValueInDisplayUnitDecimal(
  //   valueInDisplayUnit: number
  // ): BigNumber {
  //   return BigNumber.from(
  //     Math.floor(
  //       valueInDisplayUnit *
  //         Unit[PowerFormatter.displayUnit as keyof typeof Unit]
  //     )
  //   );
  // }

  // static format(
  //   powerInWatt: number | string,
  //   includeDisplayUnit?: boolean
  // ): string {
  //   if (!powerInWatt) {
  //     const result = includeDisplayUnit
  //       ? `0 ${PowerFormatter.displayUnit}`
  //       : '0';
  //     return result;
  //   }

  //   return `${PowerFormatter.formatter.format(
  //     BigNumber.from(powerInWatt)
  //       .div(Unit[PowerFormatter.displayUnit as keyof typeof Unit])
  //       .toNumber()
  //   )}${includeDisplayUnit ? ' ' + PowerFormatter.displayUnit : ''}`;
  // }

  static format(
    powerInWatt: number | string,
    includeDisplayUnit?: boolean
  ): string {
    if (powerInWatt === null || powerInWatt === undefined) {
      const result = includeDisplayUnit
        ? `0 ${PowerFormatter.displayUnit}`
        : '0';
      return result;
    }

    const powerInWattNumber =
      typeof powerInWatt === 'number' ? powerInWatt : parseFloat(powerInWatt);

    console.log('Power In Watt:', powerInWattNumber);

    // Scale the value before converting to BigNumber to preserve precision
    const powerValue = BigNumber.from(
      Math.round(powerInWattNumber * 1_000_000)
    ).div(Unit[PowerFormatter.displayUnit as keyof typeof Unit]);

    console.log('Power Value:', powerValue.toString());

    return `${PowerFormatter.formatter.format(
      parseFloat(powerValue.toString()) / 1_000_000
    )}${includeDisplayUnit ? ' ' + PowerFormatter.displayUnit : ''}`;
  }

  static formatToNumber(powerInWatt: number | string): number {
    if (!powerInWatt) {
      return 0;
    }

    return BigNumber.from(powerInWatt)
      .div(Unit[PowerFormatter.displayUnit as keyof typeof Unit])
      .toNumber();
  }

  static formatCapacity = (capacity: number | string): string => {
    const capacityInMW = BigNumber.from(capacity).div(Unit['MW']).toNumber();
    return capacityInMW.toFixed(3);
  };
}
