import { PowerFormatter } from '@energyweb/origin-ui-utils';
import { useTranslation } from 'react-i18next';
import { TFormatDevicesData, TUseLogicPendingDevices } from './types';

const formatDevicesData: TFormatDevicesData = ({
  devices,
  actions,
  allFuelTypes,
  allDeviceTypes,
}) => {
  return devices.map((device) => {
    // const formattedCapacity =
    //   typeof device.capacity === 'number'
    //     ? (device.capacity / 1_000_000).toFixed(3) // Convert from watts to MW and format to 3 decimal places
    //     : (parseFloat(device.capacity) / 1_000_000).toFixed(3); // Convert from string to number if necessary
    return {
      id: device.externalRegistryId,
      owner: device.owner,
      facilityName: device.name,
      location: device.address,
      gridOperator: device.gridOperator,
      fuelType:
        allFuelTypes?.find((type) => type.code === device.fuelType)?.name || '',
      deviceType:
        allDeviceTypes?.find((type) => type.code === device.deviceType)?.name ||
        '',
      capacity: PowerFormatter.format(device.capacity),
      status: device.status,
      certified: 0,
      toBeCertified: 0,
      actions,
    };
  });
};

export const useLogicPendingDevices: TUseLogicPendingDevices = ({
  devices,
  actions,
  loading,
  allFuelTypes,
  allDeviceTypes,
}) => {
  const { t } = useTranslation();

  // Calculate the year range dynamically
  const currentYear = new Date().getFullYear();
  const startYear = currentYear - 4;
  const yearToDisplay = `${startYear}/${currentYear}`;

  return {
    tableTitle: t('device.pending.tableTitle'),
    header: {
      owner: t('device.pending.owner'),
      facilityName: t('device.pending.facilityName'),
      location: t('device.pending.location'),
      gridOperator: t('device.pending.gridOperator'),
      fuelType: t('device.pending.fuelType'),
      deviceType: t('device.pending.deviceType'),
      capacity: t('device.pending.nameplateCapacity'),
      status: t('device.pending.status'),
      certified: t('device.pending.certifiedFor', {
        yearToDisplay,
      }),
      toBeCertified: t('device.pending.toBeCertifiedFor', {
        yearToDisplay,
      }),
      actions: '',
    },
    pageSize: 10,
    loading: loading,
    data: formatDevicesData({ devices, actions, allFuelTypes, allDeviceTypes }),
  };
};
