// import { useEffect, useReducer, useState } from 'react';
// import { useDropzone } from 'react-dropzone';
// import { initialState, reducer } from './FileUpload.reducer';
// import {
//   ApiUploadFunction,
//   FileUploadOnChangeFunction,
// } from './FileUpload.types';

// const FILE_SUPPORTED_MIMETYPES = [
//   'image/jpeg',
//   'image/png',
//   'application/pdf',
//   'application/msword',
//   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
// ];

// export type TUseFileUploadEffectsArgs = {
//   apiUploadFunction: ApiUploadFunction;
//   onChange: FileUploadOnChangeFunction;
// };

// export const useFileUploadEffects = ({
//   apiUploadFunction,
//   onChange,
// }: TUseFileUploadEffectsArgs) => {
//   const [state, dispatch] = useReducer(reducer, initialState);
//   const [files, setFiles] = useState<File[]>([]);

//   const { getRootProps, getInputProps } = useDropzone({
//     accept: FILE_SUPPORTED_MIMETYPES,
//     onDrop: (acceptedFiles) => {
//       setFiles([...files, ...acceptedFiles]);
//     },
//   });

//   const upload = async (file: File) => {
//     const fileIndex = files.indexOf(file);

//     try {
//       const uploadedArray = await apiUploadFunction([file as Blob]);
//       dispatch({
//         type: 'setFile',
//         payload: {
//           id: fileIndex,
//           file: {
//             ...state[fileIndex],
//             uploadedName: uploadedArray[0],
//             cancelToken: null,
//             uploadProgress: 100,
//             cancelled: false,
//           },
//         },
//       });
//     } catch (error) {
//       console.error(`Error while uploading file in FileUpload: ${error}`);
//     }
//   };

//   useEffect(() => {
//     files.forEach((f) => {
//       const fileIndex = files.indexOf(f);
//       const uploadedFile = state && state[fileIndex];

//       if (typeof uploadedFile === 'undefined') {
//         dispatch({
//           type: 'setFile',
//           payload: {
//             id: fileIndex,
//             file: {
//               uploadProgress: 0,
//               removed: false,
//               cancelled: false,
//               uploadedName: null,
//               cancelToken: null,
//             },
//           },
//         });

//         upload(f);
//       }
//     });
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [files]);

//   useEffect(() => {
//     onChange(Object.entries(state).map(([, value]) => value));
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [state]);

//   return { getRootProps, getInputProps, files, state, dispatch };
// };
// import { useEffect, useReducer, useState } from 'react';
// import { useDropzone } from 'react-dropzone';
// import { initialState, reducer } from './FileUpload.reducer';
// import {
//   ApiUploadFunction,
//   FileUploadOnChangeFunction,
//   CustomFile,
// } from './FileUpload.types';

// const FILE_SUPPORTED_MIMETYPES = [
//   'image/jpeg',
//   'image/png',
//   'application/pdf',
//   'application/msword',
//   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
// ];

// export type TUseFileUploadEffectsArgs = {
//   apiUploadFunction: ApiUploadFunction;
//   onChange: FileUploadOnChangeFunction;
// };

// export const useFileUploadEffects = ({
//   apiUploadFunction,
//   onChange,
// }: TUseFileUploadEffectsArgs) => {
//   const [state, dispatch] = useReducer(reducer, initialState);
//   const [files, setFiles] = useState<CustomFile[]>([]);

//   const { getRootProps, getInputProps } = useDropzone({
//     accept: FILE_SUPPORTED_MIMETYPES,
//     onDrop: (acceptedFiles) => {
//       const newFiles = acceptedFiles.map(file => ({
//         ...file,
//         filename: file.name,
//         contentType: file.type,
//       }));
//       setFiles([...files, ...newFiles]);
//     },
//   });

//   const upload = async (file: CustomFile) => {
//     const fileIndex = files.indexOf(file);

//     try {
//       const uploadedArray = await apiUploadFunction([file as Blob]);
//       dispatch({
//         type: 'setFile',
//         payload: {
//           id: fileIndex,
//           file: {
//             ...state[fileIndex],
//             uploadedName: uploadedArray[0],
//             cancelToken: null,
//             uploadProgress: 100,
//             cancelled: false,
//           },
//         },
//       });
//     } catch (error) {
//       console.error(`Error while uploading file in FileUpload: ${error}`);
//     }
//   };

//   useEffect(() => {
//     files.forEach((f) => {
//       const fileIndex = files.indexOf(f);
//       const uploadedFile = state && state[fileIndex];

//       if (typeof uploadedFile === 'undefined') {
//         dispatch({
//           type: 'setFile',
//           payload: {
//             id: fileIndex,
//             file: {
//               uploadProgress: 0,
//               removed: false,
//               cancelled: false,
//               uploadedName: null,
//               cancelToken: null,
//             },
//           },
//         });

//         upload(f);
//       }
//     });
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [files]);

//   useEffect(() => {
//     onChange(Object.entries(state).map(([, value]) => value));
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [state]);

//   const setInitialFiles = (initialFiles: CustomFile[]) => {
//     initialFiles.forEach((file, index) => {
//       dispatch({
//         type: 'setFile',
//         payload: {
//           id: index,
//           file: {
//             uploadedName: file.filename,
//             uploadProgress: 100,
//             cancelled: false,
//             removed: false,
//             cancelToken: null,
//           },
//         },
//       });
//     });
//   };

//   return { getRootProps, getInputProps, files, state, dispatch, setInitialFiles };
// };
// import { useEffect, useReducer, useState } from 'react';
// import { useDropzone } from 'react-dropzone';
// import { initialState, reducer } from './FileUpload.reducer';
// import {
//   ApiUploadFunction,
//   FileUploadOnChangeFunction,
// } from './FileUpload.types';

// const FILE_SUPPORTED_MIMETYPES = [
//   'image/jpeg',
//   'image/png',
//   'application/pdf',
//   'application/msword',
//   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
// ];

// export type TUseFileUploadEffectsArgs = {
//   apiUploadFunction: ApiUploadFunction;
//   onChange: FileUploadOnChangeFunction;
// };

// export const useFileUploadEffects = ({
//   apiUploadFunction,
//   onChange,
// }: TUseFileUploadEffectsArgs) => {
//   const [state, dispatch] = useReducer(reducer, initialState);
//   const [files, setFiles] = useState<File[]>([]);

//   const { getRootProps, getInputProps } = useDropzone({
//     accept: FILE_SUPPORTED_MIMETYPES,
//     onDrop: (acceptedFiles) => {
//       setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
//     },
//   });

//   const upload = async (file: File) => {
//     const fileIndex = files.indexOf(file);

//     try {
//       const uploadedArray = await apiUploadFunction([file as Blob]);
//       dispatch({
//         type: 'setFile',
//         payload: {
//           id: fileIndex,
//           file: {
//             ...state[fileIndex],
//             uploadedName: uploadedArray[0],
//             cancelToken: null,
//             uploadProgress: 100,
//             cancelled: false,
//           },
//         },
//       });
//     } catch (error) {
//       console.error(`Error while uploading file in FileUpload: ${error}`);
//     }
//   };

//   useEffect(() => {
//     files.forEach((file) => {
//       const fileIndex = files.indexOf(file);
//       const uploadedFile = state[fileIndex];

//       if (!uploadedFile) {
//         dispatch({
//           type: 'setFile',
//           payload: {
//             id: fileIndex,
//             file: {
//               uploadProgress: 0,
//               removed: false,
//               cancelled: false,
//               uploadedName: null,
//               cancelToken: null,
//             },
//           },
//         });

//         upload(file);
//       }
//     });
//   }, [files]);

//   useEffect(() => {
//     onChange(Object.entries(state).map(([, value]) => value));
//   }, [state]);

//   const setInitialFiles = (initialFiles: { filename: string; contentType: string }[]) => {
//     initialFiles.forEach((file, index) => {
//       if (!state[index]) {
//         dispatch({
//           type: 'setFile',
//           payload: {
//             id: index,
//             file: {
//               uploadedName: file.filename,
//               uploadProgress: 100,
//               cancelled: false,
//               removed: false,
//               cancelToken: null,
//             },
//           },
//         });
//       }
//     });
//   };

//   return { getRootProps, getInputProps, files, state, dispatch, setInitialFiles };
// };
// import { useEffect, useReducer, useState } from 'react';
// import { useDropzone } from 'react-dropzone';
// import { initialState, reducer } from './FileUpload.reducer';
// import {
//   ApiUploadFunction,
//   CustomFile,
// } from './FileUpload.types';
// import { useRemovedFiles } from '@energyweb/origin-ui-device-view'; // Import the hook

// const FILE_SUPPORTED_MIMETYPES = [
//   'image/jpeg',
//   'image/png',
//   'application/pdf',
//   'application/msword',
//   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
// ];

// export type TUseFileUploadDeviceEffectsArgs = {
//   apiUploadFunction: ApiUploadFunction;
//   onChange: (files: CustomFile[], removedInitialFiles?: string[]) => void; // Make the second argument optional
//   initialFiles?: CustomFile[];
// };

// export const useFileUploadDeviceEffects = ({
//   apiUploadFunction,
//   onChange,
//   initialFiles = [],
// }: TUseFileUploadDeviceEffectsArgs) => {
//   const [state, dispatch] = useReducer(reducer, initialState);
//   const [files, setFiles] = useState<File[]>([]);
//   const { removedInitialFiles, setRemovedInitialFiles } = useRemovedFiles(); // Use the hook

//   const { getRootProps, getInputProps } = useDropzone({
//     accept: FILE_SUPPORTED_MIMETYPES,
//     onDrop: (acceptedFiles) => {
//       setFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
//     },
//   });

//   const upload = async (file: File) => {
//     const fileIndex = files.indexOf(file);

//     try {
//       const uploadedArray = await apiUploadFunction([file as Blob]);
//       dispatch({
//         type: 'setFile',
//         payload: {
//           id: fileIndex,
//           file: {
//             ...state[fileIndex],
//             uploadedName: uploadedArray[0],
//             cancelToken: null,
//             uploadProgress: 100,
//             cancelled: false,
//           },
//         },
//       });
//     } catch (error) {
//       console.error(`Error while uploading file in FileUpload: ${error}`);
//     }
//   };

//   useEffect(() => {
//     files.forEach((file) => {
//       const fileIndex = files.indexOf(file);
//       const uploadedFile = state[fileIndex];

//       if (!uploadedFile) {
//         dispatch({
//           type: 'setFile',
//           payload: {
//             id: fileIndex,
//             file: {
//               uploadProgress: 0,
//               removed: false,
//               cancelled: false,
//               uploadedName: null,
//               cancelToken: null,
//             },
//           },
//         });

//         upload(file);
//       }
//     });
//   }, [files]);

//   useEffect(() => {
//     const customFiles = files.map((file) => ({
//       filename: file.name,
//       contentType: file.type,
//     }));
//     console.log('Files:', customFiles);
//     console.log('Removed Initial Files:', Array.from(removedInitialFiles).map(index => initialFiles[Number(index)]?.filename || ''));
//     onChange(customFiles, Array.from(removedInitialFiles).map(index => initialFiles[Number(index)]?.filename || ''));
//   }, [state, removedInitialFiles]);

//   const setInitialFiles = (initialFiles: CustomFile[]) => {
//     initialFiles.forEach((file, index) => {
//       if (!state[index]) {
//         dispatch({
//           type: 'setFile',
//           payload: {
//             id: index,
//             file: {
//               uploadedName: file.filename,
//               uploadProgress: 100,
//               cancelled: false,
//               removed: false,
//               cancelToken: null,
//             },
//           },
//         });
//       }
//     });
//   };

//   return {
//     getRootProps,
//     getInputProps,
//     files,
//     state,
//     dispatch,
//     setInitialFiles,
//     removedInitialFiles,
//     setRemovedInitialFiles,
//   };
// };
import { useEffect, useReducer, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { initialState, reducer } from './FileUpload.reducer';
import {
  ApiUploadFunction,
  FileUploadOnChangeFunction,
  CustomFile,
} from './FileUpload.types';
import { useFileContext } from '@energyweb/origin-ui-device-view';

const FILE_SUPPORTED_MIMETYPES = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export type TUseFileUploadDeviceEffectsArgs = {
  apiUploadFunction: ApiUploadFunction;
  onChange: FileUploadOnChangeFunction;
  initialFiles?: CustomFile[];
};

export const useFileUploadDeviceEffects = ({
  apiUploadFunction,
  onChange,
  initialFiles = [],
}: TUseFileUploadDeviceEffectsArgs) => {
  console.log('useFileUploadDeviceEffects called');
  const [state, dispatch] = useReducer(reducer, initialState);
  const [files, setFiles] = useState<File[]>([]);
  const { setUploadedFiles } = useFileContext();

  const { getRootProps, getInputProps } = useDropzone({
    accept: FILE_SUPPORTED_MIMETYPES,
    onDrop: (acceptedFiles) => {
      console.log('Files dropped:', acceptedFiles);
      setFiles([...files, ...acceptedFiles]);
    },
  });

  const upload = async (file: File) => {
    console.log('xxx Uploading file xxxx:', file.name);
    // Calculate the file index starting after the initialFiles length
    const fileIndex = initialFiles.length + files.indexOf(file);
    console.log('File index:', fileIndex);
    console.log('initialFiles:', initialFiles.length);

    try {
      const uploadedArray = await apiUploadFunction([file as Blob]);
      console.log('xxx File uploaded xxx:', uploadedArray[0]);

      const newFile: CustomFile = {
        id: uploadedArray[0],
        filename: uploadedArray[0], // assuming the returned ID is the filename
        contentType: file.type,
      };

      dispatch({
        type: 'setFile',
        payload: {
          id: fileIndex,
          file: {
            ...state[fileIndex],
            uploadedName: uploadedArray[0],
            cancelToken: null,
            uploadProgress: 100,
            cancelled: false,
          },
        },
      });
      // Update the uploaded files in the context
      // Update the uploaded files in the context
      setUploadedFiles((prev) => {
        const existingFile = prev.find((f) => f.filename === uploadedArray[0]);
        if (!existingFile) {
          console.log('----Adding uploaded file:', newFile);
          return [...prev, newFile];
        }
        return prev;
      });
    } catch (error) {
      console.error(`Error while uploading file in FileUpload: ${error}`);
    }
  };

  useEffect(() => {
    console.log('---->>>>Files:', files);
    files.forEach((file) => {
      const fileIndex = initialFiles.length + files.indexOf(file);
      const uploadedFile = state[fileIndex];
      console.log('---->>>>Uploaded file:', uploadedFile, fileIndex, state);
  
      if (!uploadedFile) {
        console.log('xxx Uploading file xxxx:', file.name);
        dispatch({
          type: 'setFile',
          payload: {
            id: fileIndex,
            file: {
              uploadProgress: 0,
              removed: false,
              cancelled: false,
              uploadedName: null,
              cancelToken: null,
            },
          },
        });
  
        upload(file);
      } else {
        console.log('Upload file already exists:', uploadedFile);
      }
    });
  }, [files, initialFiles.length, state]);
  

  useEffect(() => {
    onChange(Object.entries(state).map(([, value]) => value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  // const setInitialFiles = (initialFiles: CustomFile[]) => {
  //   console.log('Setting initial files:', initialFiles);
  //   initialFiles.forEach((file, index) => {
  //     if (!state[index]) {
  //       dispatch({
  //         type: 'setFile',
  //         payload: {
  //           id: index,
  //           file: {
  //             uploadedName: file.filename,
  //             uploadProgress: 100,
  //             cancelled: false,
  //             removed: false,
  //             cancelToken: null,
  //           },
  //         },
  //       });
  //     }
  //   });
  // };

  return {
    getRootProps,
    getInputProps,
    files,
    state,
    dispatch,
    // setInitialFiles,
  };
};
// import React, { useEffect, useReducer, useState, useRef } from 'react';
// import { useDropzone } from 'react-dropzone';
// import { initialState, reducer } from './FileUpload.reducer';
// import {
//   ApiUploadFunction,
//   FileUploadOnChangeFunction,
//   CustomFile,
// } from './FileUpload.types';
// import { useFileContext } from '@energyweb/origin-ui-device-view';

// const FILE_SUPPORTED_MIMETYPES = [
//   'image/jpeg',
//   'image/png',
//   'application/pdf',
//   'application/msword',
//   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
// ];

// export type TUseFileUploadDeviceEffectsArgs = {
//   apiUploadFunction: ApiUploadFunction;
//   onChange: FileUploadOnChangeFunction;
//   initialFiles?: CustomFile[];
// };

// export const useFileUploadDeviceEffects = ({
//   apiUploadFunction,
//   onChange,
//   initialFiles = [],
// }: TUseFileUploadDeviceEffectsArgs) => {
//   console.log('useFileUploadDeviceEffects called');
//   const [state, dispatch] = useReducer(reducer, initialState);
//   const [files, setFiles] = useState<File[]>([]);
//   const { uploadedFiles, setUploadedFiles } = useFileContext(); // Get the context values
//   const uploadedFilesRef = useRef(uploadedFiles);

//   // Initialize the context with the currently uploaded files
//   useEffect(() => {
//     if (uploadedFiles.length > 0) {
//       setUploadedFiles(uploadedFiles);
//       console.log('Initialized with uploaded files:', uploadedFiles);
//     }
//   }, [setUploadedFiles, uploadedFiles]);

//   const { getRootProps, getInputProps } = useDropzone({
//     accept: FILE_SUPPORTED_MIMETYPES,
//     onDrop: (acceptedFiles) => {
//       console.log('Files dropped:', acceptedFiles);
//       setFiles([...files, ...acceptedFiles]);
//     },
//   });

//   const upload = async (file: File) => {
//     console.log('xxx Uploading file xxxx:', file.name);
//     const fileIndex = initialFiles.length + files.indexOf(file);

//     try {
//       const uploadedArray = await apiUploadFunction([file as Blob]);
//       console.log('xxx File uploaded xxx:', uploadedArray[0]);
//       dispatch({
//         type: 'setFile',
//         payload: {
//           id: fileIndex,
//           file: {
//             ...state[fileIndex],
//             uploadedName: uploadedArray[0],
//             cancelToken: null,
//             uploadProgress: 100,
//             cancelled: false,
//           },
//         },
//       });

//       // Update the uploaded files in the context
//       if (!uploadedFilesRef.current.includes(uploadedArray[0])) {
//         uploadedFilesRef.current = [...uploadedFilesRef.current, uploadedArray[0]];
//         setUploadedFiles(uploadedFilesRef.current);
//       }
//     } catch (error) {
//       console.error(`Error while uploading file in FileUpload: ${error}`);
//     }
//   };

//   useEffect(() => {
//     console.log('---->>>>Files:', files);
//     files.forEach((file) => {
//       const fileIndex = initialFiles.length + files.indexOf(file);
//       const uploadedFile = state[fileIndex];

//       if (!uploadedFile) {
//         console.log('xxx Uploading file xxxx:', file.name);
//         dispatch({
//           type: 'setFile',
//           payload: {
//             id: fileIndex,
//             file: {
//               uploadProgress: 0,
//               removed: false,
//               cancelled: false,
//               uploadedName: null,
//               cancelToken: null,
//             },
//           },
//         });

//         upload(file);
//       } else {
//         console.log('Upload file already exists:', uploadedFile);
//       }
//     });
//   }, [files, initialFiles.length, state]);

//   useEffect(() => {
//     onChange(Object.entries(state).map(([, value]) => value));
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [state]);

//   return {
//     getRootProps,
//     getInputProps,
//     files,
//     state,
//     dispatch,
//   };
// };
// import { useEffect, useReducer, useState } from 'react';
// import { useDropzone } from 'react-dropzone';
// import { initialState, reducer } from './FileUpload.reducer';
// import {
//   ApiUploadFunction,
//   FileUploadOnChangeFunction,
//   CustomFile,
// } from './FileUpload.types';
// import { useFileContext } from '@energyweb/origin-ui-device-view';

// const FILE_SUPPORTED_MIMETYPES = [
//   'image/jpeg',
//   'image/png',
//   'application/pdf',
//   'application/msword',
//   'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
// ];

// export type TUseFileUploadDeviceEffectsArgs = {
//   apiUploadFunction: ApiUploadFunction;
//   onChange: FileUploadOnChangeFunction;
//   initialFiles?: CustomFile[];
// };

// export const useFileUploadDeviceEffects = ({
//   apiUploadFunction,
//   onChange,
//   initialFiles = [],
// }: TUseFileUploadDeviceEffectsArgs) => {
//   console.log('useFileUploadDeviceEffects called');
//   const [state, dispatch] = useReducer(reducer, initialState);
//   const [files, setFiles] = useState<File[]>([]);
//   const { uploadedFiles, setUploadedFiles } = useFileContext();

//   const { getRootProps, getInputProps } = useDropzone({
//     accept: FILE_SUPPORTED_MIMETYPES,
//     onDrop: (acceptedFiles) => {
//       console.log('Files dropped:', acceptedFiles);
//       setFiles([...files, ...acceptedFiles]);
//     },
//   });

//   // Function to update uploaded files in the context and state
//   const initializeUploadedFiles = () => {
//     if (uploadedFiles.length > 0) {
//       console.log('Initializing uploaded files from context:', uploadedFiles);
//       uploadedFiles.forEach((file, index) => {
//         if (!state[index]) {
//           dispatch({
//             type: 'setFile',
//             payload: {
//               id: index,
//               file: {
//                 uploadedName: file,
//                 uploadProgress: 100,
//                 cancelled: false,
//                 removed: false,
//                 cancelToken: null,
//               },
//             },
//           });
//         }
//       });
//     }
//   };

//   useEffect(() => {
//     initializeUploadedFiles();
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, []); // Run this effect only once when the component mounts

//   const upload = async (file: File) => {
//     console.log('xxx Uploading file xxxx:', file.name);
//     const fileIndex = initialFiles.length + files.indexOf(file);

//     try {
//       const uploadedArray = await apiUploadFunction([file as Blob]);
//       console.log('xxx File uploaded xxx:', uploadedArray[0]);
//       dispatch({
//         type: 'setFile',
//         payload: {
//           id: fileIndex,
//           file: {
//             ...state[fileIndex],
//             uploadedName: uploadedArray[0],
//             cancelToken: null,
//             uploadProgress: 100,
//             cancelled: false,
//           },
//         },
//       });
//       // Update the uploaded files in the context
//       if (!uploadedFiles.includes(uploadedArray[0])) {
//         const newUploadedFiles = [...uploadedFiles, uploadedArray[0]];
//         setUploadedFiles(newUploadedFiles);
//       }
//     } catch (error) {
//       console.error(`Error while uploading file in FileUpload: ${error}`);
//     }
//   };

//   useEffect(() => {
//     console.log('---->>>>Files:', files);
//     files.forEach((file) => {
//       const fileIndex = initialFiles.length + files.indexOf(file);
//       const uploadedFile = state[fileIndex];

//       if (!uploadedFile) {
//         console.log('xxx Uploading file xxxx:', file.name);
//         dispatch({
//           type: 'setFile',
//           payload: {
//             id: fileIndex,
//             file: {
//               uploadProgress: 0,
//               removed: false,
//               cancelled: false,
//               uploadedName: null,
//               cancelToken: null,
//             },
//           },
//         });

//         upload(file);
//       } else {
//         console.log('Upload file already exists:', uploadedFile);
//       }
//     });
//   }, [files, initialFiles.length, state]);

//   useEffect(() => {
//     onChange(Object.entries(state).map(([, value]) => value));
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [state]);

//   return {
//     getRootProps,
//     getInputProps,
//     files,
//     state,
//     dispatch,
//   };
// };
