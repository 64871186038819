import { useSpecsForAllDeviceCard } from '@energyweb/origin-ui-device-logic';

import {
  ComposedPublicDevice,
  useDeviceFirstImageUrl,
} from '@energyweb/origin-ui-device-data';
import { CodeNameDTO } from '@energyweb/origin-device-registry-irec-local-api-react-query-client';
import { useNavigate } from 'react-router';
import { useDeviceAppEnv } from '../../../context';

type TUsePublicDeviceCardEffectsArgs = {
  device: ComposedPublicDevice;
  allDeviceTypes: CodeNameDTO[];
};

// export const usePublicDeviceCardEffects = ({
//   device,
//   allDeviceTypes,
// }: TUsePublicDeviceCardEffectsArgs) => {
//   const navigate = useNavigate();

//   const imageUrl = useDeviceFirstImageUrl(device.imageIds);

//   const viewDetailsClickHandler = (link: string) => navigate(link);

//   const { specsData, iconsData, cardProps } = useSpecsForAllDeviceCard({
//     device,
//     allTypes: allDeviceTypes,
//     clickHandler: viewDetailsClickHandler,
//     imageUrl,
//   });

//   return { specsData, iconsData, cardProps };
// };
export const usePublicDeviceCardEffects = ({
  device,
  allDeviceTypes,
}: TUsePublicDeviceCardEffectsArgs) => {
  const navigate = useNavigate();
  const { irecDeviceUrl } = useDeviceAppEnv(); // Retrieve the URL from context

  const imageUrl = useDeviceFirstImageUrl(device.imageIds);

  const viewDetailsClickHandler = (link: string) => navigate(link);

  const viewDeviceNameClickHandler = () => {
    // Use the URL from the context here
    window.open(`${irecDeviceUrl}/${device.code}`, '_blank', 'noopener,noreferrer');
  };

  const { specsData, iconsData, cardProps } = useSpecsForAllDeviceCard({
    device,
    allTypes: allDeviceTypes,
    clickHandler: viewDetailsClickHandler,
    nameClickHandler: viewDeviceNameClickHandler,  // Pass the second handler here
    imageUrl,
  });

  return { specsData, iconsData, cardProps };
};

