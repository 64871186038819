import { LightenColor } from '@energyweb/origin-ui-theme';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme) => ({
  subtitle: {
    color: LightenColor(theme.palette.text.secondary, -7, theme.palette.mode),
  },
  icon: {
    width: 30,
    height: 30,
    fill: LightenColor(theme.palette.text.secondary, -7, theme.palette.mode),
    marginRight: theme.spacing(1), // Adjust as needed
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap', // Ensure no wrap within this range
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      flexDirection: 'row',
    },
    '@media (min-width: 1200px) and (max-width: 1240px)': {
      flexDirection: 'row',
      flexWrap: 'nowrap', // Ensure no wrap within this range
    },
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    whiteSpace: 'nowrap', // Prevent text from wrapping
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

