// import React, { FC } from 'react';
// import { Typography } from '@mui/material';

// import { FileUpload, GenericForm } from '@energyweb/origin-ui-core';
// import { ComposedDevice } from '@energyweb/origin-ui-device-data';

// import { useStyles } from './RequestCertificateForm.styles';
// import { useRequestCertificateFormEffects } from './RequestCertificateForm.effects';

// export interface RequestCertificateFormProps {
//   device: ComposedDevice;
//   closeForm: () => void;
// }

// export const RequestCertificateForm: FC<RequestCertificateFormProps> = ({
//   device,
//   closeForm,
// }) => {
//   const classes = useStyles();
//   const { formProps, fileUploadProps, isLoading, isMutating, formTitle } =
//     useRequestCertificateFormEffects(device, closeForm);

//   if (isLoading) {
//     return null;
//   }

//   return (
//     <div className={classes.formWrapper}>
//       <Typography variant="h5">{formTitle}</Typography>
//       <Typography color="textSecondary">{device.name}</Typography>
//       <GenericForm loading={isMutating} {...formProps}>
//         <FileUpload
//           dropzoneClassName={classes.dropzone}
//           {...fileUploadProps}
//           wrapperProps={{ ['data-cy']: 'requestCertificateUpload' }}
//         />
//       </GenericForm>
//     </div>
//   );
// };
import React, { FC, useState } from 'react';
import { Typography, FormControlLabel } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { FileUpload, GenericForm, Switch } from '@energyweb/origin-ui-core';
import { ComposedDevice } from '@energyweb/origin-ui-device-data';

import { useStyles } from './RequestCertificateForm.styles';
import { useRequestCertificateFormEffects } from './RequestCertificateForm.effects';

export interface RequestCertificateFormProps {
  device: ComposedDevice;
  closeForm: () => void;
}

export const RequestCertificateForm: FC<RequestCertificateFormProps> = ({
  device,
  closeForm,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { formProps, fileUploadProps, isLoading, isMutating, formTitle } =
    useRequestCertificateFormEffects(device, closeForm);

  const [isSwitchChecked, setIsSwitchChecked] = useState(false);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSwitchChecked(event.target.checked);
  };

  if (isLoading) {
    return null;
  }

  return (
    <div className={classes.formWrapper}>
      <Typography variant="h5">{formTitle}</Typography>
      <Typography color="textSecondary">{device.name}</Typography>

      <GenericForm
        loading={isMutating}
        {...formProps}
        buttonProps={{
          ...formProps.buttonProps,
          disabled: !isSwitchChecked, // Disable button if switch is not checked
        }}
      >
        <FileUpload
          dropzoneClassName={classes.dropzone}
          {...fileUploadProps}
          wrapperProps={{ ['data-cy']: 'requestCertificateUpload' }}
        />
        <FormControlLabel
          control={
            <Switch
              checked={isSwitchChecked}
              onChange={handleSwitchChange}
              color="primary"
            />
          }
          label={t('device.my.requestCertificates.switchLabel') as string}
        />
      </GenericForm>
    </div>
  );
};
