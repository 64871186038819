import React, { FC } from 'react';
import {
  DeviceImagesUpload,
  useDeviceImagesUploadEffects,
} from './DeviceImagesUpload';
import {
  DeviceFilesUpload,
  useDeviceFilesUploadEffects,
} from './DeviceFilesUpload';
import { Box, Button, CircularProgress } from '@mui/material';
import { GenericFormSecondaryButton } from '@energyweb/origin-ui-core';
import { DeviceUploadFilesFormValues } from '@energyweb/origin-ui-device-logic';
import { UnpackNestedValue } from 'react-hook-form';

interface DeviceUploadsWrapperProps {
  //   submitHandler: (values: DeviceUploadFilesFormValues) => Promise<void>;
  //   secondaryButtons?: GenericFormSecondaryButton[];
  //   loading?: boolean;
  // }
  submitHandler: (
    values: UnpackNestedValue<DeviceUploadFilesFormValues>
  ) => Promise<void>;
  secondaryButtons?: GenericFormSecondaryButton[];
  loading?: boolean;
  buttonText?: string;
}

export const DeviceUploadsWrapper: FC<DeviceUploadsWrapperProps> = ({
  submitHandler,
  secondaryButtons,
  loading,
}) => {
  const {
    values: imageValues,
    onDeviceImageChange,
    uploadText: imageUploadText,
    uploadFunction: imageUploadFunction,
    deviceImagesHeading,
  } = useDeviceImagesUploadEffects();

  const {
    values: fileValues,
    onDeviceFileChange,
    uploadText: fileUploadText,
    uploadFunction: fileUploadFunction,
    deviceFilesHeading,
  } = useDeviceFilesUploadEffects();

  const uploadFileValues: DeviceUploadFilesFormValues = {
    ...imageValues,
    ...fileValues,
  };

  // console.log('uploadFileValues', uploadFileValues);

  return (
    <Box>
      <DeviceImagesUpload
        uploadText={imageUploadText}
        uploadFunction={imageUploadFunction}
        onDeviceChange={onDeviceImageChange}
        heading={deviceImagesHeading}
      />
      <DeviceFilesUpload
        uploadText={fileUploadText}
        uploadFunction={fileUploadFunction}
        onDeviceChange={onDeviceFileChange}
        heading={deviceFilesHeading}
      />
      <Box mt={2} display="flex" justifyContent="flex-end">
        {secondaryButtons &&
          secondaryButtons.map((button) => (
            <Button key={`secondary-button-${button.label}`} {...button}>
              {button.label}
            </Button>
          ))}
        <Button
          color="primary"
          name="submit"
          size="large"
          variant="contained"
          disabled={loading}
          onClick={() => submitHandler(uploadFileValues)}
        >
          Create Draft
          {loading && (
            <Box ml={2}>
              <CircularProgress size={20} />
            </Box>
          )}
        </Button>
      </Box>
    </Box>
  );
};
// import React, { FC, useEffect } from 'react';
// import {
//   DeviceImagesUpload,
//   useDeviceImagesUploadEffects,
// } from './DeviceImagesUpload';
// import {
//   DeviceFilesUpload,
//   useDeviceFilesUploadEffects,
// } from './DeviceFilesUpload';
// import { Box, Button, CircularProgress } from '@mui/material';
// import { GenericFormSecondaryButton } from '@energyweb/origin-ui-core';
// import { DeviceUploadFilesFormValues } from '@energyweb/origin-ui-device-logic';
// import { UnpackNestedValue } from 'react-hook-form';
// import { CustomFile } from '@energyweb/origin-ui-core';

// interface DeviceUploadsWrapperProps {
//   submitHandler: (
//     values: UnpackNestedValue<DeviceUploadFilesFormValues>
//   ) => Promise<void>;
//   secondaryButtons?: GenericFormSecondaryButton[];
//   loading?: boolean;
//   buttonText?: string;
//   initialImages?: CustomFile[];  // Make optional
//   initialFiles?: CustomFile[];   // Make optional
//   showSubmitButton?: boolean;
// }

// export const DeviceUploadsWrapper: FC<DeviceUploadsWrapperProps> = ({
//   submitHandler,
//   secondaryButtons,
//   loading,
//   initialImages = [],  // Default to empty array
//   initialFiles = [],   // Default to empty array
//   showSubmitButton = true,
// }) => {
//   const {
//     values: imageValues,
//     onDeviceImageChange,
//     uploadText: imageUploadText,
//     uploadFunction: imageUploadFunction,
//     deviceImagesHeading,
//   } = useDeviceImagesUploadEffects();

//   const {
//     values: fileValues,
//     onDeviceFileChange,
//     uploadText: fileUploadText,
//     uploadFunction: fileUploadFunction,
//     deviceFilesHeading,
//   } = useDeviceFilesUploadEffects();

//   const uploadFileValues: DeviceUploadFilesFormValues = {
//     ...imageValues,
//     ...fileValues,
//   };

//   useEffect(() => {
//     console.log('DeviceUploadsWrapper - Component Mounted');
//     console.log('Initial Images:', initialImages);
//     console.log('Initial Files:', initialFiles);
//   }, [initialImages, initialFiles]);

//   console.log('DeviceUploadsWrapper - Render');

//   return (
//     <Box>
//       <DeviceImagesUpload
//         uploadText={imageUploadText}
//         uploadFunction={imageUploadFunction}
//         onDeviceChange={onDeviceImageChange}
//         heading={deviceImagesHeading}
//         initialFiles={initialImages}
//       />
//       <DeviceFilesUpload
//         uploadText={fileUploadText}
//         uploadFunction={fileUploadFunction}
//         onDeviceChange={onDeviceFileChange}
//         heading={deviceFilesHeading}
//         initialFiles={initialFiles}
//       />
//       {showSubmitButton && (
//         <Box mt={2} display="flex" justifyContent="flex-end">
//           {secondaryButtons &&
//             secondaryButtons.map((button) => (
//               <Button key={`secondary-button-${button.label}`} {...button}>
//                 {button.label}
//               </Button>
//             ))}
//           <Button
//             color="primary"
//             name="submit"
//             size="large"
//             variant="contained"
//             disabled={loading}
//             onClick={() => submitHandler(uploadFileValues)}
//           >
//             Create Draft
//             {loading && (
//               <Box ml={2}>
//                 <CircularProgress size={20} />
//               </Box>
//             )}
//           </Button>
//         </Box>
//       )}
//     </Box>
//   );
// };

// import React, { FC, useEffect } from 'react';
// import {
//   DeviceImagesUpload,
//   useDeviceImagesUploadEffects,
// } from './DeviceImagesUpload';
// import {
//   DeviceFilesUpload,
//   useDeviceFilesUploadEffects,
// } from './DeviceFilesUpload';
// import { Box, Button, CircularProgress } from '@mui/material';
// import { GenericFormSecondaryButton } from '@energyweb/origin-ui-core';
// import { DeviceUploadFilesFormValues } from '@energyweb/origin-ui-device-logic';
// import { UnpackNestedValue } from 'react-hook-form';

// interface DeviceUploadsWrapperProps {
//   submitHandler: (
//     values: UnpackNestedValue<DeviceUploadFilesFormValues>
//   ) => Promise<void>;
//   secondaryButtons?: GenericFormSecondaryButton[];
//   loading?: boolean;
//   buttonText?: string;
//   initialImages?: { filename: string; contentType: string }[];  // Make optional
//   initialFiles?: { filename: string; contentType: string }[];   // Make optional
//   showSubmitButton?: boolean;
// }

// export const DeviceUploadsWrapper: FC<DeviceUploadsWrapperProps> = ({
//   submitHandler,
//   secondaryButtons,
//   loading,
//   initialImages = [],  // Default to empty array
//   initialFiles = [],   // Default to empty array
//   showSubmitButton = true,
// }) => {
//   const {
//     values: imageValues,
//     onDeviceImageChange,
//     uploadText: imageUploadText,
//     uploadFunction: imageUploadFunction,
//     deviceImagesHeading,
//   } = useDeviceImagesUploadEffects();

//   const {
//     values: fileValues,
//     onDeviceFileChange,
//     uploadText: fileUploadText,
//     uploadFunction: fileUploadFunction,
//     deviceFilesHeading,
//   } = useDeviceFilesUploadEffects();

//   const uploadFileValues: DeviceUploadFilesFormValues = {
//     ...imageValues,
//     ...fileValues,
//   };

//   useEffect(() => {
//     console.log('DeviceUploadsWrapper - Component Mounted');
//     console.log('Initial Images:', initialImages);
//     console.log('Initial Files:', initialFiles);
//   }, [initialImages, initialFiles]);

//   return (
//     <Box>
//       <DeviceImagesUpload
//         uploadText={imageUploadText}
//         uploadFunction={imageUploadFunction}
//         onDeviceChange={onDeviceImageChange}
//         heading={deviceImagesHeading}
//         initialFiles={initialImages}
//       />
//       <DeviceFilesUpload
//         uploadText={fileUploadText}
//         uploadFunction={fileUploadFunction}
//         onDeviceChange={onDeviceFileChange}
//         heading={deviceFilesHeading}
//         initialFiles={initialFiles}
//       />
//       {showSubmitButton && (
//         <Box mt={2} display="flex" justifyContent="flex-end">
//           {secondaryButtons &&
//             secondaryButtons.map((button) => (
//               <Button key={`secondary-button-${button.label}`} {...button}>
//                 {button.label}
//               </Button>
//             ))}
//           <Button
//             color="primary"
//             name="submit"
//             size="large"
//             variant="contained"
//             disabled={loading}
//             onClick={() => submitHandler(uploadFileValues)}
//           >
//             Create Draft
//             {loading && (
//               <Box ml={2}>
//                 <CircularProgress size={20} />
//               </Box>
//             )}
//           </Button>
//         </Box>
//       )}
//     </Box>
//   );
// };
