import React, { memo, PropsWithChildren, ReactElement, ReactNode } from 'react';
import {
  InputAdornment,
  TextField,
  TextFieldProps,
  InputBaseProps,
} from '@mui/material';
import { UseFormRegister } from 'react-hook-form';

export type FormInputField<FormValuesType> = {
  name: keyof FormValuesType;
  label: string;
  type?: 'text' | 'password' | 'number';
  placeholder?: string;
  required?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: {
    element: ReactNode;
    isValidCheck?: boolean;
  };
  textFieldProps?: TextFieldProps;
  inputProps?: InputBaseProps['inputProps'];
};

export interface FormInputProps<FormValues> {
  field: FormInputField<FormValues>;
  register: UseFormRegister<FormValues>;
  errorExists?: boolean;
  errorText?: string;
  isDirty?: boolean;
  disabled?: boolean;
  variant?: 'standard' | 'outlined' | 'filled';
}

export type TFormInput = <FormValuesType>(
  props: PropsWithChildren<FormInputProps<FormValuesType>>
) => ReactElement;

export const FormInput: TFormInput = memo(
  ({
    field,
    register,
    errorExists = false,
    errorText = '',
    isDirty = true,
    variant = 'standard',
    disabled = false,
  }) => {
    const { ref, name, onBlur, onChange } = register(field.name as any);
    const showEndAdornment = field.endAdornment?.isValidCheck
      ? !errorExists && isDirty
      : true;

    return (
      <TextField
        fullWidth
        key={name}
        margin="normal"
        name={name ?? ''}
        disabled={disabled}
        label={field.label ?? ''}
        type={field.type ?? 'text'}
        inputRef={ref}
        error={errorExists}
        helperText={errorText}
        required={field.required}
        variant={variant}
        inputProps={{
          ...field.inputProps,
        }}
        InputProps={{
          startAdornment: field.startAdornment && (
            <InputAdornment position="start">
              {field.startAdornment}
            </InputAdornment>
          ),
          endAdornment: field.endAdornment?.element && showEndAdornment && (
            <InputAdornment position="end">
              {field.endAdornment?.element}
            </InputAdornment>
          ),
        }}
        onChange={onChange}
        onBlur={onBlur}
        {...field.textFieldProps}
      />
    );
  }
);
// import React, { memo, PropsWithChildren, ReactElement, ReactNode } from 'react';
// import {
//   InputAdornment,
//   TextField,
//   TextFieldProps,
//   InputBaseProps,
// } from '@mui/material';
// import { UseFormRegister } from 'react-hook-form';

// export type FormInputField<FormValuesType> = {
//   name: keyof FormValuesType;
//   label: string;
//   type?: 'text' | 'password' | 'number';
//   placeholder?: string;
//   required?: boolean;
//   multiline?: boolean; // Support for multiline
//   rows?: number; // Number of rows if multiline
//   startAdornment?: ReactNode;
//   endAdornment?: {
//     element: ReactNode;
//     isValidCheck?: boolean;
//   };
//   textFieldProps?: TextFieldProps;
//   inputProps?: InputBaseProps['inputProps'];
// };

// export interface FormInputProps<FormValues> {
//   field: FormInputField<FormValues>;
//   register: UseFormRegister<FormValues>;
//   errorExists?: boolean;
//   errorText?: string;
//   isDirty?: boolean;
//   disabled?: boolean;
//   variant?: 'standard' | 'outlined' | 'filled';
// }

// export type TFormInput = <FormValuesType>(
//   props: PropsWithChildren<FormInputProps<FormValuesType>>
// ) => ReactElement;

// export const FormInput: TFormInput = memo(
//   ({
//     field,
//     register,
//     errorExists = false,
//     errorText = '',
//     isDirty = true,
//     variant = 'outlined', // Use outlined to match the other fields
//     disabled = false,
//   }) => {
//     const { ref, name, onBlur, onChange } = register(field.name as any);
//     const showEndAdornment = field.endAdornment?.isValidCheck
//       ? !errorExists && isDirty
//       : true;

//     return (
//       <TextField
//         fullWidth
//         key={name}
//         margin="normal"
//         name={name ?? ''}
//         disabled={disabled}
//         label={field.label ?? ''}
//         type={field.type ?? 'text'}
//         inputRef={ref}
//         error={errorExists}
//         helperText={errorText}
//         required={field.required}
//         variant={variant}
//         multiline={field.multiline || false} // Handle multiline
//         rows={field.rows || 1} // Apply the number of rows if multiline
//         InputProps={{
//           style: {
//             borderRadius: '8px', // Rounded corners
//             border: '1px solid #ddd', // Light border
//             padding: '10px', // Padding inside the box
//             boxShadow: 'none', // Remove any shadow
//             backgroundColor: 'transparent', // Ensure background is transparent
//           },
//           startAdornment: field.startAdornment && (
//             <InputAdornment position="start">
//               {field.startAdornment}
//             </InputAdornment>
//           ),
//           endAdornment: field.endAdornment?.element && showEndAdornment && (
//             <InputAdornment position="end">
//               {field.endAdornment?.element}
//             </InputAdornment>
//           ),
//         }}
//         {...field.textFieldProps}
//       />
//     );
//   }
// );

