import { FormSelectOption } from '@energyweb/origin-ui-core';
import {
  IrecAccountDto,
  //IrecAccountDtoType, //TODO: uncomment this line & find out why it's not being used
} from '@energyweb/origin-organization-irec-api-react-query-client';

enum IrecAccountDtoType { //TODO this is only added because the import didnt work
  Issue = 'Issue',
  Redemption = 'Redemption',
  Trade = 'Trade',
  CustodialTrade = 'CustodialTrade',
  CustodialRedemption = 'CustodialRedemption'
}

export const prepareAccountCodeOptions = (
  myAccounts: IrecAccountDto[]
): FormSelectOption[] => {
  if (!myAccounts) {
    return [];
  }

  const options: FormSelectOption[] = myAccounts
    .filter((account) => account.type.code === IrecAccountDtoType.Trade)
    .map((account) => ({
      value: account.code,
      label: account.code,
    }));
  return options;
};
