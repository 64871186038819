// import React, { PropsWithChildren, ReactElement } from 'react';
// import {
//   TextField,
//   Autocomplete,
//   Chip,
//   TextFieldProps,
//   InputBaseProps,
// } from '@mui/material';
// import { useSelectAutocompleteEffects } from './SelectAutocomplete.effects';
// import { useStyles } from './SelectAutocomplete.styles';
// import { FormSelectOption } from '../FormSelect';

// export type SelectAutocompleteField<FormValuesType> = {
//   name: keyof FormValuesType;
//   label: string;
//   placeholder?: string;
//   required?: boolean;
//   options?: FormSelectOption[];
//   multiple?: boolean;
//   maxValues?: number;
//   textFieldProps?: TextFieldProps;
//   inputProps?: InputBaseProps['inputProps'];
//   dependentOn?: string;
//   dependentOptionsCallback?: (fieldValue: any) => FormSelectOption[];
// };

// export interface SelectAutocompleteProps<FormValuesType = any> {
//   value: FormSelectOption[];
//   onChange: (newValue: FormSelectOption[]) => void;
//   field: SelectAutocompleteField<FormValuesType>;
//   errorExists?: boolean;
//   errorText?: string;
//   variant?: 'standard' | 'outlined' | 'filled';
//   disabled?: boolean;
//   dependentValue?: any;
//   className?: string;
// }

// export type TSelectAutocomplete = <FormValuesType>(
//   props: PropsWithChildren<SelectAutocompleteProps<FormValuesType>>
// ) => ReactElement;

// export const SelectAutocomplete: TSelectAutocomplete = ({
//   value,
//   field,
//   onChange,
//   errorExists = false,
//   errorText = '',
//   disabled = false,
//   variant = 'filled',
//   dependentValue = null,
//   className,
// }) => {
//   const { options, textValue, setTextValue, changeHandler } =
//     useSelectAutocompleteEffects(onChange, dependentValue, field);
//   const classes = useStyles();

//   // Log initial props and internal state
//   console.log('SelectAutocomplete Render');
//   console.log('Field Name:', field.name);
//   console.log('Initial Value:', value);
//   console.log('Dependent Value:', dependentValue);
//   console.log('Options:', options);

//   return (
//     <Autocomplete
//       multiple
//       filterSelectedOptions
//       options={options}
//       className={`${classes.autocomplete} ${className}`}
//       inputValue={textValue}
//       getOptionLabel={(option: FormSelectOption) => option.label}
//       onChange={changeHandler}
//       isOptionEqualToValue={(
//         option: FormSelectOption,
//         value: FormSelectOption
//       ) => option.value === value.value}
//       getOptionDisabled={() => disabled}
//       disabled={disabled}
//       value={value !== undefined ? value : []}
//       renderInput={(params) => (
//         <TextField
//           {...params}
//           required={field.required && !(value?.length > 0)}
//           label={field.label}
//           placeholder={field.placeholder}
//           onChange={(event: any) => setTextValue(event.target.value)}
//           helperText={errorText}
//           inputProps={{ ...params.inputProps, ...field.inputProps }}
//           error={errorExists}
//           variant={variant}
//           fullWidth
//           {...field.textFieldProps}
//         />
//       )}
//       renderTags={(value, getTagProps) => {
//         return value.map((option, index) => (
//           <Chip
//             color="primary"
//             label={(option as FormSelectOption).label}
//             key={(option as FormSelectOption).value}
//             disabled={disabled}
//             {...getTagProps({ index })}
//           />
//         ));
//       }}
//     />
//   );
// };
import React, { PropsWithChildren, ReactElement } from 'react';
import {
  TextField,
  Autocomplete,
  Chip,
  TextFieldProps,
  InputBaseProps,
} from '@mui/material';
import { useSelectAutocompleteEffects } from './SelectAutocomplete.effects';
import { useStyles } from './SelectAutocomplete.styles';
import { FormSelectOption } from '../FormSelect';

export type SelectAutocompleteField<FormValuesType> = {
  name: keyof FormValuesType;
  label: string;
  placeholder?: string;
  required?: boolean;
  options?: FormSelectOption[];
  multiple?: boolean;
  maxValues?: number;
  textFieldProps?: TextFieldProps;
  inputProps?: InputBaseProps['inputProps'];
  dependentOn?: string;
  dependentOptionsCallback?: (fieldValue: any) => FormSelectOption[];
};

export interface SelectAutocompleteProps<FormValuesType = any> {
  value: FormSelectOption[];
  onChange: (newValue: FormSelectOption[]) => void;
  field: SelectAutocompleteField<FormValuesType>;
  errorExists?: boolean;
  errorText?: string;
  variant?: 'standard' | 'outlined' | 'filled';
  disabled?: boolean;
  dependentValue?: any;
  className?: string;
}

export type TSelectAutocomplete = <FormValuesType>(
  props: PropsWithChildren<SelectAutocompleteProps<FormValuesType>>
) => ReactElement;

export const SelectAutocomplete: TSelectAutocomplete = ({
  value,
  field,
  onChange,
  errorExists = false,
  errorText = '',
  disabled = false,
  variant = 'filled',
  dependentValue = null,
  className,
}) => {
  const { options, textValue, setTextValue, changeHandler } =
    useSelectAutocompleteEffects(onChange, dependentValue, field);
  const classes = useStyles();

  // Log initial props and internal state
  console.log('SelectAutocomplete Render');
  console.log('Field Name:', field.name);
  console.log('Initial Value:', Array.isArray(value) ? value : []);
  console.log('Dependent Value:', dependentValue);
  console.log('Options:', Array.isArray(options) ? options : []);

  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      options={Array.isArray(options) ? options : []} // Ensure options is an array
      className={`${classes.autocomplete} ${className}`}
      inputValue={textValue}
      getOptionLabel={(option: FormSelectOption) => option.label}
      onChange={changeHandler}
      isOptionEqualToValue={(
        option: FormSelectOption,
        value: FormSelectOption
      ) => option.value === value.value}
      getOptionDisabled={() => disabled}
      disabled={disabled}
      value={Array.isArray(value) ? value : []} // Ensure value is an array
      renderInput={(params) => (
        <TextField
          {...params}
          required={field.required && !(value?.length > 0)}
          label={field.label}
          placeholder={field.placeholder}
          onChange={(event: any) => setTextValue(event.target.value)}
          helperText={errorText}
          inputProps={{ ...params.inputProps, ...field.inputProps }}
          error={errorExists}
          variant={variant}
          fullWidth
          {...field.textFieldProps}
        />
      )}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => (
          <Chip
            color="primary"
            label={(option as FormSelectOption).label}
            key={(option as FormSelectOption).value}
            disabled={disabled}
            {...getTagProps({ index })}
          />
        ));
      }}
    />
  );
};
