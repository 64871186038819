// import React, { FC } from 'react';
// import {
//   DeviceImagesUpload,
//   useDeviceImagesUploadEffects,
// } from './DeviceImagesUpload';
// import {
//   DeviceFilesUpload,
//   useDeviceFilesUploadEffects,
// } from './DeviceFilesUpload';
// import { Box, Button, CircularProgress } from '@mui/material';
// import { GenericFormSecondaryButton } from '@energyweb/origin-ui-core';
// import { DeviceUploadFilesFormValues } from '@energyweb/origin-ui-device-logic';
// import { UnpackNestedValue } from 'react-hook-form';

// interface DeviceUploadsWrapperProps {
//   //   submitHandler: (values: DeviceUploadFilesFormValues) => Promise<void>;
//   //   secondaryButtons?: GenericFormSecondaryButton[];
//   //   loading?: boolean;
//   // }
//   submitHandler: (
//     values: UnpackNestedValue<DeviceUploadFilesFormValues>
//   ) => Promise<void>;
//   secondaryButtons?: GenericFormSecondaryButton[];
//   loading?: boolean;
//   buttonText?: string;
// }

// export const DeviceUploadsWrapper: FC<DeviceUploadsWrapperProps> = ({
//   submitHandler,
//   secondaryButtons,
//   loading,
// }) => {
//   const {
//     values: imageValues,
//     onDeviceImageChange,
//     uploadText: imageUploadText,
//     uploadFunction: imageUploadFunction,
//     deviceImagesHeading,
//   } = useDeviceImagesUploadEffects();

//   const {
//     values: fileValues,
//     onDeviceFileChange,
//     uploadText: fileUploadText,
//     uploadFunction: fileUploadFunction,
//     deviceFilesHeading,
//   } = useDeviceFilesUploadEffects();

//   const uploadFileValues: DeviceUploadFilesFormValues = {
//     ...imageValues,
//     ...fileValues,
//   };

//   console.log('uploadFileValues', uploadFileValues);

//   return (
//     <Box>
//       <DeviceImagesUpload
//         uploadText={imageUploadText}
//         uploadFunction={imageUploadFunction}
//         onDeviceChange={onDeviceImageChange}
//         heading={deviceImagesHeading}
//       />
//       <DeviceFilesUpload
//         uploadText={fileUploadText}
//         uploadFunction={fileUploadFunction}
//         onDeviceChange={onDeviceFileChange}
//         heading={deviceFilesHeading}
//       />
//       <Box mt={2} display="flex" justifyContent="flex-end">
//         {secondaryButtons &&
//           secondaryButtons.map((button) => (
//             <Button key={`secondary-button-${button.label}`} {...button}>
//               {button.label}
//             </Button>
//           ))}
//         <Button
//           color="primary"
//           name="submit"
//           size="large"
//           variant="contained"
//           disabled={loading}
//           onClick={() => submitHandler(uploadFileValues)}
//         >
//           Create Draft
//           {loading && (
//             <Box ml={2}>
//               <CircularProgress size={20} />
//             </Box>
//           )}
//         </Button>
//       </Box>
//     </Box>
//   );
// };
// import React, { FC, useEffect } from 'react';
// import {
//   DeviceImagesUpload,
//   useDeviceImagesUploadEffects,
// } from './DeviceImagesUpload';
// import {
//   DeviceFilesUpload,
//   useDeviceFilesUploadEffects,
// } from './DeviceFilesUpload';
// import { Box, Button, CircularProgress } from '@mui/material';
// import { GenericFormSecondaryButton } from '@energyweb/origin-ui-core';
// import { DeviceUploadFilesFormValues } from '@energyweb/origin-ui-device-logic';
// import { UnpackNestedValue } from 'react-hook-form';
// import { CustomFile } from '@energyweb/origin-ui-core';

// interface DeviceUploadsWrapperProps {
//   submitHandler: (
//     values: UnpackNestedValue<DeviceUploadFilesFormValues>
//   ) => Promise<void>;
//   secondaryButtons?: GenericFormSecondaryButton[];
//   loading?: boolean;
//   buttonText?: string;
//   initialImages?: CustomFile[];  // Make optional
//   initialFiles?: CustomFile[];   // Make optional
//   showSubmitButton?: boolean;
// }

// export const DeviceUploadsWrapper: FC<DeviceUploadsWrapperProps> = ({
//   submitHandler,
//   secondaryButtons,
//   loading,
//   initialImages = [],  // Default to empty array
//   initialFiles = [],   // Default to empty array
//   showSubmitButton = true,
// }) => {
//   const {
//     values: imageValues,
//     onDeviceImageChange,
//     uploadText: imageUploadText,
//     uploadFunction: imageUploadFunction,
//     deviceImagesHeading,
//   } = useDeviceImagesUploadEffects();

//   const {
//     values: fileValues,
//     onDeviceFileChange,
//     uploadText: fileUploadText,
//     uploadFunction: fileUploadFunction,
//     deviceFilesHeading,
//   } = useDeviceFilesUploadEffects();

//   const uploadFileValues: DeviceUploadFilesFormValues = {
//     ...imageValues,
//     ...fileValues,
//   };

//   useEffect(() => {
//     console.log('DeviceUploadsWrapper - Component Mounted');
//     console.log('Initial Images:', initialImages);
//     console.log('Initial Files:', initialFiles);
//   }, [initialImages, initialFiles]);

//   console.log('DeviceUploadsWrapper - Render');

//   return (
//     <Box>
//       <DeviceImagesUpload
//         uploadText={imageUploadText}
//         uploadFunction={imageUploadFunction}
//         onDeviceChange={onDeviceImageChange}
//         heading={deviceImagesHeading}
//         initialFiles={initialImages}
//       />
//       <DeviceFilesUpload
//         uploadText={fileUploadText}
//         uploadFunction={fileUploadFunction}
//         onDeviceChange={onDeviceFileChange}
//         heading={deviceFilesHeading}
//         initialFiles={initialFiles}
//       />
//       {showSubmitButton && (
//         <Box mt={2} display="flex" justifyContent="flex-end">
//           {secondaryButtons &&
//             secondaryButtons.map((button) => (
//               <Button key={`secondary-button-${button.label}`} {...button}>
//                 {button.label}
//               </Button>
//             ))}
//           <Button
//             color="primary"
//             name="submit"
//             size="large"
//             variant="contained"
//             disabled={loading}
//             onClick={() => submitHandler(uploadFileValues)}
//           >
//             Create Draft
//             {loading && (
//               <Box ml={2}>
//                 <CircularProgress size={20} />
//               </Box>
//             )}
//           </Button>
//         </Box>
//       )}
//     </Box>
//   );
// };

// import React, { FC, useEffect } from 'react';
// import {
//   DeviceImagesUpload,
//   useDeviceImagesUploadEffects,
// } from './DeviceImagesUpload';
// import {
//   DeviceFilesUpload,
//   useDeviceFilesUploadEffects,
// } from './DeviceFilesUpload';
// import { Box, Button, CircularProgress } from '@mui/material';
// import { GenericFormSecondaryButton } from '@energyweb/origin-ui-core';
// import { DeviceUploadFilesFormValues } from '@energyweb/origin-ui-device-logic';
// import { UnpackNestedValue } from 'react-hook-form';

// interface DeviceUploadsWrapperProps {
//   submitHandler: (
//     values: UnpackNestedValue<DeviceUploadFilesFormValues>
//   ) => Promise<void>;
//   secondaryButtons?: GenericFormSecondaryButton[];
//   loading?: boolean;
//   buttonText?: string;
//   initialImages?: { filename: string; contentType: string }[];  // Make optional
//   initialFiles?: { filename: string; contentType: string }[];   // Make optional
//   showSubmitButton?: boolean;
// }

// export const DeviceUploadsWrapper: FC<DeviceUploadsWrapperProps> = ({
//   submitHandler,
//   secondaryButtons,
//   loading,
//   initialImages = [],  // Default to empty array
//   initialFiles = [],   // Default to empty array
//   showSubmitButton = true,
// }) => {
//   const {
//     values: imageValues,
//     onDeviceImageChange,
//     uploadText: imageUploadText,
//     uploadFunction: imageUploadFunction,
//     deviceImagesHeading,
//   } = useDeviceImagesUploadEffects();

//   const {
//     values: fileValues,
//     onDeviceFileChange,
//     uploadText: fileUploadText,
//     uploadFunction: fileUploadFunction,
//     deviceFilesHeading,
//   } = useDeviceFilesUploadEffects();

//   const uploadFileValues: DeviceUploadFilesFormValues = {
//     ...imageValues,
//     ...fileValues,
//   };

//   useEffect(() => {
//     console.log('DeviceUploadsWrapper - Component Mounted');
//     console.log('Initial Images:', initialImages);
//     console.log('Initial Files:', initialFiles);
//   }, [initialImages, initialFiles]);

//   return (
//     <Box>
//       <DeviceImagesUpload
//         uploadText={imageUploadText}
//         uploadFunction={imageUploadFunction}
//         onDeviceChange={onDeviceImageChange}
//         heading={deviceImagesHeading}
//         initialFiles={initialImages}
//       />
//       <DeviceFilesUpload
//         uploadText={fileUploadText}
//         uploadFunction={fileUploadFunction}
//         onDeviceChange={onDeviceFileChange}
//         heading={deviceFilesHeading}
//         initialFiles={initialFiles}
//       />
//       {showSubmitButton && (
//         <Box mt={2} display="flex" justifyContent="flex-end">
//           {secondaryButtons &&
//             secondaryButtons.map((button) => (
//               <Button key={`secondary-button-${button.label}`} {...button}>
//                 {button.label}
//               </Button>
//             ))}
//           <Button
//             color="primary"
//             name="submit"
//             size="large"
//             variant="contained"
//             disabled={loading}
//             onClick={() => submitHandler(uploadFileValues)}
//           >
//             Create Draft
//             {loading && (
//               <Box ml={2}>
//                 <CircularProgress size={20} />
//               </Box>
//             )}
//           </Button>
//         </Box>
//       )}
//     </Box>
//   );
// };
// import React, { FC, useEffect } from 'react';
// import {
//   EditDeviceImagesUpload,
//   useDeviceImagesUploadEffects,
// } from './DeviceImagesUpload';
// import {
//   EditDeviceFilesUpload,
//   useDeviceFilesUploadEffects,
// } from './DeviceFilesUpload';
// import { Box, Button, CircularProgress } from '@mui/material';
// import { GenericFormSecondaryButton } from '@energyweb/origin-ui-core';
// import { DeviceUploadFilesFormValues } from '@energyweb/origin-ui-device-logic';
// import { UnpackNestedValue } from 'react-hook-form';
// // import { useRemovedFiles } from '@energyweb/origin-ui-device-view';

// interface EditDeviceUploadsWrapperProps {
//   submitHandler: (
//     values: UnpackNestedValue<DeviceUploadFilesFormValues>
//   ) => Promise<void>;
//   secondaryButtons?: GenericFormSecondaryButton[];
//   loading?: boolean;
//   buttonText?: string;
//   initialImages?: { filename: string; contentType: string }[];
//   initialFiles?: { filename: string; contentType: string }[];
//   showSubmitButton?: boolean;
// }

// export const EditDeviceUploadsWrapper: FC<EditDeviceUploadsWrapperProps> = ({
//   submitHandler,
//   secondaryButtons,
//   loading,
//   initialImages = [],
//   initialFiles = [],
//   showSubmitButton = true,
// }) => {
//   const {
//     values: imageValues,
//     onDeviceImageChange,
//     uploadText: imageUploadText,
//     uploadFunction: imageUploadFunction,
//     deviceImagesHeading,
//   } = useDeviceImagesUploadEffects();

//   const {
//     values: fileValues,
//     onDeviceFileChange,
//     uploadText: fileUploadText,
//     uploadFunction: fileUploadFunction,
//     deviceFilesHeading,
//   } = useDeviceFilesUploadEffects();

//   // const { removedInitialFiles } = useRemovedFiles();

//   const uploadFileValues: DeviceUploadFilesFormValues = {
//     ...imageValues,
//     ...fileValues,
//   };

//   useEffect(() => {
//     console.log('DeviceUploadsWrapper - Component Mounted');
//     console.log('Initial Images:', initialImages);
//     console.log('Initial Files:', initialFiles);
//   }, [initialImages, initialFiles]);

//   return (
//     <Box>
//       <EditDeviceImagesUpload
//         uploadText={imageUploadText}
//         uploadFunction={imageUploadFunction}
//         onDeviceChange={onDeviceImageChange}
//         heading={deviceImagesHeading}
//         initialFiles={initialImages}
//       />
//       <EditDeviceFilesUpload
//         uploadText={fileUploadText}
//         uploadFunction={fileUploadFunction}
//         onDeviceChange={onDeviceFileChange}
//         heading={deviceFilesHeading}
//         initialFiles={initialFiles}
//       />
//     </Box>
//   );
// };
// import React, { FC, useEffect } from 'react';
// import {
//   EditDeviceImagesUpload,
//   useDeviceImagesUploadEffects,
// } from './DeviceImagesUpload';
// import {
//   EditDeviceFilesUpload,
//   useDeviceFilesUploadEffects,
// } from './DeviceFilesUpload';
// import { Box } from '@mui/material';
// import { DeviceUploadFilesFormValues } from '@energyweb/origin-ui-device-logic';
// import { CustomFile } from '@energyweb/origin-ui-core';
// import { useFileContext } from '@energyweb/origin-ui-device-view';

// interface EditDeviceUploadsWrapperProps {
//   initialImages?: CustomFile[];
//   initialFiles?: CustomFile[];
//   onFilesChange: (uploadedFiles: DeviceUploadFilesFormValues) => void; // Callback to pass file data up
// }

// export const EditDeviceUploadsWrapper: FC<EditDeviceUploadsWrapperProps> = ({
//   initialImages = [],
//   initialFiles = [],
//   // onFilesChange,
// }) => {
//   const {
//     values: imageValues,
//     onDeviceImageChange,
//     uploadText: imageUploadText,
//     uploadFunction: imageUploadFunction,
//     deviceImagesHeading,
//   } = useDeviceImagesUploadEffects();

//   const {
//     values: fileValues,
//     onDeviceFileChange,
//     uploadText: fileUploadText,
//     uploadFunction: fileUploadFunction,
//     deviceFilesHeading,
//   } = useDeviceFilesUploadEffects();

//   const uploadFileValues: DeviceUploadFilesFormValues = {
//     ...imageValues,
//     ...fileValues,
//   };

//   const { uploadedFiles, removedInitialFiles } = useFileContext();

//   useEffect(() => {
//     console.log('DeviceUploadsWrapper - Initial Images:', initialImages);
//     console.log('DeviceUploadsWrapper - Initial Files:', initialFiles);
    
//   }, [initialImages, initialFiles]);

//   useEffect(() => {
//     console.log('DeviceUploadsWrapper - Uploaded Files from context:', uploadedFiles);
//     console.log('DeviceUploadsWrapper - Removed Initial Files from context:', removedInitialFiles);
//   }, [uploadedFiles, removedInitialFiles]);

//   // Call the onFilesChange callback whenever the file data changes
//   // useEffect(() => {
//   //   console.log(
//   //     'JSON.stringify(uploadFileValues)',
//   //     JSON.stringify(uploadFileValues)
//   //   );
//   //   console.log(
//   //     'JSON.stringify({ ...imageValues, ...fileValues })',
//   //     JSON.stringify({ ...imageValues, ...fileValues })
//   //   );
//   //   // if (
//   //   //   JSON.stringify(uploadFileValues) !==
//   //   //   JSON.stringify({ ...imageValues, ...fileValues })
//   //   // ) {
//   //     console.log('DeviceUploadsWrapper - Files Changed');

//   //     // onFilesChange(uploadFileValues);
//   //   // }
//   //   console.log('DeviceUploadsWrapper - Upload File Values:', uploadFileValues);
//   // }, [imageValues, fileValues]);

  

//   // useEffect(() => {
//   //   const uploadFileValuesLength =
//   //     uploadFileValues.imageIds.length + uploadFileValues.fileIds.length;
//   //   const combinedValuesLength =
//   //     imageValues.imageIds.length + fileValues.fileIds.length;

//   //     console.log('uploadFileValuesLength', uploadFileValuesLength);
//   //     console.log('combinedValuesLength', combinedValuesLength);

//   //   if (uploadFileValuesLength !== combinedValuesLength) {
//   //     console.log('DeviceUploadsWrapper - Files Changed');
//   //     onFilesChange(uploadFileValues);
//   //   }
//   //   console.log('DeviceUploadsWrapper - Upload File Values:', uploadFileValues);
//   // }, [imageValues, fileValues]);

//   return (
//     <Box>
//       <EditDeviceImagesUpload
//         uploadText={imageUploadText}
//         uploadFunction={imageUploadFunction}
//         onDeviceChange={onDeviceImageChange}
//         heading={deviceImagesHeading}
//         initialFiles={initialImages}
//       />
//       <EditDeviceFilesUpload
//         uploadText={fileUploadText}
//         uploadFunction={fileUploadFunction}
//         onDeviceChange={onDeviceFileChange}
//         heading={deviceFilesHeading}
//         initialFiles={initialFiles}
//       />
//     </Box>
//   );
// };
// import React, { FC, useEffect } from 'react';
// import {
//   EditDeviceImagesUpload,
//   useEditDeviceImagesUploadEffects,
// } from './DeviceImagesUpload';
// import {
//   EditDeviceFilesUpload,
//   useEditDeviceFilesUploadEffects,
// } from './DeviceFilesUpload';
// import { Box } from '@mui/material';
// // import { useFileContext } from '@energyweb/origin-ui-device-view';
// import { CustomFile } from '@energyweb/origin-ui-core';

// interface EditDeviceUploadsWrapperProps {
//   initialImages?: CustomFile[];
//   initialFiles?: CustomFile[];
// }

// export const EditDeviceUploadsWrapper: FC<EditDeviceUploadsWrapperProps> = ({
//   initialImages = [],
//   initialFiles = [],
// }) => {
//   const {
//     values: imageValues,
//     onDeviceImageChange,
//     uploadText: imageUploadText,
//     uploadFunction: imageUploadFunction,
//     deviceImagesHeading,
//   } = useEditDeviceImagesUploadEffects();

//   const {
//     values: fileValues,
//     onDeviceFileChange,
//     uploadText: fileUploadText,
//     uploadFunction: fileUploadFunction,
//     deviceFilesHeading,
//   } = useEditDeviceFilesUploadEffects();

//   // const { uploadedFiles, setUploadedFiles } = useFileContext(); // Access and update the context for uploaded files

//   // const uploadFileValues = {
//   //   imageIds: imageValues.imageIds,
//   //   fileIds: fileValues.fileIds,
//   // };

//   // // Effect to update the uploaded files in context whenever values change
//   // useEffect(() => {
//   //   // Filter out any files that have been removed in the UI
//   //   const updatedUploadedFiles = [
//   //     ...uploadFileValues.imageIds,
//   //     ...uploadFileValues.fileIds,
//   //   ];

//   //   // Only update the context if there's a difference
//   //   if (
//   //     JSON.stringify(updatedUploadedFiles) !== JSON.stringify(uploadedFiles)
//   //   ) {
//   //     setUploadedFiles(updatedUploadedFiles);
//   //   }

//   //   console.log(
//   //     'DeviceUploadsWrapper - Updated Uploaded Files:',
//   //     updatedUploadedFiles
//   //   );
//   // }, [imageValues, fileValues, uploadedFiles, setUploadedFiles]);

//   return (
//     <Box>
//       <EditDeviceImagesUpload
//         uploadText={imageUploadText}
//         uploadFunction={imageUploadFunction}
//         onDeviceChange={onDeviceImageChange}
//         heading={deviceImagesHeading}
//         initialFiles={initialImages}
//       />
//       <EditDeviceFilesUpload
//         uploadText={fileUploadText}
//         uploadFunction={fileUploadFunction}
//         onDeviceChange={onDeviceFileChange}
//         heading={deviceFilesHeading}
//         initialFiles={initialFiles}
//       />
//     </Box>
//   );
// };
import React, { FC, useEffect, useMemo } from 'react';
import {
  EditDeviceImagesUpload,
  useDeviceImagesUploadEffects,
} from './DeviceImagesUpload';
import {
  EditDeviceFilesUpload,
  useDeviceFilesUploadEffects,
} from './DeviceFilesUpload';
import { Box } from '@mui/material';
import { CustomFile } from '@energyweb/origin-ui-core';
import { useFileContext } from '@energyweb/origin-ui-device-view';

interface EditDeviceUploadsWrapperProps {
  initialImages?: CustomFile[];
  initialFiles?: CustomFile[];
}

export const EditDeviceUploadsWrapper: FC<EditDeviceUploadsWrapperProps> = ({
  initialImages = [],
  initialFiles = [],
}) => {
  const {
    values: imageValues,
    onDeviceImageChange,
    uploadText: imageUploadText,
    uploadFunction: imageUploadFunction,
    deviceImagesHeading,
  } = useDeviceImagesUploadEffects();

  const {
    values: fileValues,
    onDeviceFileChange,
    uploadText: fileUploadText,
    uploadFunction: fileUploadFunction,
    deviceFilesHeading,
  } = useDeviceFilesUploadEffects();

  const { uploadedFiles, removedInitialFiles } = useFileContext();

  // Merge uploadedFiles with initialImages if not already present
  const mergedInitialImages = useMemo(() => {
    const existingIds = initialImages.map((file) => file.filename);
    const newImages = uploadedFiles
      .filter((file) => !existingIds.includes(file.filename) && file.contentType.startsWith('image/'))
      .map((file) => ({ id: file.filename, filename: file.filename, contentType: file.contentType }));
    return [...initialImages, ...newImages];
  }, [initialImages, uploadedFiles]);

  // Merge uploadedFiles with initialFiles if not already present
  const mergedInitialFiles = useMemo(() => {
    const existingIds = initialFiles.map((file) => file.filename);
    const newFiles = uploadedFiles
      .filter((file) => !existingIds.includes(file.filename) && !file.contentType.startsWith('image/'))
      .map((file) => ({ id: file.filename, filename: file.filename, contentType: file.contentType }));
    return [...initialFiles, ...newFiles];
  }, [initialFiles, uploadedFiles]);

  useEffect(() => {
    console.log('DeviceUploadsWrapper - Merged Initial Images:', mergedInitialImages);
    console.log('DeviceUploadsWrapper - Merged Initial Files:', mergedInitialFiles);
    console.log('DeviceUploadsWrapper - removedInitialFiles:', removedInitialFiles);
  }, [mergedInitialImages, mergedInitialFiles, removedInitialFiles]);

  return (
    <Box>
      <EditDeviceImagesUpload
        uploadText={imageUploadText}
        uploadFunction={imageUploadFunction}
        onDeviceChange={onDeviceImageChange}
        heading={deviceImagesHeading}
        initialFiles={mergedInitialImages}
      />
      <EditDeviceFilesUpload
        uploadText={fileUploadText}
        uploadFunction={fileUploadFunction}
        onDeviceChange={onDeviceFileChange}
        heading={deviceFilesHeading}
        initialFiles={mergedInitialFiles}
      />
    </Box>
  );
};

