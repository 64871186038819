// import { CodeNameDTO } from '@energyweb/origin-device-registry-irec-local-api-react-query-client';
// import { GenericFormProps } from '@energyweb/origin-ui-core';
// import {
//   ComposedPublicDevice,
//   EditDeviceFormValues,
// } from '@energyweb/origin-ui-device-data';
// import { PowerFormatter } from '@energyweb/origin-ui-utils';
// import { useMemo } from 'react';
// import { useTranslation } from 'react-i18next';
// import * as yup from 'yup';
// import { prepareDeviceTypesOptions, prepareFuelTypesOptions } from '../utils';
// // import { DeviceUploadsWrapper } from '@energyweb/origin-ui-device-view';

// export const useEditDeviceFormLogic = (
//   device: ComposedPublicDevice,
//   allFuelTypes: CodeNameDTO[],
//   allDeviceTypes: CodeNameDTO[],
//   loading?: boolean
// ): Omit<GenericFormProps<EditDeviceFormValues>, 'submitHandler'> => {
//   const { t } = useTranslation();

//   const allFuelTypesOptions = useMemo(() => {
//     return prepareFuelTypesOptions(allFuelTypes);
//   }, [allFuelTypes]);

//   const getDeviceType = useMemo(() => {
//     return prepareDeviceTypesOptions(allDeviceTypes);
//   }, [allDeviceTypes]);

//   const currentFuelTypeOption = allFuelTypesOptions?.find(
//     (option) => option.value === device.fuelType
//   );
//   const currentDeviceTypeOption = useMemo(() => {
//     const availableDeviceTypeOptions = getDeviceType([currentFuelTypeOption]);
//     return availableDeviceTypeOptions?.find(
//       (option) => option.value === device.deviceType
//     );
//   }, [currentFuelTypeOption]);

//   // const formattedCapacity = typeof device.capacity === 'number'
//   // ? (device.capacity / 1_000_000).toFixed(3) // Convert from watts to MW and format to 3 decimal places
//   // : (parseFloat(device.capacity) / 1_000_000).toFixed(3); // Convert from string to number if necessary

//   // console.log('Formatted Capacity:', formattedCapacity); // Debugging log

//   console.log('This is the device xxx', device.capacity);

//   return {
//     formTitle: t('device.edit.formTitle', { deviceName: device.name }),
//     initialValues: {
//       name: device.name,
//       fuelType: currentFuelTypeOption ? [currentFuelTypeOption] : [],
//       deviceType: currentDeviceTypeOption ? [currentDeviceTypeOption] : [],
//       capacity: PowerFormatter.format(device.capacity).replace(/,/g, ''),
//       // capacity: formattedCapacity.replace(/,/g, ''),
//       commissioningDate: device.commissioningDate,
//       registrationDate: device.registrationDate,
//       latitude: device.latitude,
//       longitude: device.longitude,
//       notes: device.notes,
//       // imageIds: device.imageIds || [],
//       // fileIds: device.fileIds || [],
//     },
//     validationSchema: yup.object().shape({
//       name: yup.string().required().label(t('device.edit.name')),
//       fuelType: yup.array().required().label(t('device.edit.fuelType')),
//       deviceType: yup.array().required().label(t('device.edit.deviceType')),
//       commissioningDate: yup
//         .string()
//         .required()
//         .label(t('device.edit.commissioningDate')),
//       registrationDate: yup
//         .string()
//         .required()
//         .label(t('device.edit.registrationDate')),
//       capacity: yup.string().required().label(t('device.edit.capacity')),
//       latitude: yup.string().required().label(t('device.edit.latitude')),
//       longitude: yup.string().required().label(t('device.edit.longitude')),
//     }),
//     fields: [
//       {
//         name: 'name',
//         label: t('device.edit.name'),
//         inputProps: { ['data-cy']: 'facilityName' },
//       },
//       {
//         name: 'fuelType',
//         label: t('device.edit.fuelType'),
//         select: true,
//         autocomplete: true,
//         required: true,
//         options: allFuelTypesOptions,
//       },
//       {
//         name: 'deviceType',
//         label: t('device.edit.deviceType'),
//         select: true,
//         autocomplete: true,
//         dependentOn: 'fuelType',
//         dependentOptionsCallback: getDeviceType,
//         required: true,
//       },
//       {
//         name: 'commissioningDate',
//         label: t('device.edit.commissioningDate'),
//         datePicker: true,
//         required: true,
//       },
//       {
//         name: 'registrationDate',
//         label: t('device.edit.registrationDate'),
//         datePicker: true,
//         required: true,
//       },
//       {
//         name: 'capacity',
//         label: t('device.edit.capacity'),
//         required: true,
//       },
//       {
//         name: 'latitude',
//         label: t('device.edit.latitude'),
//         required: true,
//       },
//       {
//         name: 'longitude',
//         label: t('device.edit.longitude'),
//         required: true,
//       },
//       {
//         name: 'notes',
//         label: t('device.edit.notes'),
//         textFieldProps: {
//           multiline: true,
//           rows: 5,
//         },
//       },
//       // {
//       //   name: 'imageIds',
//       //   label: t('device.edit.deviceImages'),
//       //   customComponent: (props) => (
//       //     <DeviceUploadsWrapper
//       //       {...props}
//       //       initialImages={device.imageIds || []}
//       //       initialFiles={device.fileIds || []}
//       //     />
//       //   ),
//       //   required: true,
//       // },
//       // {
//       //   name: 'fileIds',
//       //   label: t('device.edit.deviceFiles'),
//       //   customComponent: (props) => (
//       //     <DeviceUploadsWrapper
//       //       {...props}
//       //       initialImages={device.imageIds || []}
//       //       initialFiles={device.fileIds || []}
//       //     />
//       //   ),
//       //   required: true,
//       // },
//     ],
//     twoColumns: true,
//     inputsVariant: 'filled',
//     buttonText: t('general.buttons.edit'),
//     buttonProps: { ['data-cy']: 'editDeviceButton' },
//     loading,
//   };
// };
// import React from 'react';
// import { CodeNameDTO } from '@energyweb/origin-device-registry-irec-local-api-react-query-client';
// import { GenericFormProps, GenericFormField } from '@energyweb/origin-ui-core';
// import {
//   ComposedPublicDevice,
//   EditDeviceFormValues,
// } from '@energyweb/origin-ui-device-data';
// import { PowerFormatter } from '@energyweb/origin-ui-utils';
// import { useMemo } from 'react';
// import { useTranslation } from 'react-i18next';
// import * as yup from 'yup';
// import { prepareDeviceTypesOptions, prepareFuelTypesOptions } from '../utils';
// import { DeviceUploadsWrapper } from '@energyweb/origin-ui-device-view';
// import { CustomFile } from '@energyweb/origin-ui-core';

// export const useEditDeviceFormLogic = (
//   device: ComposedPublicDevice,
//   allFuelTypes: CodeNameDTO[],
//   allDeviceTypes: CodeNameDTO[],
//   loading?: boolean
// ): Omit<GenericFormProps<EditDeviceFormValues>, 'submitHandler'> => {
//   const { t } = useTranslation();

//   const allFuelTypesOptions = useMemo(() => {
//     return prepareFuelTypesOptions(allFuelTypes);
//   }, [allFuelTypes]);

//   const getDeviceType = useMemo(() => {
//     return prepareDeviceTypesOptions(allDeviceTypes);
//   }, [allDeviceTypes]);

//   const currentFuelTypeOption = allFuelTypesOptions?.find(
//     (option) => option.value === device.fuelType
//   );
//   const currentDeviceTypeOption = useMemo(() => {
//     const availableDeviceTypeOptions = getDeviceType([currentFuelTypeOption]);
//     return availableDeviceTypeOptions?.find(
//       (option) => option.value === device.deviceType
//     );
//   }, [currentFuelTypeOption]);

//   const createCustomFile = (id: string, contentType: string): CustomFile => {
//     const file = new File([], id, {
//       type: contentType,
//       lastModified: new Date().getTime(),
//     });
//     return {
//       ...file,
//       filename: id,
//       contentType: contentType,
//     };
//   };

//   console.log('Device imageIds:', device.imageIds);
//   console.log('Device fileIds:', device.fileIds);

//   const initialImages: CustomFile[] = (device.imageIds || []).map((id) =>
//     createCustomFile(id, 'image/jpeg')
//   );

//   console.log('Initial images:', initialImages);

//   const initialFiles: CustomFile[] = (device.fileIds || []).map((id) =>
//     createCustomFile(id, 'application/pdf')
//   );

//   console.log('Initial files:', initialFiles);

//   return {
//     formTitle: t('device.edit.formTitle', { deviceName: device.name }),
//     initialValues: {
//       name: device.name,
//       fuelType: currentFuelTypeOption ? [currentFuelTypeOption] : [],
//       deviceType: currentDeviceTypeOption ? [currentDeviceTypeOption] : [],
//       capacity: PowerFormatter.format(device.capacity).replace(/,/g, ''),
//       commissioningDate: device.commissioningDate,
//       registrationDate: device.registrationDate,
//       latitude: device.latitude,
//       longitude: device.longitude,
//       notes: device.notes,
//     },
//     validationSchema: yup.object().shape({
//       name: yup.string().required().label(t('device.edit.name')),
//       fuelType: yup.array().required().label(t('device.edit.fuelType')),
//       deviceType: yup.array().required().label(t('device.edit.deviceType')),
//       commissioningDate: yup
//         .string()
//         .required()
//         .label(t('device.edit.commissioningDate')),
//       registrationDate: yup
//         .string()
//         .required()
//         .label(t('device.edit.registrationDate')),
//       capacity: yup.string().required().label(t('device.edit.capacity')),
//       latitude: yup.string().required().label(t('device.edit.latitude')),
//       longitude: yup.string().required().label(t('device.edit.longitude')),
//     }),
//     fields: [
//       {
//         name: 'name',
//         label: t('device.edit.name'),
//         inputProps: { ['data-cy']: 'facilityName' },
//       },
//       {
//         name: 'fuelType',
//         label: t('device.edit.fuelType'),
//         select: true,
//         autocomplete: true,
//         required: true,
//         options: allFuelTypesOptions,
//       },
//       {
//         name: 'deviceType',
//         label: t('device.edit.deviceType'),
//         select: true,
//         autocomplete: true,
//         dependentOn: 'fuelType',
//         dependentOptionsCallback: getDeviceType,
//         required: true,
//       },
//       {
//         name: 'commissioningDate',
//         label: t('device.edit.commissioningDate'),
//         datePicker: true,
//         required: true,
//       },
//       {
//         name: 'registrationDate',
//         label: t('device.edit.registrationDate'),
//         datePicker: true,
//         required: true,
//       },
//       {
//         name: 'capacity',
//         label: t('device.edit.capacity'),
//         required: true,
//       },
//       {
//         name: 'latitude',
//         label: t('device.edit.latitude'),
//         required: true,
//       },
//       {
//         name: 'longitude',
//         label: t('device.edit.longitude'),
//         required: true,
//       },
//       {
//         name: 'notes',
//         label: t('device.edit.notes'),
//         textFieldProps: {
//           multiline: true,
//           rows: 5,
//         },
//       },
//       {
//         name: 'uploads',
//         customComponent: (props: any) => {
//           console.log('Rendering DeviceUploadsWrapper');
//           return (
//             <DeviceUploadsWrapper
//               {...props}
//               initialImages={initialImages}
//               initialFiles={initialFiles}
//               showSubmitButton={false}
//             />
//           );
//         },
//         required: false,
//       },
//     ] as GenericFormField<EditDeviceFormValues>[],
//     twoColumns: true,
//     inputsVariant: 'filled',
//     buttonText: t('general.buttons.edit'),
//     buttonProps: { ['data-cy']: 'editDeviceButton' },
//     loading,
//   };
// };
// import React from 'react';
// import { CodeNameDTO } from '@energyweb/origin-device-registry-irec-local-api-react-query-client';
// import { GenericFormProps, GenericFormField } from '@energyweb/origin-ui-core';
// import {
//   ComposedPublicDevice,
//   EditDeviceFormValues,
// } from '@energyweb/origin-ui-device-data';
// import { PowerFormatter } from '@energyweb/origin-ui-utils';
// import { useMemo } from 'react';
// import { useTranslation } from 'react-i18next';
// import * as yup from 'yup';
// import { prepareDeviceTypesOptions, prepareFuelTypesOptions } from '../utils';
// import { DeviceUploadsWrapper } from '@energyweb/origin-ui-device-view';

// export const useEditDeviceFormLogic = (
//   device: ComposedPublicDevice,
//   allFuelTypes: CodeNameDTO[],
//   allDeviceTypes: CodeNameDTO[],
//   loading?: boolean
// ): Omit<GenericFormProps<EditDeviceFormValues>, 'submitHandler'> => {
//   const { t } = useTranslation();

//   const allFuelTypesOptions = useMemo(() => {
//     return prepareFuelTypesOptions(allFuelTypes);
//   }, [allFuelTypes]);

//   const getDeviceType = useMemo(() => {
//     return prepareDeviceTypesOptions(allDeviceTypes);
//   }, [allDeviceTypes]);

//   const currentFuelTypeOption = allFuelTypesOptions?.find(
//     (option) => option.value === device.fuelType
//   );
//   const currentDeviceTypeOption = useMemo(() => {
//     const availableDeviceTypeOptions = getDeviceType([currentFuelTypeOption]);
//     return availableDeviceTypeOptions?.find(
//       (option) => option.value === device.deviceType
//     );
//   }, [currentFuelTypeOption]);

//   // Extract initial file information
//   const initialImages = (device.imageIds || []).map((id) => ({
//     filename: id,
//     contentType: 'image/jpeg',
//   }));

//   const initialFiles = (device.fileIds || []).map((id) => ({
//     filename: id,
//     contentType: 'application/pdf',
//   }));

//   return {
//     formTitle: t('device.edit.formTitle', { deviceName: device.name }),
//     initialValues: {
//       name: device.name,
//       fuelType: currentFuelTypeOption ? [currentFuelTypeOption] : [],
//       deviceType: currentDeviceTypeOption ? [currentDeviceTypeOption] : [],
//       capacity: PowerFormatter.format(device.capacity).replace(/,/g, ''),
//       commissioningDate: device.commissioningDate,
//       registrationDate: device.registrationDate,
//       latitude: device.latitude,
//       longitude: device.longitude,
//       notes: device.notes,
//     },
//     validationSchema: yup.object().shape({
//       name: yup.string().required().label(t('device.edit.name')),
//       fuelType: yup.array().required().label(t('device.edit.fuelType')),
//       deviceType: yup.array().required().label(t('device.edit.deviceType')),
//       commissioningDate: yup
//         .string()
//         .required()
//         .label(t('device.edit.commissioningDate')),
//       registrationDate: yup
//         .string()
//         .required()
//         .label(t('device.edit.registrationDate')),
//       capacity: yup.string().required().label(t('device.edit.capacity')),
//       latitude: yup.string().required().label(t('device.edit.latitude')),
//       longitude: yup.string().required().label(t('device.edit.longitude')),
//     }),
//     fields: [
//       {
//         name: 'name',
//         label: t('device.edit.name'),
//         inputProps: { ['data-cy']: 'facilityName' },
//       },
//       {
//         name: 'fuelType',
//         label: t('device.edit.fuelType'),
//         select: true,
//         autocomplete: true,
//         required: true,
//         options: allFuelTypesOptions,
//       },
//       {
//         name: 'deviceType',
//         label: t('device.edit.deviceType'),
//         select: true,
//         autocomplete: true,
//         dependentOn: 'fuelType',
//         dependentOptionsCallback: getDeviceType,
//         required: true,
//       },
//       {
//         name: 'commissioningDate',
//         label: t('device.edit.commissioningDate'),
//         datePicker: true,
//         required: true,
//       },
//       {
//         name: 'registrationDate',
//         label: t('device.edit.registrationDate'),
//         datePicker: true,
//         required: true,
//       },
//       {
//         name: 'capacity',
//         label: t('device.edit.capacity'),
//         required: true,
//       },
//       {
//         name: 'latitude',
//         label: t('device.edit.latitude'),
//         required: true,
//       },
//       {
//         name: 'longitude',
//         label: t('device.edit.longitude'),
//         required: true,
//       },
//       {
//         name: 'notes',
//         label: t('device.edit.notes'),
//         textFieldProps: {
//           multiline: true,
//           rows: 5,
//         },
//       },
//       {
//         name: 'uploads',
//         customComponent: (props: any) => {
//           console.log('Rendering DeviceUploadsWrapper');
//           return (
//             <DeviceUploadsWrapper
//               {...props}
//               initialImages={initialImages}
//               initialFiles={initialFiles}
//               showSubmitButton={false}
//             />
//           );
//         },
//         required: false,
//       },
//     ] as GenericFormField<EditDeviceFormValues>[],
//     twoColumns: true,
//     inputsVariant: 'filled',
//     buttonText: t('general.buttons.edit'),
//     buttonProps: { ['data-cy']: 'editDeviceButton' },
//     loading,
//   };
// };
// import React, { useEffect, useState, useMemo } from 'react';
// import { CodeNameDTO } from '@energyweb/origin-device-registry-irec-local-api-react-query-client';
// import { GenericFormProps, GenericFormField } from '@energyweb/origin-ui-core';
// import {
//   ComposedPublicDevice,
//   EditDeviceFormValues,
//   fetchFileMetadata,
// } from '@energyweb/origin-ui-device-data';
// import { PowerFormatter } from '@energyweb/origin-ui-utils';
// import { useTranslation } from 'react-i18next';
// import * as yup from 'yup';
// import { prepareDeviceTypesOptions, prepareFuelTypesOptions } from '../utils';
// import { DeviceUploadsWrapper } from '@energyweb/origin-ui-device-view';
// import { CustomFile } from '@energyweb/origin-ui-core';

// export const useEditDeviceFormLogic = (
//   device: ComposedPublicDevice,
//   allFuelTypes: CodeNameDTO[],
//   allDeviceTypes: CodeNameDTO[],
//   loading?: boolean
// ): Omit<GenericFormProps<EditDeviceFormValues>, 'submitHandler'> => {
//   const { t } = useTranslation();

//   const allFuelTypesOptions = useMemo(() => {
//     return prepareFuelTypesOptions(allFuelTypes);
//   }, [allFuelTypes]);

//   const getDeviceType = useMemo(() => {
//     return prepareDeviceTypesOptions(allDeviceTypes);
//   }, [allDeviceTypes]);

//   const currentFuelTypeOption = allFuelTypesOptions?.find(
//     (option) => option.value === device.fuelType
//   );
//   const currentDeviceTypeOption = useMemo(() => {
//     const availableDeviceTypeOptions = getDeviceType([currentFuelTypeOption]);
//     return availableDeviceTypeOptions?.find(
//       (option) => option.value === device.deviceType
//     );
//   }, [currentFuelTypeOption]);

//   const [initialImages, setInitialImages] = useState<CustomFile[]>([]);
//   const [initialFiles, setInitialFiles] = useState<CustomFile[]>([]);
//   const [removedInitialFiles, setRemovedInitialFiles] = useState<CustomFile[]>([]);

//   useEffect(() => {
//     const fetchFileDetails = async (ids: string[], isPublic: boolean) => {
//       const fileDetails = await Promise.all(
//         ids.map(async (id) => {
//           const details = await fetchFileMetadata(id, isPublic);
//           return {
//             filename: id,
//             contentType: details?.contentType || 'application/octet-stream', // Fallback content type
//           };
//         })
//       );
//       return fileDetails;
//     };

//     const fetchAllFileDetails = async () => {
//       const images = await fetchFileDetails(device.imageIds || [], true); // Assuming images are public
//       setInitialImages(images);

//       const files = await fetchFileDetails(device.fileIds || [], false); // Assuming files are not public
//       setInitialFiles(files);
//     };

//     fetchAllFileDetails();
//   }, [device.imageIds, device.fileIds]);

//   const handleRemoveInitialFile = (file: CustomFile) => {
//     setRemovedInitialFiles((prev) => [...prev, file]);
//   };

//   return {
//     formTitle: t('device.edit.formTitle', { deviceName: device.name }),
//     initialValues: {
//       name: device.name,
//       fuelType: currentFuelTypeOption ? [currentFuelTypeOption] : [],
//       deviceType: currentDeviceTypeOption ? [currentDeviceTypeOption] : [],
//       capacity: PowerFormatter.format(device.capacity).replace(/,/g, ''),
//       commissioningDate: device.commissioningDate,
//       registrationDate: device.registrationDate,
//       latitude: device.latitude,
//       longitude: device.longitude,
//       notes: device.notes,
//       imageIds: device.imageIds,
//       fileIds: device.fileIds,
//     },
//     validationSchema: yup.object().shape({
//       name: yup.string().required().label(t('device.edit.name')),
//       fuelType: yup.array().required().label(t('device.edit.fuelType')),
//       deviceType: yup.array().required().label(t('device.edit.deviceType')),
//       commissioningDate: yup
//         .string()
//         .required()
//         .label(t('device.edit.commissioningDate')),
//       registrationDate: yup
//         .string()
//         .required()
//         .label(t('device.edit.registrationDate')),
//       capacity: yup.string().required().label(t('device.edit.capacity')),
//       latitude: yup.string().required().label(t('device.edit.latitude')),
//       longitude: yup.string().required().label(t('device.edit.longitude')),
//     }),
//     fields: [
//       {
//         name: 'name',
//         label: t('device.edit.name'),
//         inputProps: { ['data-cy']: 'facilityName' },
//       },
//       {
//         name: 'fuelType',
//         label: t('device.edit.fuelType'),
//         select: true,
//         autocomplete: true,
//         required: true,
//         options: allFuelTypesOptions,
//       },
//       {
//         name: 'deviceType',
//         label: t('device.edit.deviceType'),
//         select: true,
//         autocomplete: true,
//         dependentOn: 'fuelType',
//         dependentOptionsCallback: getDeviceType,
//         required: true,
//       },
//       {
//         name: 'commissioningDate',
//         label: t('device.edit.commissioningDate'),
//         datePicker: true,
//         required: true,
//       },
//       {
//         name: 'registrationDate',
//         label: t('device.edit.registrationDate'),
//         datePicker: true,
//         required: true,
//       },
//       {
//         name: 'capacity',
//         label: t('device.edit.capacity'),
//         required: true,
//       },
//       {
//         name: 'latitude',
//         label: t('device.edit.latitude'),
//         required: true,
//       },
//       {
//         name: 'longitude',
//         label: t('device.edit.longitude'),
//         required: true,
//       },
//       {
//         name: 'notes',
//         label: t('device.edit.notes'),
//         textFieldProps: {
//           multiline: true,
//           rows: 5,
//         },
//       },
//       {
//         name: 'uploads',
//         customComponent: (props: any) => (
//           <DeviceUploadsWrapper
//             {...props}
//             initialImages={initialImages}
//             initialFiles={initialFiles}
//             showSubmitButton={false}
//             onRemoveInitialFile={handleRemoveInitialFile}
//           />
//         ),
//         required: false,
//       },
//     ] as GenericFormField<EditDeviceFormValues>[],
//     twoColumns: true,
//     inputsVariant: 'filled',
//     buttonText: t('general.buttons.edit'),
//     buttonProps: { ['data-cy']: 'editDeviceButton' },
//     loading,
//   };
// };
// import React, { useEffect, useState, useMemo } from 'react';
// import { CodeNameDTO } from '@energyweb/origin-device-registry-irec-local-api-react-query-client';
// import { GenericFormProps, GenericFormField } from '@energyweb/origin-ui-core';
// import {
//   ComposedPublicDevice,
//   EditDeviceFormValues,
//   fetchFileMetadata,
// } from '@energyweb/origin-ui-device-data';
// import { PowerFormatter } from '@energyweb/origin-ui-utils';
// import { useTranslation } from 'react-i18next';
// import * as yup from 'yup';
// import { prepareDeviceTypesOptions, prepareFuelTypesOptions } from '../utils';
// import { EditDeviceUploadsWrapper } from '@energyweb/origin-ui-device-view';
// import { CustomFile } from '@energyweb/origin-ui-core';
// // import { useRemovedFiles } from '@energyweb/origin-ui-device-view';

// export const useEditDeviceFormLogic = (
//   device: ComposedPublicDevice,
//   allFuelTypes: CodeNameDTO[],
//   allDeviceTypes: CodeNameDTO[],
//   loading?: boolean
// ): Omit<GenericFormProps<EditDeviceFormValues>, 'submitHandler'> => {
//   const { t } = useTranslation();
//   // const { setRemovedInitialFiles } = useRemovedFiles();

//   const allFuelTypesOptions = useMemo(() => {
//     return prepareFuelTypesOptions(allFuelTypes);
//   }, [allFuelTypes]);

//   const getDeviceType = useMemo(() => {
//     return prepareDeviceTypesOptions(allDeviceTypes);
//   }, [allDeviceTypes]);

//   const currentFuelTypeOption = allFuelTypesOptions?.find(
//     (option) => option.value === device.fuelType
//   );
//   const currentDeviceTypeOption = useMemo(() => {
//     const availableDeviceTypeOptions = getDeviceType([currentFuelTypeOption]);
//     return availableDeviceTypeOptions?.find(
//       (option) => option.value === device.deviceType
//     );
//   }, [currentFuelTypeOption]);

//   const [initialImages, setInitialImages] = useState<CustomFile[]>([]);
//   const [initialFiles, setInitialFiles] = useState<CustomFile[]>([]);

//   useEffect(() => {
//     const fetchFileDetails = async (ids: string[], isPublic: boolean) => {
//       const fileDetails = await Promise.all(
//         ids.map(async (id) => {
//           const details = await fetchFileMetadata(id, isPublic);
//           return {
//             filename: id,
//             contentType: details?.contentType || 'application/octet-stream',
//           };
//         })
//       );
//       return fileDetails;
//     };

//     const fetchAllFileDetails = async () => {
//       const images = await fetchFileDetails(device.imageIds || [], true);
//       setInitialImages(images);

//       const files = await fetchFileDetails(device.fileIds || [], false);
//       setInitialFiles(files);
//     };

//     fetchAllFileDetails();
//   }, [device.imageIds, device.fileIds]);

//   useEffect(() => {
//     console.log('useEditDeviceFormLogic - Initial Images:', initialImages);
//     console.log('useEditDeviceFormLogic - Initial Files:', initialFiles);
//   }, [initialImages, initialFiles]);

//   return {
//     formTitle: t('device.edit.formTitle', { deviceName: device.name }),
//     initialValues: {
//       name: device.name,
//       fuelType: currentFuelTypeOption ? [currentFuelTypeOption] : [],
//       deviceType: currentDeviceTypeOption ? [currentDeviceTypeOption] : [],
//       capacity: PowerFormatter.format(device.capacity).replace(/,/g, ''),
//       commissioningDate: device.commissioningDate,
//       registrationDate: device.registrationDate,
//       latitude: device.latitude,
//       longitude: device.longitude,
//       notes: device.notes,
//       imageIds: device.imageIds,
//       fileIds: device.fileIds,
//     },
//     validationSchema: yup.object().shape({
//       name: yup.string().required().label(t('device.edit.name')),
//       fuelType: yup.array().required().label(t('device.edit.fuelType')),
//       deviceType: yup.array().required().label(t('device.edit.deviceType')),
//       commissioningDate: yup.string().required().label(t('device.edit.commissioningDate')),
//       registrationDate: yup.string().required().label(t('device.edit.registrationDate')),
//       capacity: yup.string().required().label(t('device.edit.capacity')),
//       latitude: yup.string().required().label(t('device.edit.latitude')),
//       longitude: yup.string().required().label(t('device.edit.longitude')),
//     }),
//     fields: [
//       {
//         name: 'name',
//         label: t('device.edit.name'),
//         inputProps: { ['data-cy']: 'facilityName' },
//       },
//       {
//         name: 'fuelType',
//         label: t('device.edit.fuelType'),
//         select: true,
//         autocomplete: true,
//         required: true,
//         options: allFuelTypesOptions,
//       },
//       {
//         name: 'deviceType',
//         label: t('device.edit.deviceType'),
//         select: true,
//         autocomplete: true,
//         dependentOn: 'fuelType',
//         dependentOptionsCallback: getDeviceType,
//         required: true,
//       },
//       {
//         name: 'commissioningDate',
//         label: t('device.edit.commissioningDate'),
//         datePicker: true,
//         required: true,
//       },
//       {
//         name: 'registrationDate',
//         label: t('device.edit.registrationDate'),
//         datePicker: true,
//         required: true,
//       },
//       {
//         name: 'capacity',
//         label: t('device.edit.capacity'),
//         required: true,
//       },
//       {
//         name: 'latitude',
//         label: t('device.edit.latitude'),
//         required: true,
//       },
//       {
//         name: 'longitude',
//         label: t('device.edit.longitude'),
//         required: true,
//       },
//       {
//         name: 'notes',
//         label: t('device.edit.notes'),
//         textFieldProps: {
//           multiline: true,
//           rows: 5,
//         },
//       },
//       {
//         name: 'uploads',
//         customComponent: (props: any) => (
//           <EditDeviceUploadsWrapper
//             {...props}
//             initialImages={initialImages}
//             initialFiles={initialFiles}
//             showSubmitButton={false}
//           />
//         ),
//         required: false,
//       },
//     ] as GenericFormField<EditDeviceFormValues>[],
//     twoColumns: true,
//     inputsVariant: 'filled',
//     buttonText: t('general.buttons.edit'),
//     buttonProps: { ['data-cy']: 'editDeviceButton' },
//     loading,
//   };
// };
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { CodeNameDTO } from '@energyweb/origin-device-registry-irec-local-api-react-query-client';
import { GenericFormProps, GenericFormField } from '@energyweb/origin-ui-core';
import {
  ComposedPublicDevice,
  EditDeviceFormValues,
  fetchFileMetadata,
} from '@energyweb/origin-ui-device-data';
import { PowerFormatter } from '@energyweb/origin-ui-utils';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { prepareDeviceTypesOptions, prepareFuelTypesOptions } from '../utils';
import { EditDeviceUploadsWrapper } from '@energyweb/origin-ui-device-view';
import { CustomFile } from '@energyweb/origin-ui-core';
import { DeviceUploadFilesFormValues } from '@energyweb/origin-ui-device-logic';

export const useEditDeviceFormLogic = (
  device: ComposedPublicDevice,
  allFuelTypes: CodeNameDTO[],
  allDeviceTypes: CodeNameDTO[],
  loading?: boolean
): Omit<GenericFormProps<EditDeviceFormValues>, 'submitHandler'> => {
  const { t } = useTranslation();

  const allFuelTypesOptions = useMemo(() => {
    return prepareFuelTypesOptions(allFuelTypes);
  }, [allFuelTypes]);

  const getDeviceType = useMemo(() => {
    return prepareDeviceTypesOptions(allDeviceTypes);
  }, [allDeviceTypes]);

  const currentFuelTypeOption = allFuelTypesOptions?.find(
    (option) => option.value === device.fuelType
  );
  const currentDeviceTypeOption = useMemo(() => {
    const availableDeviceTypeOptions = getDeviceType([currentFuelTypeOption]);
    return availableDeviceTypeOptions?.find(
      (option) => option.value === device.deviceType
    );
  }, [currentFuelTypeOption]);

  const [initialImages, setInitialImages] = useState<CustomFile[]>([]);
  const [initialFiles, setInitialFiles] = useState<CustomFile[]>([]);
  const [uploadFileValues, setUploadFileValues] =
    useState<DeviceUploadFilesFormValues>({
      imageIds: [],
      fileIds: [],
    });

    useEffect(() => {
      let isMounted = true;
    
      const fetchFileDetails = async (ids: string[], isPublic: boolean) => {
        const fileDetails = await Promise.all(
          ids.map(async (id) => {
            const details = await fetchFileMetadata(id, isPublic);
            return {
              id: id,
              filename: id,
              contentType: details?.contentType || 'application/octet-stream',
            };
          })
        );
        return fileDetails;
      };
    
      const fetchAllFileDetails = async () => {
        const images = await fetchFileDetails(device.imageIds || [], true);
        const files = await fetchFileDetails(device.fileIds || [], false);
        
        if (isMounted) {
          setInitialImages(images);
          setInitialFiles(files);
        }
      };
    
      fetchAllFileDetails();
    
      return () => {
        isMounted = false; // cleanup to prevent setting state if unmounted
      };
    }, [device.imageIds, device.fileIds]);
    

  useEffect(() => {
    console.log('useEditDeviceFormLogic - Initial Images:', initialImages);
    console.log('useEditDeviceFormLogic - Initial Files:', initialFiles);
  }, [initialImages, initialFiles]);

  // const handleFilesChange = useCallback(
  //   (newFiles) => {
  //     if (JSON.stringify(newFiles) !== JSON.stringify(uploadFileValues)) {
  //       setUploadFileValues(newFiles);
  //     }
  //   },
  //   [uploadFileValues] // Only re-create this callback if `uploadFileValues` changes
  // );
  

  // const updatedImageIds = [
  //   ...(device.imageIds || []), // Existing image IDs
  //   ...(uploadFileValues.imageIds || []), // New uploaded image IDs
  // ];

  // const updatedFileIds = [
  //   ...(device.fileIds || []), // Existing file IDs
  //   ...(uploadFileValues.fileIds || []), // New uploaded file IDs
  // ];

  return {
    formTitle: t('device.edit.formTitle', { deviceName: device.name }),
    initialValues: {
      name: device.name,
      fuelType: currentFuelTypeOption ? [currentFuelTypeOption] : [],
      deviceType: currentDeviceTypeOption ? [currentDeviceTypeOption] : [],
      capacity: PowerFormatter.format(device.capacity).replace(/,/g, ''),
      commissioningDate: device.commissioningDate,
      registrationDate: device.registrationDate,
      latitude: device.latitude,
      longitude: device.longitude,
      notes: device.notes,
      // imageIds: updatedImageIds, // Use the merged image IDs
      // fileIds: updatedFileIds, // Use the merged file IDs
      imageIds: device.imageIds,
      fileIds: device.fileIds,
    },
    validationSchema: yup.object().shape({
      name: yup.string().required().label(t('device.edit.name')),
      fuelType: yup.array().required().label(t('device.edit.fuelType')),
      deviceType: yup.array().required().label(t('device.edit.deviceType')),
      commissioningDate: yup
        .string()
        .required()
        .label(t('device.edit.commissioningDate')),
      registrationDate: yup
        .string()
        .required()
        .label(t('device.edit.registrationDate')),
      capacity: yup.string().required().label(t('device.edit.capacity')),
      latitude: yup.string().required().label(t('device.edit.latitude')),
      longitude: yup.string().required().label(t('device.edit.longitude')),
    }),
    fields: [
      {
        name: 'name',
        label: t('device.edit.name'),
        inputProps: { ['data-cy']: 'facilityName' },
      },
      {
        name: 'fuelType',
        label: t('device.edit.fuelType'),
        select: true,
        autocomplete: true,
        required: true,
        options: allFuelTypesOptions,
      },
      {
        name: 'deviceType',
        label: t('device.edit.deviceType'),
        select: true,
        autocomplete: true,
        dependentOn: 'fuelType',
        dependentOptionsCallback: getDeviceType,
        required: true,
      },
      {
        name: 'commissioningDate',
        label: t('device.edit.commissioningDate'),
        datePicker: true,
        required: true,
      },
      {
        name: 'registrationDate',
        label: t('device.edit.registrationDate'),
        datePicker: true,
        required: true,
      },
      {
        name: 'capacity',
        label: t('device.edit.capacity'),
        required: true,
      },
      {
        name: 'latitude',
        label: t('device.edit.latitude'),
        required: true,
      },
      {
        name: 'longitude',
        label: t('device.edit.longitude'),
        required: true,
      },
      {
        name: 'notes',
        label: t('device.edit.notes'),
        textFieldProps: {
          multiline: true,
          rows: 5,
        },
      },
      {
        name: 'uploads',
        customComponent: (props: any) => {
          console.log('customComponent', {
            initialImages,
            initialFiles,
          });
      
          return (
            <EditDeviceUploadsWrapper
              {...props}
              initialImages={initialImages}
              initialFiles={initialFiles}
              // onFilesChange={(newFiles) => {
              //   console.log('onFilesChange triggered in customComponent with:', newFiles);
              //   handleFilesChange(newFiles);
              // }}
            />
          );
        },
        required: false,
      },
    ] as GenericFormField<EditDeviceFormValues>[],
    twoColumns: true,
    inputsVariant: 'filled',
    buttonText: t('general.buttons.edit'),
    buttonProps: { ['data-cy']: 'editDeviceButton' },
    loading,
  };
};
