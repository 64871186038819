import axios from 'axios';

export const fileDownloadHandler = async (id: string) => {
  return await axios.get(`api/file/${id}`);
};

export const publicFileDownloadHandler = async (id: string) => {
  return await axios.get(`api/file/public/${id}`);
};

export const fetchFileMetadata = async (id: string, isPublic = false) => {
  const endpoint = isPublic ? `/api/file/public/metadata/${id}` : `/api/file/metadata/${id}`;
  try {
    const response = await axios.get(endpoint);
    return response.data;
  } catch (error) {
    console.error('Error fetching file metadata:', error);
    return null;
  }
};