import React from 'react';

const withBlackBorder = (
  WrappedComponent: React.ComponentType<React.SVGProps<SVGSVGElement>>
): React.FC<React.SVGProps<SVGSVGElement>> => {
  return (props) => (
    <div style={{
      display: 'inline-block',
      borderRadius: '4px',
      border: '0.5px solid black', // Thin black border directly on the flag
      padding: 0, // Remove padding
      margin: 0, // Remove margin
      lineHeight: 0, // Remove line height
      width: '40px', // Control width
      height: '30px', // Control height to match the width ratio
      overflow: 'hidden', // Ensure no overflow
    }}>
      <WrappedComponent
        {...props}
        style={{
          ...props.style,
          display: 'block', // Ensure the flag does not overflow
          margin: '-2px', // Apply a larger negative margin to counter any internal padding or border spacing
          padding: 0, // Remove padding from the SVG
          width: 'calc(100% + 4px)', // Expand to accommodate the increased negative margin
          height: 'calc(100% + 4px)', // Maintain aspect ratio with the increased margin
        }}
      />
    </div>
  );
};

export default withBlackBorder;
