// import { useDeviceRegistryControllerGetMyDevices } from '@energyweb/origin-device-registry-api-react-query-client';
// import { useDeviceControllerGetMyDevices } from '@energyweb/origin-device-registry-irec-local-api-react-query-client';
// import { composeMyDevices } from '../utils';

// export const useApiMyDevices = () => {
//   const { data: allOriginDevices, isLoading: isOriginDevicesLoading } =
//     useDeviceRegistryControllerGetMyDevices();

//   const { data: allIRecDevices, isLoading: isIRecDevicesLoading } =
//     useDeviceControllerGetMyDevices();

//     console.log('My Device xxxxxxallIRecDevices', allIRecDevices);

//   const isLoading = isOriginDevicesLoading || isIRecDevicesLoading;
//   const myDevices =
//     allOriginDevices && allIRecDevices
//       ? composeMyDevices(allOriginDevices, allIRecDevices)
//       : [];

//   return { isLoading, myDevices };
// };

import { useState, useEffect } from 'react';
import { useDeviceRegistryControllerGetMyDevices } from '@energyweb/origin-device-registry-api-react-query-client';
import { useDeviceControllerGetMyDevices } from '@energyweb/origin-device-registry-irec-local-api-react-query-client';
import { composeMyDevices } from '../utils';

export const useApiMyDevices = () => {
  const [retryCount, setRetryCount] = useState(0);
  const [maxRetries] = useState(3);
  const [retryDelay] = useState(1000); // Delay between retries in milliseconds

  const { data: allOriginDevices, isLoading: isOriginDevicesLoading, refetch: refetchOriginDevices } =
    useDeviceRegistryControllerGetMyDevices();

  const { data: allIRecDevices, isLoading: isIRecDevicesLoading, refetch: refetchIRecDevices } =
    useDeviceControllerGetMyDevices();

  console.log('My Device xxxxxxallIRecDevices', allIRecDevices);

  const isLoading = isOriginDevicesLoading || isIRecDevicesLoading;
  const myDevices =
    allOriginDevices && allIRecDevices
      ? composeMyDevices(allOriginDevices, allIRecDevices)
      : [];

  // Retry logic if data is undefined
  useEffect(() => {
    if ((allOriginDevices === undefined || allIRecDevices === undefined) && retryCount < maxRetries) {
      const timeoutId = setTimeout(() => {
        console.warn('Retrying to fetch devices due to undefined data...');
        setRetryCount(retryCount + 1);
        refetchOriginDevices();
        refetchIRecDevices();
      }, retryDelay);

      // Clean up timeout if component unmounts
      return () => clearTimeout(timeoutId);
    }
  }, [allOriginDevices, allIRecDevices, retryCount, maxRetries, retryDelay, refetchOriginDevices, refetchIRecDevices]);

  return { isLoading, myDevices, refetchOriginDevices, refetchIRecDevices };
};
